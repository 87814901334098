import CommunitySection from './CommunitySection';
import FAQSection from './FAQSection';
import FeaturesSection from './FeaturesSection';
import HeroSection from './HeroSection';
import IntroSection from './IntroSection';
import StatsSection from './StatsSection';

function HomePageView(): JSX.Element {
  return (
    <main>
      <HeroSection />
      <StatsSection />
      <IntroSection />
      <FeaturesSection />
      <FAQSection />
      <CommunitySection />
    </main>
  );
}

export default HomePageView;
