import { PageSection } from '@tectonicfi/tectonic-ui-kit';

import { FrequentlyAskedQuestions } from '../LandingBodyContent';

import BackgroundImageContainer from './BackgroundImageContainer';

function FAQSection(): JSX.Element {
  return (
    <BackgroundImageContainer height="820px" src="/images/faq-background.png">
      <PageSection
        className="h-full"
        contentWrapperClassName="w-full flex flex-col items-start justify-center mobile:items-center"
      >
        <FrequentlyAskedQuestions />
      </PageSection>
    </BackgroundImageContainer>
  );
}

export default FAQSection;
