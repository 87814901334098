import { StackView, Text, Button } from '@tectonicfi/tectonic-ui-kit';
import Link from 'next/link';

import { PATHNAME } from '@components/AppLayout';

interface Props {
  className?: string;
}

export function SavingsIntroduction({ className = 'max-w-xl' }: Props) {
  return (
    <StackView spacing={2} className={className}>
      <Text as="h2" variant="semiBold">
        Savings
      </Text>
      <Text as="h3" variant="heading2">
        Generate passive yield on your assets
      </Text>
      <Text>
        Crypto assets deposited into Tectonic earn attractive APYs based on a
        dynamic rate according to market demands. Earnings are available
        immediately with no lockup.
      </Text>
      <Link href={PATHNAME.markets} passHref>
        <Button as="a">Start saving</Button>
      </Link>
    </StackView>
  );
}

export function InstantLoansIntroduction({ className = 'max-w-xl' }: Props) {
  return (
    <StackView spacing={2} className={className}>
      <Text as="h2" variant="semiBold">
        Instant loans
      </Text>
      <Text as="h3" variant="heading2">
        Instant liquidity at your fingertips
      </Text>
      <Text>
        Get an instant loan to unlock liquidity from idle crypto assets into
        Tectonic.
      </Text>
      <Link href={PATHNAME.markets} passHref>
        <Button as="a">Start borrowing</Button>
      </Link>
    </StackView>
  );
}
