import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk';
import { PartnerTokenInfo } from '@cronos-labs/tectonic-sdk/dist/types';

import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';
import { PoolType } from '@config/base';

import useWallets from './useWallets';
import useSdkAndSupportedAssets from './useSdkAndSupportedAssets';

export default function usePartnerTokensData(poolType: PoolType) {
  const { sdk, list: assets } = useSdkAndSupportedAssets(poolType);
  const { currentAccount } = useWallets();

  const query = getQuery(QueryKey.TECTONIC_PARTNER_TOKENS)(
    sdk,
    currentAccount ?? ''
  );

  const { data, isLoading } = useQuery<PartnerTokenInfo>(
    query.queryKey,
    query.queryFn,
    {
      enabled: !!sdk && !!currentAccount,
    }
  );
  const {
    partnerTokens = [],
    unclaimedAmounts = [],
    balances = [],
  } = data ?? {};

  const getPartnerTokens = useMemo(
    () =>
      partnerTokens.map(({ address, ...restPartnerToken }, i) => {
        const asset = assets.find(
          (e) => e.underlyingAddress === address
        ) as TectonicAsset;

        return {
          ...asset,
          ...restPartnerToken,
          underlyingAddress: asset?.underlyingAddress || address,
          amount: unclaimedAmounts[i],
          balance: balances[i],
        };
      }),
    [partnerTokens, assets, unclaimedAmounts, balances]
  );

  return {
    isLoading: isLoading,
    partnerTokensData: getPartnerTokens,
  };
}
