import { useMemo } from 'react';
import { BigNumber } from 'ethers';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk';

import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import useUsdPrices from '@hooks/useUsdPrices';
import useUserMetrics from '@hooks/useUserMetrics';
import { PoolType } from '@config/base';

import { checkBoost } from './utils';

export interface MarketsData {
  pool: PoolType;
  asset: TectonicAsset;
  amount: BigNumber;
  usdPrice?: string;
}
interface useAllMarketsDataResult {
  suppliedMarketsResult: MarketsData[];
  borrowedMarketsResult: MarketsData[];
}

export default function useAllMarketsData(
  isOpen: boolean,
  boostedSupplyCollections?: string[],
  boostedBorrowCollections?: string[]
): useAllMarketsDataResult {
  const {
    data: userMetricsDataMainPool,
    loaded: loadedUserMetricsDataMainPool,
  } = useUserMetrics('MAIN');
  const {
    data: userMetricsDataVenoPool,
    loaded: loadedUserMetricsDataVenoPool,
  } = useUserMetrics('VENO');
  const {
    data: userMetricsDataDefiPool,
    loaded: loadedUserMetricsDataDefiPool,
  } = useUserMetrics('DEFI');
  const { list: supportedAssetsMainPool } = useSdkAndSupportedAssets('MAIN');
  const { list: supportedAssetsVenoPool } = useSdkAndSupportedAssets('VENO');
  const { list: supportedAssetsDefiPool } = useSdkAndSupportedAssets('DEFI');
  const { usdPrices: usdPricesMainPool, loaded: loadedUsdPricesMainPool } =
    useUsdPrices('MAIN');
  const { usdPrices: usdPricesVenoPool, loaded: loadedUsdPricesVenoPool } =
    useUsdPrices('VENO');
  const { usdPrices: usdPricesDefiPool, loaded: loadedUsdPricesDefiPool } =
    useUsdPrices('DEFI');

  const suppliedMarketsResult: MarketsData[] = [];
  const borrowedMarketsResult: MarketsData[] = [];

  useMemo(() => {
    if (
      !isOpen ||
      !loadedUsdPricesDefiPool ||
      !loadedUsdPricesMainPool ||
      !loadedUsdPricesVenoPool ||
      !loadedUserMetricsDataMainPool ||
      !loadedUserMetricsDataVenoPool ||
      !loadedUserMetricsDataDefiPool
    ) {
      return [];
    }
    supportedAssetsMainPool?.map((asset) => {
      const { symbol } = asset;
      const supplyAmount = userMetricsDataMainPool.supplyAmounts[symbol];
      const borrowAmount = userMetricsDataMainPool.borrowAmounts[symbol];
      const usdPrice = usdPricesMainPool[symbol];
      const isBoostedSupply = checkBoost(
        asset.tTokenAddress,
        boostedSupplyCollections
      );
      const isBoostedBorrow = checkBoost(
        asset.tTokenAddress,
        boostedBorrowCollections
      );
      if (supplyAmount?.gt(0) || isBoostedSupply) {
        suppliedMarketsResult.push({
          pool: 'MAIN',
          asset,
          amount: supplyAmount,
          usdPrice,
        });
      }
      if (borrowAmount?.gt(0) || isBoostedBorrow) {
        borrowedMarketsResult.push({
          pool: 'MAIN',
          asset,
          amount: borrowAmount,
          usdPrice,
        });
      }
    });
    supportedAssetsVenoPool?.map((asset) => {
      const { symbol } = asset;
      const supplyAmount = userMetricsDataVenoPool.supplyAmounts[symbol];
      const borrowAmount = userMetricsDataVenoPool.borrowAmounts[symbol];
      const usdPrice = usdPricesVenoPool[symbol];
      const isBoostedSupply = checkBoost(
        asset.tTokenAddress,
        boostedSupplyCollections
      );
      const isBoostedBorrow = checkBoost(
        asset.tTokenAddress,
        boostedBorrowCollections
      );
      if (supplyAmount?.gt(0) || isBoostedSupply) {
        suppliedMarketsResult.push({
          pool: 'VENO',
          asset,
          amount: supplyAmount,
          usdPrice,
        });
      }
      if (borrowAmount?.gt(0) || isBoostedBorrow) {
        borrowedMarketsResult.push({
          pool: 'VENO',
          asset,
          amount: borrowAmount,
          usdPrice,
        });
      }
    });
    supportedAssetsDefiPool?.map((asset) => {
      const { symbol } = asset;
      const supplyAmount = userMetricsDataDefiPool.supplyAmounts[symbol];
      const borrowAmount = userMetricsDataDefiPool.borrowAmounts[symbol];
      const usdPrice = usdPricesDefiPool[symbol];
      const isBoostedSupply = checkBoost(
        asset.tTokenAddress,
        boostedSupplyCollections
      );
      const isBoostedBorrow = checkBoost(
        asset.tTokenAddress,
        boostedBorrowCollections
      );
      if (supplyAmount?.gt(0) || isBoostedSupply) {
        suppliedMarketsResult.push({
          pool: 'DEFI',
          asset,
          amount: supplyAmount,
          usdPrice,
        });
      }
      if (borrowAmount?.gt(0) || isBoostedBorrow) {
        borrowedMarketsResult.push({
          pool: 'DEFI',
          asset,
          amount: borrowAmount,
          usdPrice,
        });
      }
    });
    return { suppliedMarketsResult, borrowedMarketsResult };
  }, [
    supportedAssetsMainPool,
    supportedAssetsVenoPool,
    supportedAssetsDefiPool,
    userMetricsDataMainPool,
    userMetricsDataVenoPool,
    userMetricsDataDefiPool,
    loadedUserMetricsDataMainPool,
    loadedUserMetricsDataVenoPool,
    loadedUserMetricsDataDefiPool,
    loadedUsdPricesMainPool,
    loadedUsdPricesDefiPool,
    loadedUsdPricesVenoPool,
    usdPricesMainPool,
    usdPricesVenoPool,
    usdPricesDefiPool,
    boostedSupplyCollections,
    boostedBorrowCollections,
  ]);

  return { suppliedMarketsResult, borrowedMarketsResult };
}
