import { StackView, Text } from '@tectonicfi/tectonic-ui-kit';

import SocialMediaLinks from '../SocialMediaLinks';

interface Props {
  className?: string;
}

function Community({ className = 'max-w-xl' }: Props) {
  return (
    <StackView spacing={2} className={className}>
      <Text as="h2" variant="heading2">
        Join the community
      </Text>
      <Text>
        Learn more about Tectonic, stay up to date on all the latest protocol
        news and connect with others in the community.
      </Text>
      <SocialMediaLinks />
    </StackView>
  );
}

export default Community;
