import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk';

import useIsMobile from '@hooks/useIsMobile';
import { BoostType } from '@hooks/useGetXTonicBoostDailyDistributeRate';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import useUserMetrics, { UseUserMetricsResult } from '@hooks/useUserMetrics';
import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal/BaseTransactionModal';
import { Mode } from '@components/MarketsPageView/types';

import useAllMarketsData, { MarketsData } from './useAllMarketsData';
import AssetDetails from './AssetDetails';
import { useGetUserBoostCollections } from './XTonicBoostingHooks';
import { SelectedMarketProps } from './BoostModal';
import { getBoostedAssetDetails } from './utils';

export interface BoostModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  mode: Mode;
  onReplaceBoostMarkets: (
    oldTTokens: string[],
    oldBoostTypes: BoostType[],
    newTTokens: string[],
    newBoostTypes: BoostType[]
  ) => void;
  onRemoveBoostMarkets: (tTokens: string[], boostTypes: BoostType[]) => void;
}

enum UpdateBoostStep {
  selectMarkets,
  confirmMarkets,
}

const UpdateBoostModal = ({
  transactionStatus,
  onReplaceBoostMarkets,
  onRemoveBoostMarkets,
  ...props
}: BoostModalProps) => {
  const { list: supportedAssetsMainPool } = useSdkAndSupportedAssets('MAIN');
  const { list: supportedAssetsVenoPool } = useSdkAndSupportedAssets('VENO');
  const { list: supportedAssetsDefiPool } = useSdkAndSupportedAssets('DEFI');
  const { data: userMetricsDataMainPool } = useUserMetrics('MAIN');
  const { data: userMetricsDataVenoPool } = useUserMetrics('VENO');
  const { data: userMetricsDataDefiPool } = useUserMetrics('DEFI');
  const [updateBoostStep, setUpdateBoostStep] = useState<UpdateBoostStep>(
    UpdateBoostStep.selectMarkets
  );
  const [selectedMarket, setSelectedMarket] = useState<SelectedMarketProps[]>();
  const [initialSelectedMarket, setInitialSelectedMarket] =
    useState<SelectedMarketProps[]>();
  const options = useMemo(() => ({ skip: !props.isOpen }), [props.isOpen]);

  const { data: boostedSupplyCollections } = useGetUserBoostCollections(
    BoostType.SUPPLY,
    options
  );
  const { data: boostedBorrowCollections } = useGetUserBoostCollections(
    BoostType.BORROW,
    options
  );

  const checkPoolAssets = (
    supportedAssets: TectonicAsset[],
    item: string,
    boostType: BoostType,
    userMetrics: UseUserMetricsResult['data'],
    initialMarket?: boolean
  ): Undefined<TectonicAsset> => {
    const result = getBoostedAssetDetails(supportedAssets, item);
    if (initialMarket) {
      return result;
    }
    if (result) {
      const amount =
        boostType === BoostType.SUPPLY
          ? userMetrics.supplyAmounts[result.symbol]
          : userMetrics.borrowAmounts[result.symbol];
      return !amount?.isZero() ? result : undefined;
    }
  };

  const getSelectedMarkets = (
    boostedCollections: Undefined<string[]>,
    boostType: BoostType,
    initialMarket?: boolean
  ): SelectedMarketProps[] => {
    const result: SelectedMarketProps[] = [];
    boostedCollections?.map((item) => {
      const boostedMainAsset = checkPoolAssets(
        supportedAssetsMainPool,
        item,
        boostType,
        userMetricsDataMainPool,
        initialMarket
      );
      if (boostedMainAsset) {
        result.push({ marketDetails: boostedMainAsset, boostType: boostType });
      }
      const boostedVenoAsset = checkPoolAssets(
        supportedAssetsVenoPool,
        item,
        boostType,
        userMetricsDataVenoPool,
        initialMarket
      );
      if (boostedVenoAsset) {
        result.push({ marketDetails: boostedVenoAsset, boostType: boostType });
      }
      const boostedDefiAsset = checkPoolAssets(
        supportedAssetsDefiPool,
        item,
        boostType,
        userMetricsDataDefiPool,
        initialMarket
      );
      if (boostedDefiAsset) {
        result.push({ marketDetails: boostedDefiAsset, boostType: boostType });
      }
    });
    return result;
  };

  useEffect(() => {
    const supplyBoostedMarkets = getSelectedMarkets(
      boostedSupplyCollections,
      BoostType.SUPPLY
    );
    const borrowBoostedMarkets = getSelectedMarkets(
      boostedBorrowCollections,
      BoostType.BORROW
    );
    const supplyBoostedInitialMarkets = getSelectedMarkets(
      boostedSupplyCollections,
      BoostType.SUPPLY,
      true
    );
    const borrowBoostedInitialMarkets = getSelectedMarkets(
      boostedBorrowCollections,
      BoostType.BORROW,
      true
    );
    setSelectedMarket([...supplyBoostedMarkets, ...borrowBoostedMarkets]);
    setInitialSelectedMarket([
      ...supplyBoostedInitialMarkets,
      ...borrowBoostedInitialMarkets,
    ]);
  }, [
    supportedAssetsMainPool,
    supportedAssetsVenoPool,
    supportedAssetsDefiPool,
    boostedSupplyCollections,
    boostedBorrowCollections,
  ]);

  useEffect(() => {
    if (!props.isOpen) setUpdateBoostStep(UpdateBoostStep.selectMarkets);
  }, [props.isOpen]);

  const isMobile = useIsMobile();
  const {
    suppliedMarketsResult: suppliedMarketsData,
    borrowedMarketsResult: borrowedMarketsData,
  } = useAllMarketsData(
    props.isOpen,
    boostedSupplyCollections,
    boostedBorrowCollections
  );
  const onClickUpdate = () => {
    if (selectedMarket && selectedMarket.length <= 2) {
      setUpdateBoostStep(UpdateBoostStep.confirmMarkets);
    }
  };

  const onClickReplacedBoostMarkets = () => {
    if (selectedMarket && initialSelectedMarket) {
      const oldTTokenAddress = initialSelectedMarket.map(
        (item) => item.marketDetails.tTokenAddress
      );
      const oldBoostTypes = initialSelectedMarket.map((item) => item.boostType);

      const newTTokenAddress = selectedMarket.map(
        (item) => item.marketDetails.tTokenAddress
      );
      const newBoostTypes = selectedMarket.map((item) => item.boostType);
      onReplaceBoostMarkets(
        oldTTokenAddress,
        oldBoostTypes,
        newTTokenAddress,
        newBoostTypes
      );
    }
  };

  const getMarkets = (
    data: MarketsData[],
    userMarkets?: SelectedMarketProps[]
  ) => {
    return (
      userMarkets &&
      data.filter((item) =>
        userMarkets.find((market) => isEqual(market.marketDetails, item.asset))
      )
    );
  };

  return (
    <BaseTransactionModal
      className={clsx('min-h-[calc(100%-1rem)] desktop:!w-[510px]', {
        'fixed bottom-0 right-0 top-0 left-0 !h-[100vh] !max-h-screen !w-[100vw] mobile:p-2':
          isMobile,
      })}
      title={'Update Boost'}
      {...props}
      isShowMobileSliderClose={isMobile}
      transactionStatus={transactionStatus}
      onClose={props.onClose}
    >
      {updateBoostStep === UpdateBoostStep.selectMarkets && (
        <>
          Select up to 2 markets to boost
          <Text variant="small">
            Note: Only markets that you have supplied to or borrowed from and
            have TONIC incentives will be displayed
          </Text>
          <div className="h-[400px] overflow-y-auto pr-1.5 mobile:min-h-screen mobile:pb-[230px]">
            <div
              className={clsx(
                'mt-2 grid grid-cols-1 divide-y divide-yellowBorder border-1 border-semiYellowBorder',
                { hidden: suppliedMarketsData?.length === 0 }
              )}
            >
              <Text className="p-1.5">Supplied assets</Text>
              {suppliedMarketsData?.map((data: MarketsData, index) => {
                return (
                  <AssetDetails
                    {...data}
                    isOpen={props.isOpen}
                    key={index}
                    setSelectedMarket={setSelectedMarket}
                    selectedMarket={selectedMarket}
                    boostType={BoostType.SUPPLY}
                  />
                );
              })}
            </div>
            <div
              className={clsx(
                'mt-2 grid grid-cols-1 divide-y divide-yellowBorder border-1 border-semiBorderGradientTo',
                { hidden: borrowedMarketsData?.length === 0 }
              )}
            >
              <Text className="p-1.5">Borrowed assets</Text>
              {borrowedMarketsData?.map((data: MarketsData, index) => {
                return (
                  <AssetDetails
                    {...data}
                    isOpen={props.isOpen}
                    key={index}
                    setSelectedMarket={setSelectedMarket}
                    selectedMarket={selectedMarket}
                    boostType={BoostType.BORROW}
                  />
                );
              })}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 z-50 w-full bg-blueElevatedSurface p-3 mobile:p-2">
            <div
              className={clsx('flex gap-x-1', {
                hidden: !(selectedMarket && selectedMarket?.length > 2),
              })}
            >
              <Icon.Warn width={18} height={18} />
              <div className="text-mediumSmall font-normal text-rainbowRed">
                Please select up to 2 markets only
              </div>
            </div>
            <Button
              className="bottom-0 w-full"
              disabled={!selectedMarket}
              onClick={onClickUpdate}
            >
              Update Boost
            </Button>
          </div>
        </>
      )}
      {updateBoostStep === UpdateBoostStep.confirmMarkets && (
        <>
          <div className="h-[450px] overflow-y-auto pr-1.5 mobile:min-h-screen mobile:pb-[230px]">
            <div
              className={clsx(
                'mt-2 grid grid-cols-1 divide-y divide-yellowBorder border-1 border-semiYellowBorder',
                {
                  hidden:
                    getMarkets(suppliedMarketsData, initialSelectedMarket)
                      ?.length === 0,
                }
              )}
            >
              <Text className="p-1.5">Supplied assets</Text>
              {getMarkets(suppliedMarketsData, initialSelectedMarket)?.map(
                (data: MarketsData, index) => {
                  return (
                    <AssetDetails
                      {...data}
                      isOpen={props.isOpen}
                      key={index}
                      boostType={BoostType.SUPPLY}
                      isSelectionHidden={true}
                      apyColor={true}
                    />
                  );
                }
              )}
            </div>

            <div
              className={clsx(
                'mt-2 grid grid-cols-1 divide-y divide-yellowBorder border-1 border-semiBorderGradientTo',
                {
                  hidden:
                    getMarkets(borrowedMarketsData, initialSelectedMarket)
                      ?.length === 0,
                }
              )}
            >
              <Text className="p-1.5">Borrowed assets</Text>
              {getMarkets(borrowedMarketsData, initialSelectedMarket)?.map(
                (data: MarketsData, index) => {
                  return (
                    <AssetDetails
                      {...data}
                      isOpen={props.isOpen}
                      key={index}
                      boostType={BoostType.BORROW}
                      isSelectionHidden={true}
                      apyColor={true}
                    />
                  );
                }
              )}
            </div>
            {initialSelectedMarket?.length === 1 &&
            selectedMarket?.map((item) => {
              isEqual(
                item.marketDetails,
                initialSelectedMarket[0].marketDetails
              );
            }) ? (
              <></>
            ) : (
              <div className="mt-2 rounded border-1 border-greyBorder">
                <div className="flex p-1">
                  <Icon.Info />
                  <div className="ml-1">
                    <Text variant="small">Are you sure?</Text>
                    <Text variant="small" className="text-semiTransparent">
                      You will no longer enjoy boosted rewards in these markets.
                    </Text>
                  </div>
                </div>
              </div>
            )}

            <Icon.Arrow
              width={16}
              height={16}
              className="my-3 mx-auto rotate-[270deg] text-rainbowYellow"
            />

            <div
              className={clsx(
                'mt-2 grid grid-cols-1 divide-y divide-yellowBorder border-1 border-semiYellowBorder',
                getMarkets(suppliedMarketsData, selectedMarket)?.length === 0 &&
                  'hidden'
              )}
            >
              <Text className="p-1.5">Supplied assets</Text>
              {getMarkets(suppliedMarketsData, selectedMarket)?.map(
                (data: MarketsData, index) => {
                  return (
                    <AssetDetails
                      {...data}
                      isOpen={props.isOpen}
                      key={index}
                      boostType={BoostType.SUPPLY}
                      isSelectionHidden={true}
                    />
                  );
                }
              )}
            </div>

            <div
              className={clsx(
                'mt-2 grid grid-cols-1 divide-y divide-yellowBorder border-1 border-semiBorderGradientTo',
                getMarkets(borrowedMarketsData, selectedMarket)?.length === 0 &&
                  'hidden'
              )}
            >
              <Text className="p-1.5">Borrowed assets</Text>
              {getMarkets(borrowedMarketsData, selectedMarket)?.map(
                (data: MarketsData, index) => {
                  return (
                    <AssetDetails
                      {...data}
                      isOpen={props.isOpen}
                      key={index}
                      boostType={BoostType.BORROW}
                      isSelectionHidden={true}
                    />
                  );
                }
              )}
            </div>
            <div className="absolute bottom-0 left-0 z-50 w-full bg-blueElevatedSurface p-3 mobile:p-2">
              <Button
                className="bottom-0 w-full"
                disabled={!selectedMarket}
                onClick={onClickReplacedBoostMarkets}
              >
                Confirm change
              </Button>
            </div>
          </div>
        </>
      )}
    </BaseTransactionModal>
  );
};

export default UpdateBoostModal;
