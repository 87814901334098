import { useCallback } from 'react';
import { BigNumber } from 'ethers';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';

import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import useUserMetrics, { UseUserMetricsResult } from '@hooks/useUserMetrics';
import useUsdPrices from '@hooks/useUsdPrices';
import { getUserBorrowBalance, getUserSupplyBalance } from '@lib/math';

const getBorrowBalance = (
  loadedUserMetrics: boolean,
  userMetricsDataParam: UseUserMetricsResult['data'],
  supportAssets: TectonicAsset[],
  usdPriceParam: Record<string, Undefined<string>>,
  loadingUsdPrice: boolean
) =>
  loadedUserMetrics && loadingUsdPrice
    ? getUserBorrowBalance(
        supportAssets.map((asset) => {
          const borrowAmount =
            userMetricsDataParam.borrowAmounts[asset.symbol] ??
            BigNumber.from(0);
          const usdPrice =
            BigNumber.from(usdPriceParam[asset.symbol]) ?? BigNumber.from(0);
          return { asset, borrowAmount, usdPrice };
        })
      )
    : BigNumber.from(0);

const useTotalSupplyBorrow = () => {
  const { list: supportedAssets } = useSdkAndSupportedAssets('MAIN');
  const { list: supportedLcroPoolAssets } = useSdkAndSupportedAssets('VENO');
  const { list: supportedDeFiPoolAssets } = useSdkAndSupportedAssets('DEFI');

  const { data: userMetricsData, loaded: loadedUserMetricsData } =
    useUserMetrics('MAIN');
  const {
    data: userMetricsDataLcroPool,
    loaded: loadedUserMetricsDataLcroPool,
  } = useUserMetrics('VENO');
  const {
    data: userMetricsDataDeFiPool,
    loaded: loadedUserMetricsDataDeFiPool,
  } = useUserMetrics('DEFI');

  const { loaded: loadedUsdPrices, usdPrices } = useUsdPrices();
  const { loaded: loadedUsdPricesLcroPool, usdPrices: usdPricesLcroPool } =
    useUsdPrices('VENO');
  const { loaded: loadedUsdPricesDeFiPool, usdPrices: usdPricesDeFiPool } =
    useUsdPrices('DEFI');

  const getSupplyBalance = useCallback(
    (
      loadedUserMetrics: boolean,
      userMetricsDataParam: typeof userMetricsDataLcroPool,
      supportAssets: TectonicAsset[],
      usdPriceParam,
      loadingUsdPrice
    ) =>
      loadedUserMetrics && loadingUsdPrice
        ? getUserSupplyBalance(
            supportAssets.map((asset) => {
              const supplyAmount =
                userMetricsDataParam.supplyAmounts[asset.symbol] ??
                BigNumber.from(0);
              const usdPrice =
                BigNumber.from(usdPriceParam[asset.symbol]) ??
                BigNumber.from(0);

              return { asset, supplyAmount, usdPrice };
            })
          )
        : BigNumber.from(0),
    []
  );

  const supplyBalance = getSupplyBalance(
    loadedUserMetricsData,
    userMetricsData,
    supportedAssets,
    usdPrices,
    loadedUsdPrices
  );

  const supplyBalanceLcroPool = getSupplyBalance(
    loadedUserMetricsDataLcroPool,
    userMetricsDataLcroPool,
    supportedLcroPoolAssets,
    usdPricesLcroPool,
    loadedUsdPricesLcroPool
  );

  const supplyBalanceDeFiPool = getSupplyBalance(
    loadedUserMetricsDataDeFiPool,
    userMetricsDataDeFiPool,
    supportedDeFiPoolAssets,
    usdPricesDeFiPool,
    loadedUsdPricesDeFiPool
  );

  const borrowBalance = getBorrowBalance(
    loadedUserMetricsData,
    userMetricsData,
    supportedAssets,
    usdPrices,
    loadedUsdPrices
  );

  const borrowBalanceLcroPool = getBorrowBalance(
    loadedUserMetricsDataLcroPool,
    userMetricsDataLcroPool,
    supportedLcroPoolAssets,
    usdPricesLcroPool,
    loadedUsdPricesLcroPool
  );

  const borrowBalanceDeFiPool = getBorrowBalance(
    loadedUserMetricsDataDeFiPool,
    userMetricsDataDeFiPool,
    supportedDeFiPoolAssets,
    usdPricesDeFiPool,
    loadedUsdPricesDeFiPool
  );

  const totalSupplyBalance = supplyBalance
    .add(supplyBalanceDeFiPool)
    .add(supplyBalanceLcroPool);
  const totalBorrowBalance = borrowBalance
    .add(borrowBalanceDeFiPool)
    .add(borrowBalanceLcroPool);

  return {
    totalBorrowBalance,
    totalSupplyBalance,
    supplyBalance,
    borrowBalance,
    supplyBalanceLcroPool,
    borrowBalanceLcroPool,
    supplyBalanceDeFiPool,
    borrowBalanceDeFiPool,
  };
};

export default useTotalSupplyBorrow;
