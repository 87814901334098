import { useState } from 'react';
import clsx from 'clsx';
import { Button, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk';

import useIsMobile from '@hooks/useIsMobile';
import { BoostType } from '@hooks/useGetXTonicBoostDailyDistributeRate';
import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal/BaseTransactionModal';
import { Mode } from '@components/MarketsPageView/types';

import useAllMarketsData, { MarketsData } from './useAllMarketsData';
import AssetDetails from './AssetDetails';

export interface BoostModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  mode: Mode;
  onBoostMarkets: (tTokens: string[], boostTypes: BoostType[]) => void;
}

export interface SelectedMarketProps {
  marketDetails: TectonicAsset;
  boostType: BoostType;
}
const BoostModal = ({
  transactionStatus,
  onBoostMarkets,
  ...props
}: BoostModalProps) => {
  const [selectedMarket, setSelectedMarket] = useState<SelectedMarketProps[]>();

  const isMobile = useIsMobile();
  const {
    suppliedMarketsResult: suppliedMarketsData,
    borrowedMarketsResult: borrowedMarketsData,
  } = useAllMarketsData(props.isOpen);

  const onClickBoostMarkets = () => {
    if (selectedMarket && selectedMarket?.length > 0) {
      const tTokenAddress = selectedMarket.map(
        (item) => item.marketDetails.tTokenAddress
      );
      const boostTypes = selectedMarket.map((item) => item.boostType);
      onBoostMarkets(tTokenAddress, boostTypes);
    }
  };

  return (
    <BaseTransactionModal
      className={clsx('min-h-[calc(100%-1rem)] desktop:!w-[510px]', {
        'fixed bottom-0 right-0 top-0 left-0 !h-[100vh] !max-h-screen !w-[100vw] mobile:p-2':
          isMobile,
      })}
      title={'Boost market'}
      {...props}
      isShowMobileSliderClose={isMobile}
      transactionStatus={transactionStatus}
    >
      Select up to 2 markets to boost
      <div className="h-[450px] overflow-y-auto pr-1.5 mobile:min-h-screen mobile:pb-[230px]">
        <div
          className={clsx(
            'mt-2 grid grid-cols-1 divide-y divide-yellowBorder border-1 border-semiYellowBorder',
            { hidden: suppliedMarketsData?.length === 0 }
          )}
        >
          <Text className="p-1.5">Supplied assets</Text>
          {suppliedMarketsData?.map((data: MarketsData, index) => {
            return (
              <AssetDetails
                {...data}
                isOpen={props.isOpen}
                key={index}
                setSelectedMarket={setSelectedMarket}
                selectedMarket={selectedMarket}
                boostType={BoostType.SUPPLY}
              />
            );
          })}
        </div>
        <div
          className={clsx(
            'mt-2 grid grid-cols-1 divide-y divide-yellowBorder border-1 border-semiBorderGradientTo',
            { hidden: borrowedMarketsData?.length === 0 }
          )}
        >
          <Text className="p-1.5">Borrowed assets</Text>
          {borrowedMarketsData?.map((data: MarketsData, index) => {
            return (
              <AssetDetails
                {...data}
                isOpen={props.isOpen}
                key={index}
                setSelectedMarket={setSelectedMarket}
                selectedMarket={selectedMarket}
                boostType={BoostType.BORROW}
              />
            );
          })}
        </div>
      </div>
      <div className="absolute bottom-0 left-0 z-50 w-full bg-blueElevatedSurface p-3 mobile:p-2">
        <Button
          className="bottom-0 w-full"
          disabled={!selectedMarket}
          onClick={onClickBoostMarkets}
        >
          Confirm selection
        </Button>
      </div>
    </BaseTransactionModal>
  );
};

export default BoostModal;
