import { TectonicVaultStakeInfo } from '@cronos-labs/tectonic-sdk/dist/types';
import {
  Button,
  Text,
  Dropdown,
  Toggle,
  BackButton,
} from '@tectonicfi/tectonic-ui-kit';
import { BigNumber } from 'ethers';
import { useMemo, useState } from 'react';

import { formatXTonic } from '@lib/units';
import { passedUnlockDateToString } from '@lib/dates';
import { useStakedNFTTokenIds } from '@hooks/useStakeNFT';

import {
  DisplayValue,
  displayValues as defaultDisplayValues,
  SortOptions,
  SortValue,
} from '../DepositDropdown';
import DepositInfoRow from '../DepositInfoRow';
import useSortedDeposits from '../UpgradeLockingPeriod/useSortedDeposits';
import useDepositSelectionState, {
  TOGGLE,
  TOGGLE_SELECT_ALL,
} from '../useDepositSelectionState';
import { PoolOfUser } from '../VaultsBlock/types';

export interface WithdrawVaultProps {
  poolId: number;
  deposits: TectonicVaultStakeInfo[];
  pools: PoolOfUser[];
  onBackToVaultInfo: (id: number) => void;
  onConfirmSelection: (
    apr: string,
    depositIds: number[],
    totalXTonicUnlocked: string,
    multiplier: string
  ) => void;
}

const displayValues = {
  ...defaultDisplayValues,
  ['recently-locked']: 'Lock date (descending)',
  ['ending-soon']: 'Lock date (ascending)',
};

function WithdrawVault({
  poolId,
  deposits,
  pools,
  onBackToVaultInfo,
  onConfirmSelection,
}: WithdrawVaultProps): JSX.Element {
  const [state, dispatch] = useDepositSelectionState();
  const { isLoading: isStakedNFTIdsLoading, data: stakedNftIds } =
    useStakedNFTTokenIds(poolId);
  const hasStakedNFTsError = useMemo(
    () =>
      deposits.length === state.selectedVaults.length && !!stakedNftIds?.length,
    [stakedNftIds, deposits, state]
  );

  const [sortBy, setSortBy] = useState<SortValue>('recently-locked');
  const { sortedDeposits, lockEndTimes } = useSortedDeposits(deposits, sortBy);
  const remainingTimeStrings = useMemo(() => {
    return lockEndTimes.map(passedUnlockDateToString);
  }, [lockEndTimes]);
  const totalSelectedAmount = useMemo(() => {
    return sortedDeposits
      .filter((deposit) => state.selectedVaults.includes(deposit.stakeId))
      .reduce(
        (totalAmount, deposit) => deposit.amount.add(totalAmount),
        BigNumber.from(0)
      );
  }, [state.selectedVaults, sortedDeposits]);

  return (
    <div className="my-2 rounded-md bg-testBlue bg-gradient-to-r from-highlightBorderColorFrom to-highlightBorderColorTo p-[1px] mobile:my-3 mobile:mx-0">
      <div className="relative max-h-[610px] min-h-[589px] overflow-scroll rounded-md bg-testBlue text-white">
        <div className="max-h-[610px] overflow-scroll p-3">
          <div className="absolute top-0 left-0 right-0 z-20 rounded-md bg-testBlue p-3">
            <BackButton onClick={() => onBackToVaultInfo(poolId)}>
              Back to vault info
            </BackButton>
          </div>
          <div className="h-[25px]"></div>
          <div className="mt-3 flex w-full flex-col justify-between gap-1 leading-5">
            <div className="flex w-full flex-row justify-between">
              <Text variant="heading3" className="font-bold">
                Withdraw
              </Text>
              <Text>
                {totalSelectedAmount.gt(0)
                  ? formatXTonic(totalSelectedAmount)
                  : '-'}{' '}
                xTONIC
              </Text>
            </div>
            <div>
              <span className="text-mediumSmall">
                Select the unlocked deposit you wish to withdraw. You will stop
                earning the Vault TONIC rewards by removing your unlocked
                xTONIC.
              </span>
            </div>
          </div>
          <div className="mt-3 flex w-full flex-row items-end justify-start">
            <div className="text-[16px] mobile:text-[14px]">Sort by:</div>
            <Dropdown
              className="ml-[6px] leading-5"
              onChange={(value) => setSortBy(value)}
              value={sortBy}
              displayValue={<DisplayValue displayValues={displayValues} />}
            >
              <SortOptions displayValues={displayValues} />
            </Dropdown>
          </div>
          <div className="mt-2 flex w-full flex-row items-start justify-end gap-1">
            <div className="pt-[5px] text-mediumSmall">select all</div>
            <Toggle
              data-testid="select-all"
              checked={state.selectedAll}
              onClick={() =>
                dispatch({
                  type: TOGGLE_SELECT_ALL,
                  allIds: sortedDeposits.map((deposit) => deposit.stakeId),
                })
              }
            />
          </div>
          <div className="mt-2 flex flex-col gap-2 ">
            {sortedDeposits.map((deposit, i) => (
              <DepositInfoRow
                key={deposit.stakeId}
                deposit={deposit}
                remainingTime={remainingTimeStrings[i]}
                selected={state.selectedVaults.includes(deposit.stakeId)}
                onClick={() =>
                  dispatch({
                    type: TOGGLE,
                    id: deposit.stakeId,
                  })
                }
              />
            ))}
            <div className="h-[104px]"></div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 z-20 rounded-md bg-semiTransparentTestBlue p-2 backdrop-blur">
          <Button
            variant="contained"
            className="w-full"
            disabled={
              isStakedNFTIdsLoading ||
              !state.selectedVaults.length ||
              hasStakedNFTsError
            }
            onClick={() => {
              const pool = pools.find((p) => p.poolId === poolId);
              if (!pool) throw new Error('cannot find pool');
              onConfirmSelection(
                pool.apy.toFixed(2) + '%',
                state.selectedVaults,
                formatXTonic(totalSelectedAmount),
                `${pool.multiplier}x`
              );
            }}
          >
            Confirm Selection
          </Button>
          {hasStakedNFTsError && (
            <Text className="text-small text-red">
              In order to withdraw all your xTONIC from this vault, you need to
              first unstake all your NFTs
            </Text>
          )}
        </div>
      </div>
    </div>
  );
}

export default WithdrawVault;
