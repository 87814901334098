import { TectonicVaultPool } from '@cronos-labs/tectonic-sdk/dist/types';

import { notify } from '@lib/bugsnag';

import { LockingPeriod, LockingPeriodDetails } from './types';

export const periodInSeconds: Record<LockingPeriod, number> = {
  '30s': 30,
  '60s': 60,
  '120s': 120,
  '240s': 240,
  '1month': 2592000,
  '6months': 15552000,
  '12months': 31536000,
  '24months': 63072000,
  '48months': 126230400,
};

export const periodToName = (period: LockingPeriod) => {
  const [digits, plural] = period.split('month');
  return `${digits} Month${plural}`;
};

export const secondsToPeriod: Record<number, LockingPeriod> = {
  30: '30s',
  60: '60s',
  120: '120s',
  240: '240s',
  2592000: '1month',
  15552000: '6months',
  31536000: '12months',
  63072000: '24months',
  126230400: '48months',
};
export const poolToPeriod = (
  pool: TectonicVaultPool
): LockingPeriodDetails | undefined => {
  const monthPeriod = secondsToPeriod[pool.lockPeriod];
  if (!monthPeriod) {
    notify('Received unknown locking period from pool', JSON.stringify(pool));
    return undefined;
  }
  return {
    apr: pool.apy,
    multiplier: pool.multiplier + 'x',
    period: monthPeriod,
    poolId: pool.poolId,
  };
};
