import { useEffect, useState, useMemo, useCallback, memo } from 'react';
import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';
import { BigNumber, utils } from 'ethers';
import clsx from 'clsx';

import {
  formatUserUnderlyingAmount,
  formatUserUnderlyingUsdValue,
} from '@lib/units';
import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import useIsMobile from '@hooks/useIsMobile';
import useUsdPrices from '@hooks/useUsdPrices';
import { getUnderlyingUsdValue, convertTonicToXTonic } from '@lib/math';
import { useVaultsData } from '@components/VaultsPageView/VaultsAndEarningsBlock/VaultsAndEarningsBlock';
import { LockingPeriodDetails } from '@components/LockDepositModal/types';
import ModeSwitch from '@components/ModeSwitch';
import Features from '@components/Features';

import Empty from './Empty';
import SelectLocked, { MIN_LOCKED } from './SelectLocked';
import VaultTier from './VaultTier';
import ConfirmClaimCard from './ConfirmClaimCard';
import { modes, Mode } from './types';
import PartnerTokensTabContent from './PartnerTokensTabContent';

export interface TonicBalanceModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  estimateTonicInBatchData?: BigNumber;
  unclaimBoostTonicBalance?: BigNumber;
  asset: TectonicAsset | null;
  onLockClaim: (depositPercentage: string, vaultPoolId: number) => void;
  onClaimPartnerTokens: (partnerAddresses: string[]) => void;
}

enum CardStep {
  ltMinClaimTonic,
  selectVault,
  lockInVault,
  confirmCard,
}

export const MIN_CLAIM_TONIC_AMOUNT = '200';

function TonicBalanceModal({
  estimateTonicInBatchData,
  unclaimBoostTonicBalance,
  asset,
  onLockClaim,
  onClaimPartnerTokens,
  transactionStatus = null,
  ...props
}: TonicBalanceModalProps): JSX.Element {
  const [cardStep, setCardStep] = useState<CardStep>(CardStep.selectVault);
  const [selectedVault, setSelectedVault] =
    useState<LockingPeriodDetails | null>(null);
  const [lockedPercent, setLockedPercent] = useState(MIN_LOCKED);
  const [mode, setMode] = useState<Mode>('tonic');

  const isMobile = useIsMobile();

  const { loaded, usdPrices } = useUsdPrices();
  const { loaded: isLoadVaults, data } = useVaultsData();

  useEffect(() => {
    if (!props.isOpen) setCardStep(CardStep.selectVault);
  }, [props.isOpen]);

  const unclaimedBoostBalance = unclaimBoostTonicBalance ?? BigNumber.from(0);

  const unclaimedBalance =
    estimateTonicInBatchData?.add(unclaimedBoostBalance) || BigNumber.from(0);

  const displayTokenRewardsBalance =
    estimateTonicInBatchData || BigNumber.from(0);

  const isLtMinClaimAmount = useMemo(
    () => unclaimedBalance.lt(utils.parseEther(MIN_CLAIM_TONIC_AMOUNT)),
    [unclaimedBalance]
  );

  useEffect(() => {
    if (mode === 'tonic') {
      if (isLtMinClaimAmount && cardStep !== CardStep.ltMinClaimTonic) {
        setCardStep(CardStep.ltMinClaimTonic);
      }
      if (!isLtMinClaimAmount && cardStep === CardStep.ltMinClaimTonic) {
        setCardStep(CardStep.selectVault);
      }
    }
  }, [mode, isLtMinClaimAmount, cardStep]);

  const handleClick = () => {
    if (isLtMinClaimAmount) props.onClose();

    if (cardStep === CardStep.confirmCard) {
      if (selectedVault && lockedPercent)
        onLockClaim((lockedPercent / 100).toString(), selectedVault?.poolId);
    } else {
      setCardStep((prev) => prev + 1);
    }
  };

  const onBack = () => {
    setCardStep((prev) => prev - 1);
  };

  const getUnclaimTonicAmount = useCallback(
    (unclaimBalance: BigNumber, percent: number) =>
      asset && unclaimedBalance
        ? formatUserUnderlyingAmount(
            asset,
            unclaimBalance.mul(percent).div('100')
          )
        : '0',
    [asset, unclaimedBalance]
  );

  const tonicPrice = useMemo(
    () => (loaded && asset && usdPrices ? usdPrices[asset.symbol] ?? '0' : '0'),
    [loaded, usdPrices, asset]
  );

  const getUnclaimTonicUsdValue = useCallback(
    (balance: BigNumber) =>
      asset
        ? formatUserUnderlyingUsdValue(
            asset,
            getUnderlyingUsdValue(asset, balance, BigNumber.from(tonicPrice))
          )
        : '0',
    [asset, tonicPrice]
  );

  const totalBalanceToUsdValue = getUnclaimTonicUsdValue(
    estimateTonicInBatchData ?? BigNumber.from(0)
  );

  const boostTonicToUsdValue = getUnclaimTonicUsdValue(unclaimedBoostBalance);

  const lockedToUsdValue = getUnclaimTonicUsdValue(
    unclaimedBalance.mul(lockedPercent).div('100')
  );
  const walletToUsdValue = getUnclaimTonicUsdValue(
    unclaimedBalance.mul(100 - lockedPercent).div('100')
  );

  const lockedToValueAmount = getUnclaimTonicAmount(
    unclaimedBalance,
    lockedPercent
  );
  const walletValueAmount = getUnclaimTonicAmount(
    unclaimedBalance,
    100 - lockedPercent
  );
  const unclaimValueAmount = getUnclaimTonicAmount(unclaimedBalance, 100);
  const displayTokenRewardsAmount = getUnclaimTonicAmount(
    displayTokenRewardsBalance,
    100
  );
  const unclaimBoostValueAmount = getUnclaimTonicAmount(
    unclaimedBoostBalance,
    100
  );

  const getXTonicAmountFromTonicAmount = useCallback(
    (tonicAmount: BigNumber | null, exchangeRate: BigNumber | undefined) =>
      asset && tonicAmount && exchangeRate
        ? formatUserUnderlyingAmount(
            asset,
            convertTonicToXTonic(tonicAmount, exchangeRate)
          )
        : '0',
    [asset]
  );

  const lockedXTonicToValueAmount = getXTonicAmountFromTonicAmount(
    unclaimedBalance.mul(lockedPercent).div('100'),
    data?.exchangeRate
  );

  const getButtonStatus = useMemo(() => {
    switch (cardStep) {
      case CardStep.ltMinClaimTonic:
        return {
          disabled: false,
          label: 'Close',
        };

      case CardStep.selectVault:
        return {
          disabled: isLtMinClaimAmount,
          label: 'Select Vault',
        };

      case CardStep.lockInVault:
        return {
          disabled: !selectedVault,
          label: 'Lock in vault',
        };

      default:
        return {
          disabled: false,
          label: 'Confirm',
        };
    }
  }, [cardStep, selectedVault, isLtMinClaimAmount]);

  const isTransactionAwaitingConfirmation =
    transactionStatus === 'awaiting_confirmation';
  const isTransactionPending = transactionStatus === 'pending';
  const isTransactionConfirmed = transactionStatus === 'confirmed';
  const isTransactionFailed = transactionStatus === 'failed';

  const isResetHight =
    isTransactionAwaitingConfirmation ||
    isTransactionPending ||
    isTransactionConfirmed ||
    isTransactionFailed;

  return (
    <BaseTransactionModal
      title="Unclaimed balance"
      variant="wallet"
      onBack={
        [CardStep.confirmCard, CardStep.lockInVault].includes(cardStep)
          ? onBack
          : undefined
      }
      className={clsx('w-[510px] overflow-hidden desktop:relative', {
        'fixed bottom-0 right-0 top-0 left-0 !h-[100vh] !max-h-screen !w-[100vw]':
          isMobile,
        'h-[666px]': !isResetHight,
      })}
      {...props}
      isHideCloseIcon={isMobile}
      isShowMobileSliderClose={isMobile}
      transactionStatus={transactionStatus}
      renderTransactionStatus={() => {
        const showSpinner =
          isTransactionAwaitingConfirmation || isTransactionPending;

        return (
          <div className="text-center text-onSurface">
            {showSpinner && (
              <Icon.Spinner className="inline-block h-8 w-8 animate-spin" />
            )}
            {isTransactionAwaitingConfirmation && (
              <Text className="mt-4">
                Confirm the transaction in your wallet
              </Text>
            )}
            {isTransactionPending && (
              <Text className="mt-4">Transaction broadcast</Text>
            )}
            {isTransactionConfirmed && (
              <Icon.Check className="inline-block h-8 w-8" />
            )}
            {isTransactionFailed && (
              <Text>{props.transactionErrorMessage || 'Unknown error'}</Text>
            )}
            {(isTransactionPending || isTransactionConfirmed) && (
              <div className="pt-1">
                <a
                  className="text-yellowPrimary"
                  href={props.transactionExplorerHref as string}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  View on explorer
                </a>
                <Button className="mt-3 w-full" onClick={props.onClose}>
                  Done
                </Button>
              </div>
            )}
          </div>
        );
      }}
    >
      <div className={clsx('mobile:min-h-[88vh]')}>
        <Features.PartnerTokenRewards>
          <ModeSwitch
            className="!my-4 !ml-0 max-h-[48px] !justify-start gap-4"
            modes={modes}
            variant="tabs"
            mode={mode}
            onModeChange={setMode}
          />
        </Features.PartnerTokenRewards>
        <div
          className={clsx('overflow-y-auto', {
            'pb-10 desktop:h-[500px] mobile:h-screen mobile:pb-[160px]':
              cardStep === CardStep.selectVault,
            'desktop:h-[800px] mobile:h-screen mobile:pb-[220px]':
              cardStep === CardStep.lockInVault,
            'desktop:h-[400px] mobile:h-screen mobile:pb-[160px]':
              cardStep === CardStep.confirmCard,
          })}
        >
          {mode === 'tonic' && (
            <>
              {cardStep === CardStep.ltMinClaimTonic && (
                <Empty
                  unclaimValueAmount={displayTokenRewardsAmount}
                  unclaimBoostValueAmount={unclaimBoostValueAmount}
                  boostTonicToUsdValue={boostTonicToUsdValue}
                  totalBalanceToUsdValue={totalBalanceToUsdValue}
                />
              )}
              {cardStep === CardStep.selectVault && (
                <SelectLocked
                  lockedPercent={lockedPercent}
                  setLockedPercent={setLockedPercent}
                  lockedToValueAmount={lockedToValueAmount}
                  walletValueAmount={walletValueAmount}
                  totalBalanceToUsdValue={totalBalanceToUsdValue}
                  lockedToUsdValue={lockedToUsdValue}
                  walletToUsdValue={walletToUsdValue}
                  unclaimValueAmount={displayTokenRewardsAmount}
                  unclaimBoostValueAmount={unclaimBoostValueAmount}
                  boostTonicToUsdValue={boostTonicToUsdValue}
                />
              )}
              {cardStep === CardStep.lockInVault && (
                <VaultTier
                  selectedVault={selectedVault}
                  onSelectVaultTier={setSelectedVault}
                  isLoading={isLoadVaults}
                  pools={data?.pools ?? []}
                  lockedToValueAmount={lockedToValueAmount}
                  lockedXTonicToValueAmount={lockedXTonicToValueAmount}
                  lockedToUsdValue={lockedToUsdValue}
                />
              )}
              {cardStep === CardStep.confirmCard && (
                <ConfirmClaimCard
                  lockedToValueAmount={lockedToValueAmount}
                  walletValueAmount={walletValueAmount}
                  lockedToUsdValue={lockedToUsdValue}
                  walletToUsdValue={walletToUsdValue}
                  totalBalanceToUsdValue={totalBalanceToUsdValue}
                  lockedXTonicToValueAmount={lockedXTonicToValueAmount}
                  unclaimValueAmount={displayTokenRewardsAmount}
                  selectedVault={selectedVault}
                  period={selectedVault?.period}
                  lockedPercent={lockedPercent}
                  unclaimBoostValueAmount={unclaimBoostValueAmount}
                  boostTonicToUsdValue={boostTonicToUsdValue}
                />
              )}
              <div className="absolute bottom-0 left-0 z-50 w-full bg-blueElevatedSurface p-3">
                <Button
                  className="bottom-0 w-full"
                  disabled={getButtonStatus.disabled}
                  onClick={handleClick}
                >
                  {getButtonStatus.label}
                </Button>
              </div>
            </>
          )}
          <Features.PartnerTokenRewards>
            {mode === 'partner' && (
              <PartnerTokensTabContent
                onClaimPartnerTokens={onClaimPartnerTokens}
              />
            )}
          </Features.PartnerTokenRewards>
        </div>
      </div>
    </BaseTransactionModal>
  );
}

export default memo(TonicBalanceModal);
