import { FunctionComponent, SVGAttributes } from 'react';
import { DeFiWallet } from '@web3-wallet/defiwallet';
import type { Connector, WalletName } from '@web3-wallet/react';
import type { WalletConnectOptions } from '@web3-wallet/walletconnect';
import { Icon } from '@tectonicfi/tectonic-ui-kit';
import { BraveWallet } from '@web3-wallet/brave-wallet';
import { MetaMask } from '@web3-wallet/metamask';
import { WalletConnect } from '@web3-wallet/walletconnect';

import { getNetworkConfig, RPC_URLS } from '@config/baseNetworkConfig';

const {
  Metamask: MetamaskIcon,
  CryptoComWallet: CryptoComWalletIcon,
  WalletConnect: WalletConnectIcon,
  BraveWallet: BraveWalletIcon,
} = Icon;

export interface WalletConfig {
  name: WalletName;
  title: string;
  icon: FunctionComponent<SVGAttributes<SVGElement>>;
  connector: Connector;
  getDownloadLink: (options: { isMobile: boolean }) => string | undefined;
  trackingId: string;
}

const walletConnectProviderOptions: WalletConnectOptions['providerOptions'] = {
  projectId: process.env.WALLET_CONNECT_PROJECT_ID ?? '',
  rpcMap: RPC_URLS,
  chains: [Number(getNetworkConfig().chainId)],
  showQrModal: true,
  optionalMethods: ['eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign'],
  qrModalOptions: {
    // 'dark' | 'light'
    themeMode: 'light',
    themeVariables: {
      '--wcm-z-index': '99999999',
    },
  },
};

export const walletConfigs: WalletConfig[] = [
  {
    title: 'MetaMask',
    name: MetaMask.walletName,
    icon: MetamaskIcon,
    connector: new MetaMask({
      // p.isTrust is to enable using MetaMask to connect on DeFi Wallet
      // Brave wallet and MetaMask can't co-exist
      providerFilter: (p) =>
        (!!p.isMetaMask || !!p.isTrust) && !p.isBraveWallet,
    }),
    getDownloadLink: () => {
      if (typeof window === 'undefined') return undefined;
      /**
       * deeplink generator
       * https://metamask.github.io/metamask-deeplinks/
       */
      return `https://metamask.app.link/dapp/${window.location.hostname}`;
    },
    trackingId: 'injected',
  },
  {
    title: 'Crypto.com Onchain',
    name: DeFiWallet.walletName,
    icon: CryptoComWalletIcon,
    connector: new DeFiWallet(),
    getDownloadLink: ({ isMobile }) => {
      if (isMobile) {
        /**
         * https://bit.ly/3Bk4wzE
         * https://crypto.com/defi-wallet
         * */
        return 'https://crypto.com/defi-wallet';
      }
      return 'https://chrome.google.com/webstore/detail/cryptocom-wallet-extensio/hifafgmccdpekplomjjkcfgodnhcellj';
    },
    trackingId: 'defi_wallet',
  },
  {
    title: 'WalletConnect',
    name: WalletConnect.walletName,
    icon: WalletConnectIcon,
    // https://web3-wallet.github.io/web3-wallet/wallets/walletconnect
    connector: new WalletConnect({
      providerOptions: walletConnectProviderOptions,
    }),
    getDownloadLink: () => undefined,
    trackingId: 'wallet_connect',
  },
  {
    title: 'Brave Wallet',
    name: BraveWallet.walletName,
    icon: BraveWalletIcon,
    connector: new BraveWallet(),
    getDownloadLink: () => undefined,
    trackingId: 'brave_wallet',
  },
];

export const getWalletConfig = (name: WalletName): WalletConfig =>
  walletConfigs.find((v) => v.name === name) as WalletConfig;
