import { Text } from '@tectonicfi/tectonic-ui-kit';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  TectonicAsset,
  TectonicVaultStakeInfo,
} from '@cronos-labs/tectonic-sdk/dist/types';

import Card from '@components/Card';
import {
  LockingPeriod,
  LockingPeriodDetails,
} from '@components/LockDepositModal/types';
import Spinner from '@components/Spinner';
import TonicPageCardSection from '@components/TonicPageView/TonicPageCardSection';
import { PoolId } from '@components/VaultsPageView/types';
import useIsMobile from '@hooks/useIsMobile';
import { UseTransactionModalsStateResult } from '@providers/TransactionModalsProvider/useTransactionModalsState';
import useSupportedAsset from '@hooks/useSupportedAsset';
import { TxState } from 'hooks/useGetTxStatus';

import VaultInfo from '../VaultInfo';
import UpgradeLockingPeriod from '../UpgradeLockingPeriod';
import WithdrawVault from '../WithdrawVault';
import EmptyNFTModal from '../StakingNFT/StakingNFTModal/EmptyNFTModal';

import { PoolOfUser } from './types';
import PoolOverview from './PoolOverview';
import { VaultsBlockState, VaultsBlockView } from './types';

export interface VaultBlockProps {
  loaded: boolean;
  pools: PoolOfUser[];
  onDepositMoreClick: (period: LockingPeriod) => void;
  deposits: TectonicVaultStakeInfo[];
  onOpenModal: UseTransactionModalsStateResult['onOpenModal'];
  vaultsBlockState: VaultsBlockState;
  setVaultsBlockState: Dispatch<SetStateAction<VaultsBlockState>>;
  txState: Null<TxState>;
}

export default function VaultsBlock({
  pools,
  loaded,
  deposits,
  onDepositMoreClick,
  onOpenModal,
  vaultsBlockState,
  setVaultsBlockState,
  txState,
}: VaultBlockProps) {
  const tonicAsset = useSupportedAsset('TONIC');
  const isMobile = useIsMobile();
  const [isEmptyNFTModalOpen, setEmptyNFTModalOpen] = useState(false);

  const onPoolClick = (id: PoolId, activeView?: VaultsBlockView) => {
    setVaultsBlockState({
      activePool: id,
      activeView: activeView || 'VaultInfo',
      activeDeposits: undefined,
    });
  };
  const handleUpgradeLockingPeriodClick = (
    id: PoolId,
    depositsForPeriod: TectonicVaultStakeInfo[]
  ) => {
    setVaultsBlockState({
      activePool: id,
      activeView: 'UpgradeLockingPeriod',
      activeDeposits: depositsForPeriod,
    });
  };
  const handleWithdrawVaultClick = (
    id: PoolId,
    unlockedDeposits: TectonicVaultStakeInfo[]
  ) => {
    setVaultsBlockState({
      activePool: id,
      activeView: 'WithdrawVault',
      activeDeposits: unlockedDeposits,
    });
  };

  const onBackToOverview = () => {
    setVaultsBlockState({
      activePool: undefined,
      activeView: 'PoolOverview',
      activeDeposits: undefined,
    });
  };

  const onLockDeposit = (period: LockingPeriod) =>
    onOpenModal(tonicAsset as TectonicAsset, 'lockDeposit', {
      meta: { type: 'lockDeposit', preselectedPeriod: period },
    });

  const onOpenUpgradeModal = (
    stakeInfos: TectonicVaultStakeInfo[],
    availablePeriods: LockingPeriodDetails[]
  ) =>
    onOpenModal(tonicAsset as TectonicAsset, 'upgradeVault', {
      meta: {
        type: 'upgradeVault',
        deposits: stakeInfos,
        availablePeriods,
      },
    });

  const onWithdrawModal = (
    apr: string,
    depositIds: number[],
    totalXTonicUnlocked: string,
    multiplier: string
  ) =>
    onOpenModal(tonicAsset as TectonicAsset, 'withdrawDeposit', {
      meta: {
        type: 'withdrawDeposit',
        apr,
        stakeIds: depositIds,
        totalXTonicUnlocked,
        multiplier,
      },
    });

  const pool = useMemo<PoolOfUser>(
    () =>
      pools.find(
        (p) => p.poolId === (vaultsBlockState.activePool as number)
      ) as PoolOfUser,
    [pools, vaultsBlockState]
  );

  const handleOpenNFTStakingModal = useCallback(
    (poolId: number, hasUserNFTs: boolean) => {
      if (!hasUserNFTs) return setEmptyNFTModalOpen(true);
      onOpenModal(null, 'nftStaking', {
        meta: { type: 'nftStaking', poolId },
      });
    },
    [onOpenModal]
  );

  if (!loaded) {
    return (
      <div className="flex h-full min-h-[300px] flex-col items-center justify-center">
        {/* This could be a skeleton of 4 collapsed cards */}
        <Spinner />
      </div>
    );
  }

  return (
    <Card
      padding="none"
      border={!isMobile}
      variant={isMobile ? 'no-background' : 'default'}
    >
      <TonicPageCardSection borderBottom className="mobile:hidden">
        <Text variant="semiBold">My Vaults</Text>
      </TonicPageCardSection>
      <div className="m-2 mobile:m-0">
        {vaultsBlockState.activeView === 'PoolOverview' && (
          <PoolOverview
            pools={pools}
            onPoolClick={onPoolClick}
            onDepositMoreClick={onDepositMoreClick}
            onOpenNFTStakingModal={handleOpenNFTStakingModal}
            txState={txState}
          />
        )}
        {vaultsBlockState.activeView === 'VaultInfo' && (
          <VaultInfo
            pool={pool}
            onBackToOverview={onBackToOverview}
            deposits={deposits}
            onDepositMoreClick={onLockDeposit}
            onWithdrawClick={handleWithdrawVaultClick}
            onUpgradeLockingPeriodClick={handleUpgradeLockingPeriodClick}
            onOpenNFTStakingModal={handleOpenNFTStakingModal}
            txState={txState}
          />
        )}
        {vaultsBlockState.activeView === 'UpgradeLockingPeriod' && (
          <UpgradeLockingPeriod
            pools={pools}
            onBackToVaultInfo={onPoolClick}
            deposits={vaultsBlockState.activeDeposits ?? []}
            poolId={vaultsBlockState.activePool ?? 0}
            onConfirmSelection={onOpenUpgradeModal}
          />
        )}
        {vaultsBlockState.activeView === 'WithdrawVault' && (
          <WithdrawVault
            pools={pools}
            onBackToVaultInfo={onPoolClick}
            deposits={vaultsBlockState.activeDeposits ?? []}
            poolId={vaultsBlockState.activePool ?? 0}
            onConfirmSelection={onWithdrawModal}
          />
        )}
      </div>
      <EmptyNFTModal
        isOpen={isEmptyNFTModalOpen}
        onClose={() => setEmptyNFTModalOpen(false)}
      />
    </Card>
  );
}
