import { PageSection } from '@tectonicfi/tectonic-ui-kit';

import {
  SavingsIntroduction,
  InstantLoansIntroduction,
} from '../LandingBodyContent';

import BackgroundImageContainer from './BackgroundImageContainer';

function IntroSection(): JSX.Element {
  return (
    <BackgroundImageContainer
      height="1110px"
      src="/images/introduction-background.png"
    >
      <PageSection
        className="h-full"
        contentWrapperClassName="w-full flex flex-col items-start justify-center mobile:items-center"
      >
        <div className="mb-[220px]">
          <SavingsIntroduction />
        </div>
        <div className="self-end">
          <InstantLoansIntroduction />
        </div>
      </PageSection>
    </BackgroundImageContainer>
  );
}

export default IntroSection;
