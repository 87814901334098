import { PageSection } from '@tectonicfi/tectonic-ui-kit';

import { Hero } from '../LandingBodyContent';

import BackgroundImageContainer from './BackgroundImageContainer';

function HeroSection(): JSX.Element {
  return (
    <BackgroundImageContainer height="680px" src="/images/hero-background.png">
      <PageSection
        className="h-full"
        contentWrapperClassName="w-full flex items-center justify-center"
      >
        <Hero />
      </PageSection>
    </BackgroundImageContainer>
  );
}

export default HeroSection;
