import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';
import { BigNumber, utils } from 'ethers';

import { CRO_GAS_BUFFER } from '@config/constants';

import { subtractGasBuffer } from './math';
import { isNativeToken } from './utils';

interface MaxBorrowParams {
  userMaxBorrowAmount: BigNumber;
  hasAnyCollateralEnabled: boolean | null;
}

interface MaxRepayParams {
  walletBalance: BigNumber;
  currentBorrowAmount: BigNumber;
}

interface MaxSupplyParams {
  walletBalance: BigNumber;
}

interface MaxWithdrawParams {
  userMaxWithdrawAmount: BigNumber;
}

export function removeTrailingZero(amount: string): string {
  const values = amount.split('.');
  if (values[1] === '0') {
    return values[0];
  }
  return amount;
}

export function determineSupplyMaxAmount(
  asset: TectonicAsset,
  { walletBalance }: MaxSupplyParams
): string {
  if (walletBalance.isZero()) return '0';

  if (isNativeToken(asset)) {
    return removeTrailingZero(
      utils.formatUnits(subtractGasBuffer(walletBalance, asset), asset.decimals)
    );
  }

  return removeTrailingZero(utils.formatUnits(walletBalance, asset.decimals));
}

export function determineRepayMaxAmount(
  asset: TectonicAsset,
  { walletBalance, currentBorrowAmount }: MaxRepayParams
): string {
  if (walletBalance.isZero()) return '0';

  if (isNativeToken(asset)) {
    // Deduct 2 CRO from the wallet balance
    if (walletBalance.lte(currentBorrowAmount)) {
      return removeTrailingZero(
        utils.formatUnits(
          subtractGasBuffer(walletBalance, asset),
          asset.decimals
        )
      );
    }

    // Deduct 2 CRO from the wallet balance if wallet balance is greater than borrow amount by less than 2
    const walletBalanceBorrowAmountDifference =
      walletBalance.sub(currentBorrowAmount);
    const gasBuffer = utils.parseUnits(CRO_GAS_BUFFER, asset.decimals);

    if (walletBalanceBorrowAmountDifference.lt(gasBuffer)) {
      return removeTrailingZero(
        utils.formatUnits(
          subtractGasBuffer(walletBalance, asset),
          asset.decimals
        )
      );
    }

    // Do not deduct 2 CRO from the borrow amount if wallet balance is greater than borrow amount by 2 or more
    return removeTrailingZero(
      utils.formatUnits(currentBorrowAmount, asset.decimals)
    );
  }

  if (walletBalance.lte(currentBorrowAmount)) {
    return removeTrailingZero(utils.formatUnits(walletBalance, asset.decimals));
  }

  return removeTrailingZero(
    utils.formatUnits(currentBorrowAmount, asset.decimals)
  );
}

export function determineWithdrawMaxAmount(
  asset: TectonicAsset,
  { userMaxWithdrawAmount }: MaxWithdrawParams
): string {
  return removeTrailingZero(
    utils.formatUnits(userMaxWithdrawAmount, asset.decimals)
  );
}

export function determineBorrowMaxAmount(
  asset: TectonicAsset,
  { userMaxBorrowAmount, hasAnyCollateralEnabled }: MaxBorrowParams
): string {
  if (userMaxBorrowAmount.isZero() || !hasAnyCollateralEnabled) return '0';

  return removeTrailingZero(
    utils.formatUnits(userMaxBorrowAmount, asset.decimals)
  );
}
