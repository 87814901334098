import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TectonicAsset, TectonicSDK } from '@cronos-labs/tectonic-sdk';

import { AppThunk } from '@types';
import { notify } from '@lib/bugsnag';

type MarketDeFiDataMap<T> = Record<string, Undefined<T>>;

type MarketDeFiDataLoadingMap = Record<string, Undefined<boolean>>;

export interface MarketsSliceState {
  borrowAmounts: MarketDeFiDataMap<string>;
  isRefreshingBorrowAmount: MarketDeFiDataLoadingMap;
  isRefreshingSupplyAmount: MarketDeFiDataLoadingMap;
  supplyAmounts: MarketDeFiDataMap<string>;
}

export const initialState: MarketsSliceState = {
  borrowAmounts: {},
  isRefreshingBorrowAmount: {},
  isRefreshingSupplyAmount: {},
  supplyAmounts: {},
};

const defiPoolMarketsSlice = createSlice({
  initialState,
  name: 'defiMarkets',
  reducers: {
    refreshMarketBorrowAmountPending(
      state,
      action: PayloadAction<{ asset: TectonicAsset }>
    ): void {
      state.isRefreshingBorrowAmount[action.payload.asset.symbol] = true;
    },
    refreshMarketBorrowAmountFulfilled(
      state,
      action: PayloadAction<{ asset: TectonicAsset; value: string }>
    ): void {
      state.isRefreshingBorrowAmount[action.payload.asset.symbol] = false;
      state.borrowAmounts[action.payload.asset.symbol] = action.payload.value;
    },
    refreshMarketBorrowAmountRejected(
      state,
      action: PayloadAction<{ asset: TectonicAsset }>
    ): void {
      state.isRefreshingBorrowAmount[action.payload.asset.symbol] = false;
    },
    refreshMarketSupplyAmountPending(
      state,
      action: PayloadAction<{ asset: TectonicAsset }>
    ): void {
      state.isRefreshingSupplyAmount[action.payload.asset.symbol] = true;
    },
    refreshMarketSupplyAmountFulfilled(
      state,
      action: PayloadAction<{ asset: TectonicAsset; value: string }>
    ): void {
      state.isRefreshingSupplyAmount[action.payload.asset.symbol] = false;
      state.supplyAmounts[action.payload.asset.symbol] = action.payload.value;
    },
    refreshMarketSupplyAmountRejected(
      state,
      action: PayloadAction<{ asset: TectonicAsset }>
    ): void {
      state.isRefreshingSupplyAmount[action.payload.asset.symbol] = false;
    },
  },
});

const {
  refreshMarketBorrowAmountFulfilled,
  refreshMarketBorrowAmountPending,
  refreshMarketBorrowAmountRejected,
  refreshMarketSupplyAmountFulfilled,
  refreshMarketSupplyAmountPending,
  refreshMarketSupplyAmountRejected,
} = defiPoolMarketsSlice.actions;

export function refreshAssetBorrowAmount(
  sdk: TectonicSDK,
  asset: TectonicAsset
): AppThunk<Promise<void>> {
  return async function getAssetBorrowAmountThunk(
    dispatch,
    getState
  ): Promise<void> {
    const state = getState();

    if (!state.defiMarkets.isRefreshingBorrowAmount[asset.symbol]) {
      try {
        dispatch(refreshMarketBorrowAmountPending({ asset }));
        const assetBorrowAmount = await sdk.Borrow.totalBorrowedAmountInMarket(
          asset.tTokenAddress
        );
        dispatch(
          refreshMarketBorrowAmountFulfilled({
            asset,
            value: assetBorrowAmount.toString(),
          })
        );
      } catch (error) {
        notify(error);
        dispatch(refreshMarketBorrowAmountRejected({ asset }));
      }
    }
  };
}

export function getAssetBorrowAmount(
  sdk: TectonicSDK,
  asset: TectonicAsset
): AppThunk<Promise<void>> {
  return async function getAssetBorrowAmountThunk(
    dispatch,
    getState
  ): Promise<void> {
    const state = getState();
    const cachedBorrowAmount = state.defiMarkets.borrowAmounts[asset.symbol];

    if (!cachedBorrowAmount) {
      dispatch(refreshAssetBorrowAmount(sdk, asset));
    }
  };
}

export function refreshAssetSupplyAmount(
  sdk: TectonicSDK,
  asset: TectonicAsset
): AppThunk<Promise<void>> {
  return async function getAssetSupplyAmountThunk(
    dispatch,
    getState
  ): Promise<void> {
    const state = getState();

    if (!state.defiMarkets.isRefreshingSupplyAmount[asset.symbol]) {
      try {
        dispatch(refreshMarketSupplyAmountPending({ asset }));
        const [totalSupply, reserves] = await Promise.all([
          sdk.Supply.totalSuppliedUnderlyingAmountInMarket(asset.tTokenAddress),
          sdk.Supply.reserves(asset.tTokenAddress),
        ]);
        const assetSupplyAmount = totalSupply.add(reserves);
        dispatch(
          refreshMarketSupplyAmountFulfilled({
            asset,
            value: assetSupplyAmount.toString(),
          })
        );
      } catch (error) {
        notify(error);
        dispatch(refreshMarketSupplyAmountRejected({ asset }));
      }
    }
  };
}

export function getAssetSupplyAmount(
  sdk: TectonicSDK,
  asset: TectonicAsset
): AppThunk<Promise<void>> {
  return async function getAssetSupplyAmountThunk(
    dispatch,
    getState
  ): Promise<void> {
    const state = getState();
    const cachedSupplyAmount = state.defiMarkets.supplyAmounts[asset.symbol];

    if (!cachedSupplyAmount) {
      dispatch(refreshAssetSupplyAmount(sdk, asset));
    }
  };
}

export default defiPoolMarketsSlice.reducer;
