import { TectonicAsset } from '@cronos-labs/tectonic-sdk';
import { BigNumber, utils } from 'ethers';
import numbro from 'numbro';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@config/queryKey';
import useUsdPrices from '@hooks/useUsdPrices';
import { getUnderlyingUsdValue } from '@lib/math';
import { formatTonic, getUsdPriceDecimals } from '@lib/units';
import { TectonicSdkContext } from '@providers/TectonicSdkProvider';
import { getQuery } from '@queries/queries';

export default function useBountyConversionData(
  tonicAsset: TectonicAsset,
  walletAddress: string | null
) {
  const sdk = useContext(TectonicSdkContext);
  const { loaded: usdPricesLoaded, usdPrices } = useUsdPrices();

  const conversionBountyDataQuery = getQuery(
    QueryKey.TECTONIC_CONVERSION_BOUNTY_DATA
  )(sdk, walletAddress ?? '');

  const { data, isLoading: queriesLoading } = useQuery(
    conversionBountyDataQuery.queryKey,
    conversionBountyDataQuery.queryFn,
    { enabled: !!sdk, refetchInterval: 5000 }
  );
  const isLoading =
    queriesLoading || !usdPricesLoaded || !usdPrices || !usdPrices['TONIC'];
  if (isLoading || !data) {
    return { isLoading: true };
  }
  const tectonicPrice = BigNumber.from(usdPrices['TONIC']);

  const { bountyYield, bountyReward } = data;

  const formattedBountyYield = formatTonic(bountyYield);
  const formattedBountyReward = formatTonic(bountyReward);

  const bountyRewardUsd = getUnderlyingUsdValue(
    tonicAsset,
    bountyReward,
    tectonicPrice
  );

  const convertedBountyRewardUsd = utils.formatUnits(
    bountyRewardUsd,
    getUsdPriceDecimals(tonicAsset)
  );
  const formattedBountyRewardUsd = `$${numbro(convertedBountyRewardUsd).format({
    mantissa: 2,
    thousandSeparated: true,
    trimMantissa: true,
  })}`;
  return {
    isLoading,
    rawBountyReward: bountyReward,
    bountyYield: formattedBountyYield,
    bountyReward: formattedBountyReward,
    bountyRewardUsd: formattedBountyRewardUsd,
  };
}
