import { BigNumber } from '@ethersproject/bignumber';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk';

export type ValidateCollateralToggleActions =
  | 'disable'
  | 'enable'
  | 'dismiss'
  | null;

interface ValidateCollateralToggleParams {
  action: ValidateCollateralToggleActions;
  asset: TectonicAsset | null;
  assetBorrowAmount: BigNumber | null;
}

export interface ValidateCollateralToggleResult {
  reason: 'invalid' | 'insufficientLiquidity' | null;
  valid: boolean;
}

function validateCollateralToggle({
  action,
  asset,
  assetBorrowAmount,
}: ValidateCollateralToggleParams): ValidateCollateralToggleResult {
  if (action === 'dismiss') return { valid: true, reason: null };

  if (asset && assetBorrowAmount) {
    if (!action) return { valid: false, reason: 'invalid' }; // though this may not occur, add just to be safe

    // Contract will throw an error if you disable collateral for asset while still borrowing that asset
    // disable call to action button to prevent error / wasted transcation
    if (action === 'disable' && !assetBorrowAmount.isZero()) {
      return { valid: false, reason: 'insufficientLiquidity' };
    }

    return { valid: true, reason: null };
  }

  return {
    valid: false,
    reason: 'invalid',
  };
}

export default validateCollateralToggle;
