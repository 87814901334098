import { TectonicAsset } from '@cronos-labs/tectonic-sdk';

export const getBoostedAssetDetails = (
  boostedAsset: TectonicAsset[],
  tTokenAddress?: TectonicAsset['tTokenAddress']
) => {
  return boostedAsset.find((asset) => asset.tTokenAddress === tTokenAddress);
};

export const checkBoost = (
  tTokenAddress: string,
  boostedCollections?: string[]
) => {
  return boostedCollections?.some((tToken) => tToken === tTokenAddress);
};
