import { PageSection } from '@tectonicfi/tectonic-ui-kit';

import Stats from '../LandingBodyContent/Stats';

import BackgroundImageContainer from './BackgroundImageContainer';

function StatsSection(): JSX.Element {
  return (
    <BackgroundImageContainer
      height="400px"
      src="/images/total-value-background.png"
    >
      <PageSection
        className="h-full"
        contentWrapperClassName="w-full flex items-center justify-center mobile:items-center"
      >
        <Stats />
      </PageSection>
    </BackgroundImageContainer>
  );
}

export default StatsSection;
