import { StackView, Text, Icon } from '@tectonicfi/tectonic-ui-kit';

interface Props {
  containerClassName?: string;
}

const imageClass = 'w-[128px] h-[128px]';

function Features({ containerClassName }: Props) {
  return (
    <StackView spacing={2} className={containerClassName}>
      <Text as="h2" variant="heading2">
        Keeping your funds safe is our top priority
      </Text>

      <StackView direction="horizontal" spacing={2}>
        <StackView spacing={2}>
          <Icon.Audit className={imageClass} />
          <Text as="h3" variant="heading3">
            Audited smart contracts
          </Text>
          <Text>
            Our smart contracts have been audited by leading blockchain security
            auditors Slowmist.
          </Text>
        </StackView>

        <StackView spacing={2}>
          <Icon.Insurance className={imageClass} />
          <Text as="h3" variant="heading3">
            Insurance fund (Coming soon)
          </Text>
          <Text>
            10% of the interest paid by borrowers goes to an insurance fund used
            in the event that undercollateralized loans are not properly
            liquidated.
          </Text>
        </StackView>

        <StackView spacing={2}>
          <Icon.OpenSource className={imageClass} />
          <Text as="h3" variant="heading3">
            Open source
          </Text>
          <Text>
            Interoperability and open source are among the founding principles
            of DeFi, which Tectonic is proudly committed to.
          </Text>
        </StackView>
      </StackView>
    </StackView>
  );
}

export default Features;
