import { useCallback, useReducer, useEffect } from 'react';
import { TectonicSDK, TectonicAsset } from '@cronos-labs/tectonic-sdk';
import { BigNumber } from 'ethers';

import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import { PoolType } from '@config/base';
interface TotalMarketAvailableAmountState {
  balance: BigNumber | null;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
}

const initState: TotalMarketAvailableAmountState = {
  balance: null,
  hasError: false,
  loaded: false,
  loading: false,
};

const GET_FULFILLED = 'GET_FULFILLED';

const GET_PENDING = 'GET_PENDING';

const GET_REJECTED = 'GET_REJECTED';

const RESET = 'RESET';

interface GetPendingAction {
  type: typeof GET_PENDING;
}

interface GetFulfilledAction {
  balance: BigNumber;
  type: typeof GET_FULFILLED;
}

interface GetRejectedAction {
  type: typeof GET_REJECTED;
}

interface ResetAction {
  type: typeof RESET;
}

type TotalMarketAvailableAmountAction =
  | GetFulfilledAction
  | GetPendingAction
  | GetRejectedAction
  | ResetAction;

function reducer(
  state: TotalMarketAvailableAmountState,
  action: TotalMarketAvailableAmountAction
): TotalMarketAvailableAmountState {
  switch (action.type) {
    case GET_PENDING:
      return { ...state, loading: true };
    case GET_FULFILLED:
      return {
        ...state,
        balance: action.balance,
        loaded: true,
        loading: false,
      };
    case GET_REJECTED:
      return { ...state, loading: false, hasError: true };
    case RESET:
      return { ...state, ...initState };
    default:
      return { ...state };
  }
}

interface Options {
  skip?: boolean;
}
function useGetTotalMarketAvailableAmount(
  asset: Null<TectonicAsset>,
  poolType: PoolType,
  options?: Options
) {
  const skip = options?.skip ?? false;
  const { sdk } = useSdkAndSupportedAssets(poolType);
  const [state, dispatch] = useReducer(reducer, initState);

  const getData = useCallback(
    async (tectonicSdk: TectonicSDK, tectonicAsset: TectonicAsset) => {
      try {
        const getAssetTotalSupplyAmount = async () => {
          return tectonicSdk.Supply.totalSuppliedUnderlyingAmountInMarket(
            tectonicAsset.tTokenAddress
          );
        };

        const getAssetTotalBorrowAmount = async () => {
          return tectonicSdk.Borrow.totalBorrowedAmountInMarket(
            tectonicAsset.tTokenAddress
          );
        };

        const [assetTotalSupplyAmount, assetTotalBorrowAmount] =
          await Promise.all([
            getAssetTotalSupplyAmount(),
            getAssetTotalBorrowAmount(),
          ]);

        dispatch({
          type: GET_FULFILLED,
          balance: assetTotalSupplyAmount.sub(assetTotalBorrowAmount),
        });
      } catch (error) {
        dispatch({
          type: GET_REJECTED,
        });
      }
    },
    []
  );

  useEffect(() => {
    if (sdk && asset && !skip) {
      dispatch({ type: GET_PENDING });
      getData(sdk, asset);
    } else {
      dispatch({ type: RESET });
    }
  }, [sdk, asset, getData, skip]);

  return {
    balance: state.balance,
    loaded: state.loaded && !state.hasError,
    loading: state.loading,
  };
}

export default useGetTotalMarketAvailableAmount;
