import { BigNumber } from 'ethers';
import { useCallback, useReducer } from 'react';
import {
  TectonicAsset,
  TectonicVaultStakeInfo,
} from '@cronos-labs/tectonic-sdk/dist/types';

import { BaseTransactionModalProps } from '@components/BaseTransactionModal';
import { restrictInput } from '@lib/utils';
import {
  LockingPeriod,
  LockingPeriodDetails,
} from '@components/LockDepositModal/types';
import { VaultId } from '@components/VaultsPageView/types';

export type TransactionModalVariant =
  | 'boost'
  | 'updateBoost'
  | 'borrow'
  | 'swap'
  | 'short'
  | 'claimTonicAirdrop'
  | 'collateral'
  | 'pending'
  | 'repay'
  | 'stakeTonic'
  | 'supply'
  | 'tonicBalance'
  | 'unstakeTonic'
  | 'withdraw'
  | 'withdrawUnstakedTonic'
  | 'lockDeposit'
  | 'upgradeVault'
  | 'harvestVaultTonic'
  | 'withdrawDeposit'
  | 'claimBounty'
  | 'nftStaking';

export interface UnstakeTonicMeta {
  type: 'unstakeTonic';
  hasCooldown: boolean;
  xTonicToTonicExchangeRate: BigNumber;
}
export interface UpgradeVaultMeta {
  type: 'upgradeVault';
  deposits: TectonicVaultStakeInfo[];
  availablePeriods: LockingPeriodDetails[];
}

export interface WithdrawDepositMeta {
  type: 'withdrawDeposit';
  apr: string;
  stakeIds: VaultId[];
  totalXTonicUnlocked: string;
  multiplier: string;
}

export interface HarvestVaultTonicMeta {
  type: 'harvestVaultTonic';
  tonicAmount: BigNumber;
}
export interface ClaimBountyMeta {
  type: 'claimBounty';
  formattedAmount: string;
}
export interface LockDepositMeta {
  type: 'lockDeposit';
  preselectedPeriod?: LockingPeriod;
}
export interface NftStakingMeta {
  type: 'nftStaking';
  poolId: number;
}
export type TransactionModalMeta =
  | UnstakeTonicMeta
  | UpgradeVaultMeta
  | WithdrawDepositMeta
  | LockDepositMeta
  | HarvestVaultTonicMeta
  | ClaimBountyMeta
  | NftStakingMeta;

export interface TransactionModalsState {
  activeModal: TransactionModalVariant | null;
  amount: string;
  asset: TectonicAsset | null;
  meta: TransactionModalMeta | null;
  transactionStatus: BaseTransactionModalProps['transactionStatus'];
}

export const initState: TransactionModalsState = {
  activeModal: null,
  amount: '0',
  asset: null,
  meta: null,
  transactionStatus: null,
};

const CHANGE_AMOUNT = 'CHANGE_AMOUNT';

const CLOSE_MODAL = 'CLOSE_MODAL';

const OPEN_MODAL = 'OPEN_MODAL';

const ON_MODAL_CLOSED = 'ON_MODAL_CLOSED';

interface ChangeAmountAction {
  type: typeof CHANGE_AMOUNT;
  value: string;
}

interface CloseModalAction {
  type: typeof CLOSE_MODAL;
}

interface OpenModalAction {
  amount: string | null;
  asset: TectonicAsset;
  meta: TransactionModalMeta | null;
  type: typeof OPEN_MODAL;
  variant: TransactionModalVariant;
}

interface OnModalCloseAction {
  type: typeof ON_MODAL_CLOSED;
}

type TransactionModalsAction =
  | ChangeAmountAction
  | CloseModalAction
  | OnModalCloseAction
  | OpenModalAction;

function reducer(
  state: TransactionModalsState,
  action: TransactionModalsAction
): TransactionModalsState {
  switch (action.type) {
    case CHANGE_AMOUNT:
      return {
        ...state,
        amount: restrictInput(action.value, state.asset?.decimals || 6), // restricts up to asset's decimal OR 6, better lower than higher
      };
    case CLOSE_MODAL:
      return { ...state, activeModal: null };
    case OPEN_MODAL: {
      const newState: TransactionModalsState = {
        ...state,
        activeModal: action.variant,
        asset: action.asset,
      };

      if (action.amount !== null) {
        newState.amount = action.amount;
      }

      if (action.meta) {
        newState.meta = action.meta;
      }

      return newState;
    }
    case ON_MODAL_CLOSED:
      return { ...state, ...initState };
    default:
      return { ...state };
  }
}

export interface UseTransactionModalsStateResult {
  onAmountChange(value: string): void;
  onCloseModal(): void;
  onModalClosed(): void;
  onOpenModal(
    asset: TectonicAsset | null,
    variant: TransactionModalVariant,
    options?: { amount?: string; meta?: TransactionModalMeta }
  ): void;
  state: TransactionModalsState;
}

function useTransactionModalsState(): UseTransactionModalsStateResult {
  const [state, dispatch] = useReducer(reducer, initState);
  const onAmountChange = useCallback(
    (value: string) => dispatch({ type: CHANGE_AMOUNT, value }),
    []
  );
  const onCloseModal = useCallback(() => dispatch({ type: CLOSE_MODAL }), []);
  const onModalClosed = useCallback(
    () => dispatch({ type: ON_MODAL_CLOSED }),
    []
  );
  const onOpenModal = useCallback(
    (
      asset: TectonicAsset,
      variant: TransactionModalVariant,
      options?: { amount?: string; meta?: TransactionModalMeta }
    ) =>
      dispatch({
        amount: options?.amount ?? null,
        asset,
        meta: options?.meta ?? null,
        type: OPEN_MODAL,
        variant,
      }),
    []
  );

  return {
    onAmountChange,
    onCloseModal,
    onModalClosed,
    onOpenModal,
    state,
  };
}

export default useTransactionModalsState;
