import { TectonicAsset } from '@cronos-labs/tectonic-sdk';
import { BigNumber } from 'ethers';

import { formatUnits } from '@lib/units';
import { PoolType } from '@config/base';
import { TOKEN_INTEREST_INFOS } from '@config/TokenInterestInfo';
import { WalletBalanceState } from '@hooks/useWalletBalance';

export enum Dexes {
  vvs = 'vvs',
  wowmax = 'wowmax',
}

export const isMaxSupplyCap = (
  poolType: PoolType,
  symbol: string,
  assets: TectonicAsset[],
  supplyAmount?: string
): boolean => {
  const asset = assets.find((a) => a.symbol === symbol);

  if (!asset) return true;

  const assetSupplyAmount = supplyAmount ? BigNumber.from(supplyAmount) : null;

  if (!assetSupplyAmount) return false;

  const info = TOKEN_INTEREST_INFOS[poolType][asset.symbol];

  return (
    info?.supplyCap > 0 &&
    !!assetSupplyAmount
      .div(BigNumber.from(10).pow(asset.decimals))
      .gte(info.supplyCap)
  );
};

export const isOverflowBalance = (
  walletData: WalletBalanceState,
  amount: string
): boolean => {
  if (walletData.balance && walletData.asset) {
    return (
      Number(formatUnits(walletData.balance, walletData.asset?.decimals)) <
      Number(amount)
    );
  }
  return true;
};

export const checkTrailingZeros = (data: number) => {
  const result = data.toFixed(20).match(/^-?\d*\.?0*\d{0,3}/);
  return result ? result[0] : '';
};
