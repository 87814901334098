import { BigNumber, utils } from 'ethers';
import numbro from 'numbro';
import { Button, Icon, Skeleton, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk';

import Card from '@components/Card';
import DataRow from '@components/DataRow';
import SupportedAssetIcon from '@components/SupportedAssetIcon';
import { VVS_DECIMALS } from '@config/constants';
import { UseVvsFinance2022FebTonicAirdropDataResult } from '@hooks/useVvsFinance2022FebTonicAirdropData';
import {
  formatPercent,
  formatRateToPercent,
  formatTonicAirdropClaimAmount,
} from '@lib/units';
import { getShareOfVvsPoolRate } from '@lib/math';

import TonicPageCardSection from './TonicPageCardSection';

const VVS_CIRCULATING_SUPPLY = BigNumber.from(
  '5407174925839557547383116497691'
);

interface TonicAirdropCardDataRowProps {
  label: string;
  showSkeleton: boolean;
  value: string;
}

function TonicAirdropCardDataRow({
  label,
  showSkeleton,
  value,
}: TonicAirdropCardDataRowProps): JSX.Element {
  return (
    <DataRow
      label={<Text>{label}</Text>}
      value={
        showSkeleton ? (
          <Skeleton className="h-full w-6" />
        ) : (
          <Text>{value}</Text>
        )
      }
    />
  );
}

function formatVvsAmount(amount: BigNumber): string {
  try {
    const converted = utils.formatUnits(amount, VVS_DECIMALS);

    return numbro(converted).format({
      average: false,
      mantissa: 0,
      thousandSeparated: true,
    });
  } catch (error) {
    return '0';
  }
}

interface TonicAirdropCardProps
  extends Omit<UseVvsFinance2022FebTonicAirdropDataResult, 'refetch'> {
  onClaimTonic(amount: string): void;
  tonicAsset: TectonicAsset | null;
}

function TonicAirdropCard({
  hasClaimedAirdrop,
  hasError,
  hasNoSnapshot,
  loaded,
  loading,
  onClaimTonic,
  snapshot,
  tonicAsset,
}: TonicAirdropCardProps): JSX.Element {
  const showSkeleton = !loaded || loading;
  const hasSnapshot = !!(loaded && snapshot);

  function getButtonLabel(): string {
    if (hasClaimedAirdrop) {
      return 'Airdrop already claimed';
    }

    if (hasNoSnapshot) {
      return 'Nothing to claim';
    }

    return 'Claim';
  }

  if (hasError) {
    return (
      <Card className="flex flex-col justify-center" padding="none">
        <Text className="text-center">Something went wrong...</Text>
      </Card>
    );
  }

  return (
    <Card padding="none">
      <TonicPageCardSection borderBottom>
        <Text variant="semiBold">Airdrop</Text>
      </TonicPageCardSection>
      <TonicPageCardSection className="space-y-2" borderBottom>
        <TonicAirdropCardDataRow
          label="VVS held in wallet"
          showSkeleton={showSkeleton}
          value={hasSnapshot ? formatVvsAmount(snapshot.vvsInWallet) : '0'}
        />
        <TonicAirdropCardDataRow
          label="$VVS in VVS Glitter Mines"
          showSkeleton={showSkeleton}
          value={hasSnapshot ? formatVvsAmount(snapshot.vvsInMine) : '0'}
        />
        <TonicAirdropCardDataRow
          label="$VVS in LP / VVS Crystal Farms"
          showSkeleton={showSkeleton}
          value={hasSnapshot ? formatVvsAmount(snapshot.vvsInLP) : '0'}
        />
        <TonicAirdropCardDataRow
          label="Total VVS"
          showSkeleton={showSkeleton}
          value={hasSnapshot ? formatVvsAmount(snapshot.vvsTotal) : '0'}
        />
      </TonicPageCardSection>
      <TonicPageCardSection className="space-y-2" borderBottom>
        <TonicAirdropCardDataRow
          label="Circulating VVS supply during snapshot"
          showSkeleton={showSkeleton}
          value={formatVvsAmount(VVS_CIRCULATING_SUPPLY)}
        />
        <TonicAirdropCardDataRow
          label="Your share of the pool"
          showSkeleton={showSkeleton}
          value={
            hasSnapshot
              ? formatPercent(
                  formatRateToPercent(
                    getShareOfVvsPoolRate(
                      snapshot.vvsTotal,
                      VVS_CIRCULATING_SUPPLY
                    )
                  ),
                  5
                )
              : formatPercent(0, 5)
          }
        />
      </TonicPageCardSection>
      <TonicPageCardSection>
        <div className="mb-1 flex flex-row items-center">
          {tonicAsset && (
            <SupportedAssetIcon asset={tonicAsset} className="mr-1" />
          )}
          <Text>Total TONIC available to claim</Text>
        </div>
        {showSkeleton ? (
          <Skeleton />
        ) : (
          <Text variant="large">
            {hasSnapshot && tonicAsset
              ? formatTonicAirdropClaimAmount(tonicAsset, snapshot.tonicToClaim)
              : '0'}
          </Text>
        )}
        <Button
          className="mt-1.5 w-full mobile:py-1 mobile:px-2 mobile:text-small"
          disabled={hasClaimedAirdrop || hasNoSnapshot}
          onClick={(): void => {
            if (hasSnapshot) {
              onClaimTonic(snapshot.tonicToClaim.toString());
            }
          }}
        >
          {getButtonLabel()}
        </Button>
        <div className="mt-2 flex flex-row">
          <Icon.Info className="mr-1.5 mt-0.5 h-2 w-2 flex-shrink-0 text-yellowPrimary" />
          <Text variant="small">
            All amounts were captured on the snapshot date, 22 January 11:51am
            UTC, Cronos block #1139888
          </Text>
        </div>
      </TonicPageCardSection>
    </Card>
  );
}

export default TonicAirdropCard;
