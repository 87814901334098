import { useEffect, useReducer } from 'react';
import { BigNumber } from 'ethers';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';

import { notify } from '@lib/bugsnag';
import { PoolType } from '@config/base';
import useWallets from '@hooks/useWallets';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';

interface UserAssetBorrowAmountState {
  amount: BigNumber | null;
  asset: TectonicAsset | null;
  hasError: boolean;
  loading: boolean;
}

const initState: UserAssetBorrowAmountState = {
  amount: null,
  asset: null,
  hasError: false,
  loading: false,
};

const GET_FULFILLED = 'GET_FULFILLED';

const GET_PENDING = 'GET_PENDING';

const GET_REJECTED = 'GET_REJECTED';

const RESET = 'RESET';

interface GetPendingAction {
  type: typeof GET_PENDING;
}

interface GetFulfilledAction {
  amount: BigNumber;
  asset: TectonicAsset;
  type: typeof GET_FULFILLED;
}

interface GetRejectedAction {
  type: typeof GET_REJECTED;
}

interface ResetAction {
  type: typeof RESET;
}

type UserAssetBorrowAmountAction =
  | GetFulfilledAction
  | GetPendingAction
  | GetRejectedAction
  | ResetAction;

function reducer(
  state: UserAssetBorrowAmountState,
  action: UserAssetBorrowAmountAction
): UserAssetBorrowAmountState {
  switch (action.type) {
    case GET_PENDING:
      return { ...state, loading: true };
    case GET_FULFILLED:
      return {
        ...state,
        amount: action.amount,
        asset: action.asset,
        loading: false,
      };
    case GET_REJECTED:
      return { ...state, loading: false, hasError: true };
    case RESET:
      return { ...state, ...initState };
    default:
      return { ...state };
  }
}

export type UseUserAssetBorrowAmountResult = UserAssetBorrowAmountState;

interface UseUserAssetBorrowAmountOptions {
  skip?: boolean;
  mode?: PoolType;
}

function useUserAssetBorrowAmount(
  asset: TectonicAsset | null,
  poolType: PoolType,
  options?: UseUserAssetBorrowAmountOptions
): UseUserAssetBorrowAmountResult {
  const skip = options?.skip || false;
  const { currentAccount: walletAddress } = useWallets();
  const { sdk } = useSdkAndSupportedAssets(poolType);

  const [data, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    async function getBorrowAmount(): Promise<void> {
      try {
        if (asset && sdk && walletAddress) {
          dispatch({ type: GET_PENDING });

          const amount = await sdk.Borrow.borrowedAmountOfUser(
            asset.tTokenAddress,
            walletAddress
          );
          dispatch({ asset, amount, type: GET_FULFILLED });
        }
      } catch (error) {
        notify(error);
        dispatch({ type: GET_REJECTED });
      }
    }

    if (asset && walletAddress && !skip) {
      getBorrowAmount();
    } else {
      dispatch({ type: RESET });
    }
  }, [asset, sdk, walletAddress, skip]);

  return data;
}

export default useUserAssetBorrowAmount;
