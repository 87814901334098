import { parseUnits } from '@ethersproject/units';
import { BigNumber, utils } from 'ethers';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';
import numbro from 'numbro';

import { isNativeToken } from '@lib/utils';
import {
  NORMALIZED_USD_PRICE_DECIMALS,
  TOKEN_DISPLAY_DECIMALS,
  TONIC_DECIMALS,
  TONIC_SUPPLY_AMOUNT_DECIMALS,
  X_TONIC_DECIMALS,
} from '@config/constants';

// sdk.Oracle.tokenPriceInUsd returns price in BigNumber.
// The BigNumber returned is the price of the minimum unit of the token scaled up to 36 decimals.
// For CRO, the native token, the price is baseCRO (1 / 1e18) (CRO is 18 decimals) scaled up to 36 decimals, which is effectively the price of 1 CRO scaled up 18 decimals.
// For non-native tokens, the price is their minimum unit scaled up to 36 decimals, so we can get the price of 1 non-native token in USD by parsing 36 - underlying decimals.
export function getUsdPriceDecimals(asset: TectonicAsset): number {
  if (isNativeToken(asset)) {
    return 18;
  }
  return 36 - asset.decimals;
}

export function parseInputAmountToBN(
  amount: string,
  decimals: number
): BigNumber {
  return parseUnits(amount, decimals);
}

export function formatInputAmountToString(amount: string, decimals: number) {
  return parseInputAmountToBN(amount, decimals).toString();
}

export function formatUnits(amount: BigNumber, decimals: number) {
  return utils.formatUnits(amount, decimals);
}

export function formatNumber(amount: number): string {
  try {
    // Do not abbreviate amounts if the amount is below 100k
    if (amount < 100_000) {
      return numbro(amount).format({
        mantissa: 2,
        thousandSeparated: true,
      });
    }

    return numbro(amount).format({
      average: true,
      mantissa: 2,
      thousandSeparated: true,
    });
  } catch (error) {
    console.error(error);
    return '0.00';
  }
}

export function formatPercent(
  percentRateInt: number | null,
  mantissa = 2,
  thousandSeparated = true
): string {
  if (!percentRateInt) {
    return numbro('0').format({
      output: 'percent',
      mantissa,
      thousandSeparated,
    });
  }

  const percent = percentRateInt / 100;
  return numbro(percent).format({
    output: 'percent',
    mantissa,
    thousandSeparated,
  });
}

export function formatTotalTonicAmount(amount: BigNumber): string {
  try {
    const converted = utils.formatUnits(amount, TONIC_DECIMALS);

    // Do not abbreviate amounts if the amount is below 100k
    if (utils.parseUnits('100000', TONIC_DECIMALS).gt(amount)) {
      return numbro(converted).format({
        mantissa: 2,
        trimMantissa: true,
        thousandSeparated: true,
      });
    }

    return numbro(converted).format({
      average: true,
      mantissa: 2,
      trimMantissa: true,
      thousandSeparated: true,
    });
  } catch (error) {
    return '0.00';
  }
}

export function formatTotalTTokenAmount(
  asset: TectonicAsset,
  amount: BigNumber
): string {
  try {
    const converted = utils.formatUnits(amount, asset.tTokenDecimals);

    // Do not abbreviate amounts if the amount is below 10m
    if (utils.parseUnits('10000000', asset.tTokenDecimals).gt(amount)) {
      return numbro(converted).format({
        mantissa: 2,
        trimMantissa: true,
        thousandSeparated: true,
      });
    }

    return numbro(converted).format({
      average: true,
      mantissa: 2,
      trimMantissa: true,
      thousandSeparated: true,
    });
  } catch (error) {
    return '0.00';
  }
}

export function formatTotalUnderlyingAmount(
  asset: TectonicAsset,
  amount: BigNumber
): string {
  try {
    const converted = utils.formatUnits(amount, asset.decimals);

    // Do not abbreviate amounts if the amount is below 100k
    if (utils.parseUnits('100000', asset.decimals).gt(amount)) {
      return numbro(converted).format({
        mantissa: 2,
        thousandSeparated: true,
      });
    }

    return numbro(converted).format({
      average: true,
      mantissa: 2,
      thousandSeparated: true,
    });
  } catch (error) {
    console.error(error);
    return '0.00';
  }
}

export function formatTotalUnderlyingUsdValue(
  asset: TectonicAsset,
  usdValue: BigNumber
): string {
  try {
    const usdPriceDecimals = getUsdPriceDecimals(asset);
    const converted = utils.formatUnits(usdValue, usdPriceDecimals);

    // Do not abbreviate USD values below USD 100k
    if (utils.parseUnits('100000', usdPriceDecimals).gt(usdValue)) {
      return `$${numbro(converted).format({
        mantissa: 2,
        thousandSeparated: true,
      })}`;
    }

    return `$${numbro(converted).format({
      average: true,
      mantissa: 2,
      thousandSeparated: true,
    })}`;
  } catch (error) {
    return '$0.00';
  }
}

// Since this is the sum of USD Value of all assets supplied / borrowed,
// it expects a usdValue scaled up to 18 decimals.
export function formatMarketsSummaryUsdValue(usdValue: BigNumber): string {
  try {
    const converted = utils.formatUnits(
      usdValue,
      NORMALIZED_USD_PRICE_DECIMALS
    );

    return `$${numbro(converted).format({
      mantissa: 2,
      thousandSeparated: true,
    })}`;
  } catch (error) {
    return '$0.00';
  }
}

export function formatUserUnderlyingAmountWithDust(
  asset: Pick<TectonicAsset, 'symbol' | 'decimals'>,
  amount: BigNumber
): string {
  const result = formatUserUnderlyingAmount(asset, amount);
  if (result === '0' && amount.gt(0)) return `~${result}`;
  return result;
}

export function formatUserUnderlyingAmount(
  asset: Pick<TectonicAsset, 'symbol' | 'decimals'>,
  amount: BigNumber
): string {
  return formatTokenAmount(amount, asset.decimals, asset.symbol);
}

export function formatUserTTokenAmount(
  asset: Pick<TectonicAsset, 'symbol' | 'tTokenDecimals'>,
  amount: BigNumber
): string {
  return formatTokenAmount(amount, asset.tTokenDecimals, asset.symbol);
}

function formatTokenAmount(
  amount: BigNumber,
  decimals: number,
  symbol: string
) {
  try {
    const tokenDisplayDecimal = TOKEN_DISPLAY_DECIMALS[symbol] ?? 3;
    const converted = utils.formatUnits(amount, decimals);
    const value = converted.split('.');

    if (value[1] === '0') {
      return numbro(converted).format({
        thousandSeparated: true,
      });
    }

    // round down the decimal value
    const trimDecimalValue = value[1].substring(0, tokenDisplayDecimal);

    const finalValue = value[0] + '.' + trimDecimalValue;

    return numbro(finalValue).format({
      // set mantissa to 2 if the decimal value is 0
      mantissa: Number(trimDecimalValue) === 0 ? 2 : tokenDisplayDecimal,
      thousandSeparated: true,
    });
  } catch (error) {
    return '0.00';
  }
}

export function formatUserUnderlyingAmountWithAbbreviation(
  asset: TectonicAsset,
  amount: BigNumber
): string {
  try {
    const converted = utils.formatUnits(amount, asset.decimals);

    // Do not abbreviate amounts if the amount is below 100k
    if (utils.parseUnits('100000', asset.decimals).gt(amount)) {
      return numbro(converted).format({
        mantissa: 2,
        thousandSeparated: true,
      });
    }

    return numbro(converted).format({
      average: true,
      mantissa: 2,
      thousandSeparated: true,
    });
  } catch (error) {
    return '0.00';
  }
}

export function formatUserUnderlyingUsdValue(
  asset: TectonicAsset,
  usdValue: BigNumber
): string {
  try {
    const usdPriceDecimals = getUsdPriceDecimals(asset);
    const converted = utils.formatUnits(usdValue, usdPriceDecimals);

    // Do not abbreviate USD values below USD 100k
    if (utils.parseUnits('100000', usdPriceDecimals).gt(usdValue)) {
      return `$${numbro(converted).format({
        mantissa: 2,
        thousandSeparated: true,
      })}`;
    }

    return `$${numbro(converted).format({
      average: true,
      mantissa: 2,
      thousandSeparated: true,
    })}`;
  } catch (error) {
    return '$0.00';
  }
}

// This expects usdValue to have been normalized to 18 decimals
export function formatUserTotalUsdValue(usdValue: BigNumber): string {
  try {
    const converted = utils.formatUnits(
      usdValue,
      NORMALIZED_USD_PRICE_DECIMALS
    );

    // Do not abbreviate USD values below USD 100k
    if (
      utils.parseUnits('100000', NORMALIZED_USD_PRICE_DECIMALS).gt(usdValue)
    ) {
      return `$${numbro(converted).format({
        mantissa: 2,
        thousandSeparated: true,
      })}`;
    }

    return `$${numbro(converted).format({
      average: true,
      mantissa: 2,
      thousandSeparated: true,
    })}`;
  } catch (error) {
    return '$0.00';
  }
}

// Formats a rate (ex. 0.1 which would be 10%) to standard percent format (ex. 10 would be 10%)
export function formatRateToPercent(rate: number): number {
  return rate * 100;
}

export function formatAssetUsdPrice(
  asset: TectonicAsset,
  usdValue: BigNumber
): string {
  try {
    const usdPriceDecimals = getUsdPriceDecimals(asset);
    const converted = utils.formatUnits(usdValue, usdPriceDecimals);

    return `$${numbro(converted).format({
      mantissa: asset.symbol === 'TONIC' ? 12 : 2,
      thousandSeparated: true,
      trimMantissa: true,
    })}`;
  } catch (error) {
    return '$0.00';
  }
}

export function formatTonicCirculatingSupply(amount: BigNumber): string {
  try {
    const converted = utils.formatUnits(amount, TONIC_SUPPLY_AMOUNT_DECIMALS);

    return numbro(converted).format({
      average: false,
      mantissa: 0,
      thousandSeparated: true,
    });
  } catch (error) {
    return '0.00';
  }
}

export function formatTonicMarketCap(
  tonic: TectonicAsset,
  usdValue: BigNumber
): string {
  try {
    const converted = utils.formatUnits(usdValue, tonic.decimals);

    return `$${numbro(converted).format({
      mantissa: 0,
      thousandSeparated: true,
      trimMantissa: true,
    })}`;
  } catch (error) {
    return '$0';
  }
}

export function formatTonicAirdropClaimAmount(
  tonic: TectonicAsset,
  amount: BigNumber
): string {
  try {
    const converted = utils.formatUnits(amount, tonic.decimals);

    return numbro(converted).format({
      average: false,
      mantissa: 2,
      thousandSeparated: true,
      trimMantissa: true,
    });
  } catch (error) {
    return '0';
  }
}

export function formatXTonicToTonicExchangeRate(
  tonicEquivalent: BigNumber
): string {
  return numbro(utils.formatUnits(tonicEquivalent, TONIC_DECIMALS)).format({
    mantissa: 4,
    trimMantissa: true,
  });
}
export function formatXTonic(xTonicAmount: BigNumber) {
  return formatUserUnderlyingAmount(
    { symbol: 'TONIC', decimals: X_TONIC_DECIMALS },
    xTonicAmount
  );
}
export function formatTonic(tonicAmount: BigNumber) {
  return formatUserUnderlyingAmount(
    { symbol: 'TONIC', decimals: X_TONIC_DECIMALS },
    tonicAmount
  );
}

export function formatMantissa(
  num: string | number,
  mantissa = 2,
  trimMantissa = true
) {
  try {
    return numbro(num).format({
      mantissa,
      trimMantissa,
    });
  } catch (error) {
    return '';
  }
}

export function formatAmountUsdValue(
  usdValue: BigNumber,
  asset: TectonicAsset
): string {
  const usdPriceDecimals = getUsdPriceDecimals(asset);
  const converted = formatUnits(usdValue, usdPriceDecimals);

  return converted;
}
