import { useContext } from 'react';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';

import { PoolType } from '@config/base';
import { getSupportedAssetsContext } from '@providers/SupportedAssetsProvider/SupportedAssetsProvider';

function useSupportedAsset(
  symbol: string,
  poolType?: PoolType
): TectonicAsset | null {
  const context = getSupportedAssetsContext(poolType);

  const { map } = useContext(context);
  return map[symbol.toUpperCase()] || null;
}

export default useSupportedAsset;
