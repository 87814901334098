import { BigNumber, BigNumberish, FixedNumber, logger, utils } from 'ethers';
import { TectonicConst } from '@cronos-labs/tectonic-sdk/dist/constant';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';
import BN from 'bn.js';
import { parseUnits } from '@ethersproject/units';
import numbro from 'numbro';

import {
  CRO_GAS_BUFFER,
  INTEREST_RATE_DISPLAY_DECIMALS,
  INTEREST_RATE_SCALED_DECIMALS,
  LIQUIDITY_CUSHION,
  NORMALIZED_USD_PRICE_DECIMALS,
  TONIC_DECIMALS,
  TONIC_SUPPLY_AMOUNT_DECIMALS,
  X_TONIC_TO_TONIC_EXCHANGE_RATE_DECIMALS,
} from '@config/constants';
import { isNativeToken } from '@lib/utils';
import { PoolType } from '@config/base';
import { TOKEN_INTEREST_INFOS } from '@config/TokenInterestInfo';
import { parseInputAmountToBN, formatAmountUsdValue } from '@lib/units';

// SDK returns USD Prices as BigNumber.
// The BigNumber returned is the price of the minimum unit of the token scaled up to 36 decimals.
// In order to sum up USD value across assets easily,
// we divide the product of price * asset amount by asset mantissa
// (USDC would be 1000000 - 6 decimals, and Ether would be 1x10^18 - 18 decimals)
// which will yield us USD Value of the amount scaled up to the number of decimals that the USD Price is scaled up.
function getUsdValue(
  decimals: number,
  amount: BigNumber,
  priceInUsd: BigNumber
): BigNumber {
  try {
    return priceInUsd.mul(amount).div(BigNumber.from(10).pow(decimals));
  } catch (error) {
    return BigNumber.from(0);
  }
}

export function getUnderlyingUsdValue(
  asset: TectonicAsset,
  underlyingAmount: BigNumber,
  underlyingAssetPriceInUsd: BigNumber
): BigNumber {
  return getUsdValue(
    asset.decimals,
    underlyingAmount,
    underlyingAssetPriceInUsd
  );
}

// Returns number in rate (0.5 would be 50%) - not SDK percent format (50 would be 50%)
export function getUtilizationRate(
  borrowAmount: BigNumber,
  supplyAmount: BigNumber
): number {
  if (supplyAmount.isZero()) {
    return 0;
  }

  return parseFloat(
    utils.formatUnits(
      // Scale up `borrowAmount` by 18 decimals to prevent being zeroed out when
      // divided by `supplyAmount`.
      borrowAmount.mul(BigNumber.from(10).pow(18)).div(supplyAmount),
      18
    )
  );
}

// Returns number in rate (0.5 would be 50%) - not SDK percent format (50 would be 50%)
export function getLoanToValueRate(
  borrowBalance: BigNumber,
  collateralBalance: BigNumber
): number {
  return safeDivide(borrowBalance, collateralBalance);
}

// Returns number in rate (0.5 would be 50%) - not SDK percent format (50 would be 50%)
export function getLiquidationLtvRate(
  borrowLimit: BigNumber,
  collateralBalance: BigNumber
): number {
  return safeDivide(borrowLimit, collateralBalance);
}

export function getSupplyDailyEarningsAmount(
  supplyRatePerBlock: BigNumber,
  supplyAmount: BigNumber
): BigNumber {
  return supplyRatePerBlock
    .mul(TectonicConst.BLOCKS_PER_DAY)
    .mul(supplyAmount)
    .div(TectonicConst.ETH_MANTISSA);
}

export function getSupplyDailyEarningsRate(
  asset: TectonicAsset,
  dailyEarningsAmount: BigNumber,
  supplyAmount: BigNumber,
  usdPrice: BigNumber
): number {
  if (supplyAmount.isZero()) {
    return 0;
  }

  const dailyEarningsUsdValue = getUnderlyingUsdValue(
    asset,
    dailyEarningsAmount,
    usdPrice
  );
  const supplyAmountUsdValue = getUnderlyingUsdValue(
    asset,
    supplyAmount,
    usdPrice
  );

  return parseFloat(
    utils.formatUnits(
      dailyEarningsUsdValue
        .mul(BigNumber.from(10).pow(18))
        .div(supplyAmountUsdValue),
      18
    )
  );
}

export function getBorrowDailyInterestAmount(
  borrowRatePerBlock: BigNumber,
  borrowAmount: BigNumber
): BigNumber {
  return borrowRatePerBlock
    .mul(TectonicConst.BLOCKS_PER_DAY)
    .mul(borrowAmount)
    .div(TectonicConst.ETH_MANTISSA);
}

export function getBorrowDailyInterestRate(
  asset: TectonicAsset,
  dailyInterestAmount: BigNumber,
  borrowAmount: BigNumber,
  usdPrice: BigNumber
): number {
  if (borrowAmount.isZero()) {
    return 0;
  }

  const dailyInterestUsdValue = getUnderlyingUsdValue(
    asset,
    dailyInterestAmount,
    usdPrice
  );
  const borrowAmountUsdValue = getUnderlyingUsdValue(
    asset,
    borrowAmount,
    usdPrice
  );

  return parseFloat(
    utils.formatUnits(
      dailyInterestUsdValue
        .mul(BigNumber.from(10).pow(18))
        .div(borrowAmountUsdValue),
      18
    )
  );
}

// Get the number of tTokens per asset - effectively the exchange rate
// The formula should be 1 / ( exchangeRate * Math.Pow(10, TToken.decimals) / Math.Pow(10, erc20.decimals) /  TectonicConst.ETH_MANTISSA)
export function getTTokenAmountEquivalent(
  asset: TectonicAsset,
  exchangeRate: BigNumber
): number {
  return parseFloat(
    utils.formatUnits(
      BigNumber.from(1)
        // Scale up
        .mul(BigNumber.from(10).pow(18))
        .mul(TectonicConst.ETH_MANTISSA)
        .mul(BigNumber.from(10).pow(asset.decimals))
        .div(exchangeRate)
        .div(BigNumber.from(10).pow(asset.tTokenDecimals)),
      18
    )
  );
}

export function getMarketLiquidity(
  marketUnderlyingSuppliedAmount: BigNumber,
  marketUnderlyingBorrowedAmount: BigNumber
): BigNumber {
  return marketUnderlyingSuppliedAmount.sub(marketUnderlyingBorrowedAmount);
}

// Since the usd price of each asset returned from Oracle is scaled up to varying decimal places,
// we need a function to normalize the prices to the same scale when we do math between prices
// of different assets.
// This util function will normalize the usd price of an asset from Oracle to 18 decimals.
export function getNormalizedUsdPrice(
  asset: TectonicAsset,
  rawUsdPrice: BigNumber
): BigNumber {
  // Native Token (CRO) price is already at 18 decimals
  if (isNativeToken(asset)) {
    return rawUsdPrice;
  }

  return rawUsdPrice
    .mul(BigNumber.from(10).pow(asset.decimals))
    .mul(BigNumber.from(10).pow(NORMALIZED_USD_PRICE_DECIMALS))
    .div(BigNumber.from(10).pow(36));
}

// supplyRatePerBlock / ETH_MANTISSA  * BLOCKS_PER_DAY  * totalSuppliedUnderlyingAmountInMarket(need to handle the ERC20 decimals) * Token Price
export function getInterestPaidPerDay(
  asset: TectonicAsset,
  // This is scaled to 18 decimals
  supplyRatePerBlock: BigNumber,
  suppliedUnderlyingAmount: BigNumber,
  tokenPriceInUsd: BigNumber
): BigNumber {
  return supplyRatePerBlock
    .mul(BigNumber.from(TectonicConst.BLOCKS_PER_DAY))
    .mul(suppliedUnderlyingAmount)
    .mul(tokenPriceInUsd)
    .div(BigNumber.from(TectonicConst.ETH_MANTISSA))
    .div(BigNumber.from(10).pow(asset.decimals));
}

export function getBorrowLimit(
  supplyUsdValue: BigNumber,
  collateralFactor: number,
  isEnabledAsCollateral: boolean
): BigNumber {
  if (!isEnabledAsCollateral) {
    return BigNumber.from(0);
  }

  const collateralFactorBn = BigNumber.from(
    (collateralFactor * 100).toFixed(0)
  );
  const result = supplyUsdValue
    .mul(collateralFactorBn)
    .div(BigNumber.from(10000));

  return result;
}

export function getPostWithdrawBorrowLimit(
  asset: TectonicAsset,
  currentBorrowLimit: BigNumber,
  withdrawInputAmount: string,
  usdPrice: BigNumber,
  collateralFactor: number,
  isEnabledAsCollateral: boolean
): BigNumber | null {
  if (!withdrawInputAmount) {
    return null;
  }

  try {
    const withdrawAmount = parseInputAmountToBN(
      withdrawInputAmount,
      asset.decimals
    );

    if (withdrawAmount.isZero()) {
      return null;
    }

    const withdrawUsdValue = getUnderlyingUsdValue(
      asset,
      withdrawAmount,
      usdPrice
    );
    const withdrawAmountBorrowLimit = getNormalizedUsdPrice(
      asset,
      getBorrowLimit(withdrawUsdValue, collateralFactor, isEnabledAsCollateral)
    );
    const newBorrowLimit = currentBorrowLimit.sub(withdrawAmountBorrowLimit);

    // Don't show anything less than 0
    if (newBorrowLimit.isNegative()) {
      return BigNumber.from(0);
    }

    return newBorrowLimit;
  } catch (error) {
    return null;
  }
}

export function getPostSupplyBorrowLimit(
  asset: TectonicAsset,
  currentBorrowLimit: BigNumber,
  supplyInputAmount: string,
  usdPrice: BigNumber,
  collateralFactor: number,
  isEnabledAsCollateral: boolean
): BigNumber | null {
  if (!supplyInputAmount) {
    return null;
  }

  try {
    const supplyAmount = parseInputAmountToBN(
      supplyInputAmount,
      asset.decimals
    );

    if (supplyAmount.isZero()) {
      return null;
    }

    const supplyUsdValue = getUnderlyingUsdValue(asset, supplyAmount, usdPrice);
    const supplyAmountBorrowLimit = getNormalizedUsdPrice(
      asset,
      getBorrowLimit(supplyUsdValue, collateralFactor, isEnabledAsCollateral)
    );
    const newBorrowLimit = currentBorrowLimit.add(supplyAmountBorrowLimit);

    // Don't show anything less than 0
    if (newBorrowLimit.isNegative()) {
      return BigNumber.from(0);
    }

    return newBorrowLimit;
  } catch (error) {
    return null;
  }
}

export function getPostEnableAsCollateralBorrowLimit(
  asset: TectonicAsset,
  currentBorrowLimit: BigNumber,
  supplyAmount: BigNumber,
  usdPrice: BigNumber,
  collateralFactor: number
): BigNumber {
  if (supplyAmount.isZero()) {
    return currentBorrowLimit;
  }

  const supplyUsdValue = getUnderlyingUsdValue(asset, supplyAmount, usdPrice);
  const supplyAmountBorrowLimit = getNormalizedUsdPrice(
    asset,
    getBorrowLimit(supplyUsdValue, collateralFactor, true)
  );
  const newBorrowLimit = currentBorrowLimit.add(supplyAmountBorrowLimit);

  // Don't show anything less than 0
  if (newBorrowLimit.isNegative()) {
    return BigNumber.from(0);
  }

  return newBorrowLimit;
}

export function getPostEnableAsCollateralCollateralBalance(
  asset: TectonicAsset,
  currentCollateralBalance: BigNumber,
  assetSupplyAmount: BigNumber,
  usdPrice: BigNumber
): BigNumber {
  if (assetSupplyAmount.isZero()) {
    return currentCollateralBalance;
  }

  const assetSupplyBalance = getNormalizedUsdPrice(
    asset,
    getUnderlyingUsdValue(asset, assetSupplyAmount, usdPrice)
  );

  return currentCollateralBalance.add(assetSupplyBalance);
}

export function getPostDisableAsCollateralBorrowLimit(
  asset: TectonicAsset,
  currentBorrowLimit: BigNumber,
  supplyAmount: BigNumber,
  usdPrice: BigNumber,
  collateralFactor: number
): BigNumber {
  if (supplyAmount.isZero()) {
    return currentBorrowLimit;
  }

  const supplyUsdValue = getUnderlyingUsdValue(asset, supplyAmount, usdPrice);
  const supplyAmountBorrowLimit = getNormalizedUsdPrice(
    asset,
    getBorrowLimit(supplyUsdValue, collateralFactor, true)
  );
  const newBorrowLimit = currentBorrowLimit.sub(supplyAmountBorrowLimit);

  // Don't show anything less than 0
  if (newBorrowLimit.isNegative()) {
    return BigNumber.from(0);
  }

  return newBorrowLimit;
}

export function getPostDisableAsCollateralCollateralBalance(
  asset: TectonicAsset,
  currentCollateralBalance: BigNumber,
  assetSupplyAmount: BigNumber,
  usdPrice: BigNumber
): BigNumber {
  if (assetSupplyAmount.isZero()) {
    return currentCollateralBalance;
  }

  const assetSupplyBalance = getNormalizedUsdPrice(
    asset,
    getUnderlyingUsdValue(asset, assetSupplyAmount, usdPrice)
  );
  const newCollateralBalance = currentCollateralBalance.sub(assetSupplyBalance);

  // Don't show anything less than 0
  if (newCollateralBalance.isNegative()) {
    return BigNumber.from(0);
  }

  return newCollateralBalance;
}

interface GetTotalBorrowLimitAssetParam {
  asset: TectonicAsset;
  collateralFactor: number;
  isEnabledAsCollateral: boolean;
  supplyAmount: BigNumber;
  usdPrice: BigNumber;
}

// Returns Borrow Limit in USD scaled to NORMALIZED_USD_PRICE_DECIMALS
export function getTotalBorrowLimit(
  assetParams: GetTotalBorrowLimitAssetParam[]
): BigNumber {
  return assetParams.reduce(
    (
      previous,
      { asset, collateralFactor, supplyAmount, usdPrice, isEnabledAsCollateral }
    ) => {
      const assetSupplyUsdValue = getUnderlyingUsdValue(
        asset,
        supplyAmount,
        usdPrice
      );
      const assetBorrowLimitUsdValue = getBorrowLimit(
        assetSupplyUsdValue,
        collateralFactor,
        isEnabledAsCollateral
      );
      const normalizedUsdValue = getNormalizedUsdPrice(
        asset,
        assetBorrowLimitUsdValue
      );

      return previous.add(normalizedUsdValue);
    },
    BigNumber.from(0)
  );
}

interface GetUserBorrowBalanceAssetParam {
  asset: TectonicAsset;
  borrowAmount: BigNumber;
  usdPrice: BigNumber;
}

// Returns Borrow Balance in USD scaled to NORMALIZED_USD_PRICE_DECIMALS
export function getUserBorrowBalance(
  assetParams: GetUserBorrowBalanceAssetParam[]
): BigNumber {
  return assetParams.reduce((previous, { asset, borrowAmount, usdPrice }) => {
    const assetBorrowUsdValue = getUnderlyingUsdValue(
      asset,
      borrowAmount,
      usdPrice
    );
    const normalizedUsdValue = getNormalizedUsdPrice(
      asset,
      assetBorrowUsdValue
    );

    return previous.add(normalizedUsdValue);
  }, BigNumber.from(0));
}

export function getPostBorrowBorrowBalance(
  asset: TectonicAsset,
  currentBorrowBalance: BigNumber,
  borrowInputAmount: string,
  usdPrice: BigNumber
): BigNumber | null {
  if (!borrowInputAmount) {
    return null;
  }

  try {
    const borrowAmount = parseInputAmountToBN(
      borrowInputAmount,
      asset.decimals
    );

    if (borrowAmount.isZero()) {
      return null;
    }

    const borrowAmountUsdValue = getNormalizedUsdPrice(
      asset,
      getUnderlyingUsdValue(asset, borrowAmount, usdPrice)
    );

    return currentBorrowBalance.add(borrowAmountUsdValue);
  } catch (error) {
    return null;
  }
}

export function getPostRepayBorrowBalance(
  asset: TectonicAsset,
  currentBorrowBalance: BigNumber,
  repayInputAmount: string,
  usdPrice: BigNumber
): BigNumber | null {
  if (!repayInputAmount) {
    return null;
  }

  try {
    const repayAmount = parseInputAmountToBN(repayInputAmount, asset.decimals);

    if (repayAmount.isZero()) {
      return null;
    }

    const repayAmountUsdValue = getNormalizedUsdPrice(
      asset,
      getUnderlyingUsdValue(asset, repayAmount, usdPrice)
    );
    const newBorrowBalance = currentBorrowBalance.sub(repayAmountUsdValue);

    // Do not show anything less than 0
    if (newBorrowBalance.isNegative()) {
      return BigNumber.from(0);
    }

    return newBorrowBalance;
  } catch (error) {
    return null;
  }
}

interface GetUserSupplyBalanceAssetParam {
  asset: TectonicAsset;
  supplyAmount: BigNumber;
  usdPrice: BigNumber;
}

// Returns Supply Balance in USD scaled to NORMALIZED_USD_PRICE_DECIMALS
export function getUserSupplyBalance(
  assetParams: GetUserSupplyBalanceAssetParam[]
): BigNumber {
  return assetParams.reduce((previous, { asset, supplyAmount, usdPrice }) => {
    const assetSupplyUsdValue = getUnderlyingUsdValue(
      asset,
      supplyAmount,
      usdPrice
    );
    const normalizedUsdValue = getNormalizedUsdPrice(
      asset,
      assetSupplyUsdValue
    );

    return previous.add(normalizedUsdValue);
  }, BigNumber.from(0));
}

// Returns Supply Balance and collateral balance in USD scaled to NORMALIZED_USD_PRICE_DECIMALS
export function getUserSupplyBalanceAndCollateralBalance(
  assetParams: GetUserSupplyBalanceAssetParam[],
  isCollateral: {
    [key: string]: boolean;
  }
): { supplyBalance: BigNumber; collateralBalance: BigNumber } {
  return assetParams.reduce(
    (previous, { asset, supplyAmount, usdPrice }) => {
      const assetSupplyUsdValue = getUnderlyingUsdValue(
        asset,
        supplyAmount,
        usdPrice
      );
      const normalizedUsdValue = getNormalizedUsdPrice(
        asset,
        assetSupplyUsdValue
      );

      return {
        supplyBalance: previous.supplyBalance.add(normalizedUsdValue),
        collateralBalance: isCollateral[asset.symbol]
          ? previous.collateralBalance.add(normalizedUsdValue)
          : previous.collateralBalance,
      };
    },
    { supplyBalance: BigNumber.from(0), collateralBalance: BigNumber.from(0) }
  );
}

// Returns new Supply Balance in USD scaled to NORMALIZED_USD_PRICE_DECIMALS
export function getUserPostSupplySupplyBalance(
  asset: TectonicAsset,
  currentSupplyBalance: BigNumber,
  supplyInputAmount: string,
  usdPrice: BigNumber
): BigNumber | null {
  if (!supplyInputAmount) {
    return null;
  }

  try {
    const supplyAmount = parseInputAmountToBN(
      supplyInputAmount,
      asset.decimals
    );

    if (supplyAmount.isZero()) {
      return null;
    }

    const supplyAmountUsdValue = getNormalizedUsdPrice(
      asset,
      getUnderlyingUsdValue(asset, supplyAmount, usdPrice)
    );

    return currentSupplyBalance.add(supplyAmountUsdValue);
  } catch (error) {
    return null;
  }
}

export function getUserPostWithdrawSupplyBalance(
  asset: TectonicAsset,
  currentSupplyBalance: BigNumber,
  withdrawInputAmount: string,
  usdPrice: BigNumber
): BigNumber | null {
  if (!withdrawInputAmount) {
    return null;
  }

  try {
    const withdrawAmount = parseInputAmountToBN(
      withdrawInputAmount,
      asset.decimals
    );

    if (withdrawAmount.isZero()) {
      return null;
    }

    const withdrawAmountUsdValue = getNormalizedUsdPrice(
      asset,
      getUnderlyingUsdValue(asset, withdrawAmount, usdPrice)
    );

    const newSupplyBalance = currentSupplyBalance.sub(withdrawAmountUsdValue);

    if (newSupplyBalance.isNegative()) {
      return BigNumber.from(0);
    }

    return newSupplyBalance;
  } catch (error) {
    return null;
  }
}

export function getAvailableBorrow(
  borrowLimit: BigNumber,
  borrowedBalance: BigNumber
): BigNumber {
  return borrowLimit
    .mul(utils.parseUnits(LIQUIDITY_CUSHION.toString(), 18))
    .div(BigNumber.from(10).pow(18))
    .sub(borrowedBalance);
}

export function getUnderlyingAmount(
  asset: TectonicAsset,
  assetPrice: BigNumber,
  normalizedUsdValue: BigNumber
) {
  return normalizedUsdValue
    .mul(BigNumber.from(10).pow(asset.decimals))
    .div(getNormalizedUsdPrice(asset, assetPrice));
}

export function get90PercentOfUsdValue(value: BigNumber) {
  return value.mul(BigNumber.from(9000)).div(BigNumber.from(10000));
}

// To ensure enough CRO for gas, subtract 2 when txn is in CRO (native)
// If value is negative after subtraction, return 0 and do not allow for txn
export function subtractGasBuffer(
  value: BigNumber,
  asset: TectonicAsset,
  buffer = CRO_GAS_BUFFER
): BigNumber {
  const valueWithGasBuffer = value.sub(
    utils.parseUnits(buffer, asset.decimals)
  );
  if (valueWithGasBuffer.isNegative()) {
    return BigNumber.from(0);
  }
  return valueWithGasBuffer;
}

// all values are in normalized usd value with NORMALIZED_USD_PRICE_DECIMALS
// to consume, use utils.formatUnits([userMaxWithdrawalLimit], asset.decimals)

interface GetUserMaxWithdrawAmountParams {
  asset: TectonicAsset;
  assetPrice: BigNumber;
  collateralFactor: number;
  currentSupplyAmount: BigNumber;
  borrowBalance: BigNumber;
  borrowLimit: BigNumber;
  isCollateral: boolean;
}

export function getUserMaxWithdrawAmount({
  asset,
  assetPrice,
  collateralFactor,
  currentSupplyAmount,
  borrowBalance,
  borrowLimit,
  isCollateral,
}: GetUserMaxWithdrawAmountParams) {
  /*
    TODO: Note here, we may need to apply a small margin (e.g. 0.1%) to the withdraw limit
    in case the borrow interest accrues faster than supply interest
    or price fluctuates for non-stable coins, there will be some precision loss
    so the max withdraw amount will always fail in these cases
  */

  if (currentSupplyAmount?.isZero()) return BigNumber.from(0);

  if (!isCollateral || borrowBalance.isZero()) return currentSupplyAmount;

  // Get the lowest borrow limit that can we can safely withdraw to,
  // which is borrowBalance / 0.9.
  const minNewBorrowLimitUsd = borrowBalance
    .mul(BigNumber.from(10000))
    .div(BigNumber.from((LIQUIDITY_CUSHION * 10000).toFixed(0)));

  const borrowLimitDifferenceUsd = borrowLimit.sub(minNewBorrowLimitUsd);

  // Divide borrowLimitDifferenceUsd by the collateralFactor to get the difference
  // in asset usd value
  const maxWithdrawUsd = borrowLimitDifferenceUsd
    .mul(BigNumber.from(10000))
    .div(BigNumber.from((collateralFactor * 100).toFixed(0)));

  if (maxWithdrawUsd.isNegative() || maxWithdrawUsd.isZero()) {
    return BigNumber.from(0);
  }

  const currentSupplyAmountUsd = getUnderlyingUsdValue(
    asset,
    currentSupplyAmount,
    getNormalizedUsdPrice(asset, assetPrice)
  );

  // User cannot withdraw more than their supply
  if (maxWithdrawUsd.gte(currentSupplyAmountUsd)) {
    return currentSupplyAmount;
  }

  return getUnderlyingAmount(asset, assetPrice, maxWithdrawUsd);
}

// all values are in normalized usd value with NORMALIZED_USD_PRICE_DECIMALS
// to consume, use utils.formatUnits([userMaxBorrowLimit], asset.decimals)
export function getUserMaxBorrowAmount(
  asset: TectonicAsset,
  assetPrice: BigNumber,
  borrowBalance: BigNumber,
  totalBorrowLimit: BigNumber
) {
  const borrowLimitUsd =
    get90PercentOfUsdValue(totalBorrowLimit).sub(borrowBalance);

  if (borrowLimitUsd.isNegative()) {
    return BigNumber.from('0');
  }

  return getUnderlyingAmount(asset, assetPrice, borrowLimitUsd);
}

export function getAssetApyYield(
  assetUsdValue: BigNumber,
  apy: number
): BigNumber {
  const apyBn = BigNumber.from((apy * 100).toFixed(0));
  return assetUsdValue.mul(apyBn).div(BigNumber.from(10000));
}

function getAnnualTonicDistributionUsdValue(
  // Scaled up by 18 decimals
  tonicDailyDistributeRate: BigNumber,
  tonicUsdPrice: BigNumber
) {
  // Since TONIC is 18 decimals, it is already equal to NORMALIZED_USD_PRICE_DECIMALS
  return getUsdValue(
    TONIC_DECIMALS,
    tonicDailyDistributeRate,
    tonicUsdPrice
  ).mul(TectonicConst.DAYS_PER_YEAR);
}

function getAnnualNonTonicDistributionUsdValue(
  nonTonicDailyDistributeRate: BigNumber,
  nonTonicUsdPrice: BigNumber,
  nonTonicDecimal: number
) {
  return getUsdValue(
    nonTonicDecimal,
    nonTonicDailyDistributeRate,
    nonTonicUsdPrice
  ).mul(TectonicConst.DAYS_PER_YEAR);
}

export function getUserTonicRewardDistributedPerDay(
  dailyDistributionRate: BigNumber,
  userSupplyAmount: BigNumber,
  marketTotalSupplyAmount: BigNumber
): BigNumber {
  if (marketTotalSupplyAmount.isZero()) {
    return BigNumber.from(0);
  }

  return dailyDistributionRate
    .mul(userSupplyAmount)
    .div(marketTotalSupplyAmount);
}

// from ethers.BigNumber
// Normalize the hex string
function toHex(value: string | BN): string {
  // For BN, call on the hex string
  if (typeof value !== 'string') {
    return toHex(value.toString(16));
  }

  // If negative, prepend the negative sign to the normalized positive value
  if (value[0] === '-') {
    // Strip off the negative sign
    value = value.substring(1);

    // Cannot have multiple negative signs (e.g. "--0x04")
    if (value[0] === '-') {
      logger.throwArgumentError('invalid hex', 'value', value);
    }

    // Call toHex on the positive component
    value = toHex(value);

    // Do not allow "-0x00"
    if (value === '0x00') {
      return value;
    }

    // Negate the value
    return '-' + value;
  }

  // Add a "0x" prefix if missing
  if (value.substring(0, 2) !== '0x') {
    value = '0x' + value;
  }

  // Normalize zero
  if (value === '0x') {
    return '0x00';
  }

  // Make the string even length
  if (value.length % 2) {
    value = '0x0' + value.substring(2);
  }

  // Trim to smallest even-length string
  while (value.length > 4 && value.substring(0, 4) === '0x00') {
    value = '0x' + value.substring(4);
  }

  return value;
}

function toBigNumber(value: BN): BigNumber {
  return BigNumber.from(toHex(value));
}

function toBN(value: BigNumberish): BN {
  const hex = BigNumber.from(value).toHexString();
  if (hex[0] === '-') {
    return new BN('-' + hex.substring(3), 16);
  }
  return new BN(hex.substring(2), 16);
}
// from ethers.BigNumber

export function calculateDailyBorrowRate(
  utilization: BigNumber,
  baseRate: BigNumber,
  multiplier: BigNumber,
  jumpMultiplier: BigNumber,
  kink: BigNumber
): BigNumber {
  const kinkBN = toBN(kink);
  const utilizationBN = toBN(utilization);
  const baseRateUtilization = toBigNumber(BN.min(kinkBN, utilizationBN));

  const jumpUtilization = toBigNumber(
    BN.max(new BN('0'), utilizationBN.sub(kinkBN))
  );
  const normalAccumulatedInterest = multiplier
    .mul(baseRateUtilization)
    .div(kink);

  const jumpAccumulatedInterest = jumpMultiplier
    .mul(jumpUtilization)
    .div(BigNumber.from(10).pow(INTEREST_RATE_SCALED_DECIMALS));

  return baseRate
    .add(normalAccumulatedInterest)
    .add(jumpAccumulatedInterest)
    .div('365');
}

function toAPY(rate: BigNumber) {
  const one = utils.parseUnits('1', INTEREST_RATE_SCALED_DECIMALS);
  const denominator = BigNumber.from(10)
    .pow(INTEREST_RATE_SCALED_DECIMALS)
    .pow(363);
  return rate.add(one).pow(364).div(denominator).sub(one);
}

export function calculateBorrowAPY(dailyBorrowRate: BigNumber) {
  return toAPY(dailyBorrowRate);
}

export function calculateDailySupplyRate(
  dailyBorrowRate: BigNumber,
  utilization: BigNumber,
  reserveFactor: BigNumber
): BigNumber {
  const oneMinusReserveFactor = parseUnits(
    '1',
    INTEREST_RATE_SCALED_DECIMALS
  ).sub(reserveFactor);
  return dailyBorrowRate
    .mul(utilization)
    .mul(oneMinusReserveFactor)
    .div(BigNumber.from(10).pow(INTEREST_RATE_SCALED_DECIMALS))
    .div(BigNumber.from(10).pow(INTEREST_RATE_SCALED_DECIMALS));
}

export function calculateSupplyAPY(dailySupplyRate: BigNumber) {
  return toAPY(dailySupplyRate);
}

export interface InterestRate {
  utility: string;
  supplyRate: string;
  borrowRate: string;
  current?: boolean;
}

function calculateSupplyAndBorrowRate(
  utility: BigNumber,
  baseRate: BigNumber,
  multiplier: BigNumber,
  kink: BigNumber,
  jumpMultiplier: BigNumber,
  reserveFactor: BigNumber
): InterestRate {
  const dailyBorrowRate = calculateDailyBorrowRate(
    utility,
    baseRate,
    multiplier,
    jumpMultiplier,
    kink
  );
  const borrowAPY = calculateBorrowAPY(dailyBorrowRate);
  const dailySupplyRate = calculateDailySupplyRate(
    dailyBorrowRate,
    utility,
    reserveFactor
  );
  const supplyAPY = calculateSupplyAPY(dailySupplyRate);
  return {
    utility: utils.formatUnits(utility, INTEREST_RATE_DISPLAY_DECIMALS),
    supplyRate: FixedNumber.fromValue(supplyAPY, INTEREST_RATE_DISPLAY_DECIMALS)
      .round(2)
      .toString(),
    borrowRate: FixedNumber.fromValue(borrowAPY, INTEREST_RATE_DISPLAY_DECIMALS)
      .round(2)
      .toString(),
  };
}

export function getInterestRate(
  poolType: PoolType,
  token: string,
  currentBorrowAPY: number | null,
  currentSupplyAPY: number | null,
  currentUtilization?: number
): InterestRate[] | null {
  const info = TOKEN_INTEREST_INFOS[poolType][token.toUpperCase()];

  if (!info) {
    return null;
  }

  const infoInBigNumber = {} as { [key: string]: BigNumber };
  Object.entries(info).forEach(([key, val]: [key: string, val: number]) => {
    infoInBigNumber[key] = parseUnits(
      val.toString(),
      INTEREST_RATE_SCALED_DECIMALS
    );
  });
  const { baseRate, multiplier, jumpMultiplier, kink, reserveFactor } =
    infoInBigNumber;

  const list = [];

  for (let i = 0; i <= 100; i++) {
    const utilityInBigNumber = parseUnits(
      (i / 100).toString(),
      INTEREST_RATE_SCALED_DECIMALS
    );
    list.push(
      calculateSupplyAndBorrowRate(
        utilityInBigNumber,
        baseRate,
        multiplier,
        kink,
        jumpMultiplier,
        reserveFactor
      )
    );
  }

  if (currentUtilization && currentBorrowAPY && currentSupplyAPY) {
    list.push({
      utility: numbro(currentUtilization * 100).format({ mantissa: 2 }),
      supplyRate: currentSupplyAPY.toString(),
      borrowRate: currentBorrowAPY.toString(),
      current: true,
    });
  } else if (currentUtilization) {
    let stringifiedUtilization = currentUtilization
      ? currentUtilization.toString()
      : '0';

    if (stringifiedUtilization.indexOf('.') > -1) {
      stringifiedUtilization = stringifiedUtilization.substring(
        0,
        Math.min(stringifiedUtilization.length, 6)
      );
    }

    const currentUtilizationBigNumber = parseUnits(
      stringifiedUtilization,
      INTEREST_RATE_SCALED_DECIMALS
    );
    list.push({
      ...calculateSupplyAndBorrowRate(
        currentUtilizationBigNumber,
        baseRate,
        multiplier,
        kink,
        jumpMultiplier,
        reserveFactor
      ),
      current: true,
    });
  }

  list.sort((a, b) => Number(a.utility) - Number(b.utility));

  return list;
}

export function getBorrowBalanceRate(
  borrowBalance: BigNumber,
  borrowLimit: BigNumber
): number {
  if (borrowLimit.isZero()) {
    return 0;
  }

  return parseFloat(
    utils.formatUnits(
      // Scale up `borrowBalance` by 18 decimals to prevent being zeroed out
      // when divided by `borrowLimit`
      borrowBalance.mul(BigNumber.from(10).pow(18)).div(borrowLimit),
      18
    )
  );
}

interface GetUserCollateralBalanceAssetParam {
  asset: TectonicAsset;
  collateralAmount: BigNumber;
  usdPrice: BigNumber;
  isCollateral: boolean;
}

export function getUserCollateralBalance(
  assetParams: GetUserCollateralBalanceAssetParam[]
): BigNumber {
  return assetParams.reduce(
    (previous, { asset, collateralAmount, usdPrice, isCollateral }) => {
      const assetBorrowUsdValue = getUnderlyingUsdValue(
        asset,
        collateralAmount,
        usdPrice
      );
      const normalizedUsdValue = getNormalizedUsdPrice(
        asset,
        assetBorrowUsdValue
      );

      return isCollateral ? previous.add(normalizedUsdValue) : previous;
    },
    BigNumber.from(0)
  );
}

export function getUserPostSupplyCollateralBalance(
  asset: TectonicAsset,
  currentCollateralBalance: BigNumber,
  supplyInputAmount: string,
  usdPrice: BigNumber,
  isEnabledAsCollateral: boolean
): BigNumber | null {
  if (!supplyInputAmount) {
    return null;
  }

  try {
    const supplyAmount = parseInputAmountToBN(
      supplyInputAmount,
      asset.decimals
    );

    if (supplyAmount.isZero()) {
      return null;
    }

    if (!isEnabledAsCollateral) {
      return currentCollateralBalance;
    }

    const supplyAmountUsdValue = getNormalizedUsdPrice(
      asset,
      getUnderlyingUsdValue(asset, supplyAmount, usdPrice)
    );

    return currentCollateralBalance.add(supplyAmountUsdValue);
  } catch (error) {
    return null;
  }
}
export function getUserPostWithdrawCollateralBalance(
  asset: TectonicAsset,
  currentCollateralBalance: BigNumber,
  withdrawInputAmount: string,
  usdPrice: BigNumber,
  isEnabledAsCollateral: boolean
): BigNumber | null {
  if (!withdrawInputAmount) {
    return null;
  }

  try {
    const withdrawAmount = parseInputAmountToBN(
      withdrawInputAmount,
      asset.decimals
    );

    if (withdrawAmount.isZero()) {
      return null;
    }

    if (!isEnabledAsCollateral) {
      return currentCollateralBalance;
    }

    const withdrawAmountUsdValue = getNormalizedUsdPrice(
      asset,
      getUnderlyingUsdValue(asset, withdrawAmount, usdPrice)
    );

    const newCollateralBalance = currentCollateralBalance.sub(
      withdrawAmountUsdValue
    );

    if (newCollateralBalance.isNegative()) {
      return BigNumber.from(0);
    }

    return newCollateralBalance;
  } catch (error) {
    return null;
  }
}

export function getMaximumLTV(
  borrowLimit: BigNumber,
  collateralUsdValue: BigNumber
): number {
  return safeDivide(get90PercentOfUsdValue(borrowLimit), collateralUsdValue);
}

export function getLiquidationThreshold(
  borrowLimit: BigNumber,
  collateralUsdValue: BigNumber
): number {
  return safeDivide(borrowLimit, collateralUsdValue);
}

// collateralValueWhenLiquidation == Total borrow balance (USD) / liquidationThreshold(%)
// XX = (collateralUsdValue - collateralValueWhenLiquidation) / collateralUsdValue
export function getYourLiquidationRateIfCollateralDrop(
  borrowBalance: BigNumber,
  collateralUsdValue: BigNumber,
  borrowLimit: BigNumber
): number {
  if (collateralUsdValue.isZero()) {
    return 0;
  }

  const liquidationThreshold = borrowLimit
    .mul(BigNumber.from(10).pow(18))
    .div(collateralUsdValue);

  const collateralValueWhenLiquidation = borrowBalance
    .mul(BigNumber.from(10).pow(18))
    .div(BigNumber.from(liquidationThreshold));

  return safeDivide(
    collateralUsdValue.sub(collateralValueWhenLiquidation),
    collateralUsdValue
  );
}

// Distribution APY (Borrow or Supply) = ((weightAmount / total weightAmount) * tonicDailyDistributeRate * TONIC price (USD) * 365) /  (amount * asset price (USD))
export function getBoostXTonicApyRate(
  weightAmount: BigNumber,
  totalWeightAmount: BigNumber,
  tonicDailyDistributeRate: BigNumber,
  tonicUsdPrice: BigNumber,
  asset: TectonicAsset,
  amount: BigNumber,
  assetUsdPrice: BigNumber
): number {
  const calculatedWeight = totalWeightAmount.isZero()
    ? 0
    : Number(weightAmount) / Number(totalWeightAmount);

  const scaledTonicDailyDistributeRate = tonicDailyDistributeRate
    .mul(
      utils.parseUnits(calculatedWeight.toFixed(TONIC_DECIMALS), TONIC_DECIMALS)
    )
    .div(BigNumber.from(10).pow(18));

  const numerator = getAnnualTonicDistributionUsdValue(
    scaledTonicDailyDistributeRate,
    tonicUsdPrice
  );
  const denominator = getNormalizedUsdPrice(
    asset,
    getUnderlyingUsdValue(asset, amount, assetUsdPrice)
  );
  return safeDivide(numerator, denominator);
}

// Distribution APY (Borrow or Supply) = (tonicDailyDistributeRateForBorrow * TONIC price (USD) / Total amount (may be total supply or total borrow) of Asset (entire market) * asset price (USD)) * 365
export function getTonicApyRate(
  // Scaled up by 18 decimals
  tonicDailyDistributeRate: BigNumber,
  tonicUsdPrice: BigNumber,
  asset: TectonicAsset,
  assetAmount: BigNumber,
  assetUsdPrice: BigNumber
): number {
  const numerator = getAnnualTonicDistributionUsdValue(
    tonicDailyDistributeRate,
    tonicUsdPrice
  );
  const denominator = getNormalizedUsdPrice(
    asset,
    getUnderlyingUsdValue(asset, assetAmount, assetUsdPrice)
  );

  return safeDivide(numerator, denominator);
}

export function getNonTonicApyRate(
  nonTonicDailyDistributeRate: BigNumber,
  nonTonicUsdPrice: BigNumber,
  asset: TectonicAsset,
  assetBorrowAmount: BigNumber,
  assetUsdPrice: BigNumber
): number {
  const numerator = getAnnualNonTonicDistributionUsdValue(
    nonTonicDailyDistributeRate,
    nonTonicUsdPrice,
    asset.decimals
  );
  const denominator = getNormalizedUsdPrice(
    asset,
    getUnderlyingUsdValue(asset, assetBorrowAmount, assetUsdPrice)
  );

  return safeDivide(numerator, denominator);
}

// just perform NUMERATOR / DENOMINATOR and check DENOMINATOR
export function safeDivide(
  numerator: BigNumber,
  denominator: BigNumber
): number {
  if (denominator.isZero()) {
    return 0;
  }

  return parseFloat(
    utils.formatUnits(
      numerator.mul(BigNumber.from(10).pow(18)).div(denominator),
      18
    )
  );
}

export function getTonicMarketCap(
  tonicUsdPrice: BigNumber,
  circulatingSupply: BigNumber
): BigNumber {
  return tonicUsdPrice
    .mul(circulatingSupply)
    .div(BigNumber.from(10).pow(TONIC_SUPPLY_AMOUNT_DECIMALS));
}

// Returns number in rate (0.5 would be 50%) - not SDK percent format (50 would be 50%)
export function getShareOfVvsPoolRate(
  vvsAmount: BigNumber,
  vvsCirculatingSupply: BigNumber
): number {
  if (vvsCirculatingSupply.isZero()) {
    return 0;
  }

  return parseFloat(
    utils.formatUnits(
      // Scale up `vvsAmount` by 18 decimals to prevent being zeroed out when
      // divided by `vvsCirculatingSupply`.
      vvsAmount.mul(BigNumber.from(10).pow(18)).div(vvsCirculatingSupply),
      18
    )
  );
}

// xTonicToTonicExchangeRate is scaled by 18 decimals
export function convertXTonicToTonic(
  xTonicAmount: BigNumber,
  xTonicToTonicExchangeRate: BigNumber
): BigNumber {
  return xTonicAmount
    .mul(xTonicToTonicExchangeRate)
    .div(BigNumber.from(10).pow(X_TONIC_TO_TONIC_EXCHANGE_RATE_DECIMALS));
}

// xTonicToTonicExchangeRate is scaled by 18 decimals
export function convertTonicToXTonic(
  tonicAmount: BigNumber,
  xTonicToTonicExchangeRate: BigNumber
): BigNumber {
  const exchangeRate = BigNumber.from(1)
    .mul(BigNumber.from(10).pow(X_TONIC_TO_TONIC_EXCHANGE_RATE_DECIMALS * 2))
    .div(xTonicToTonicExchangeRate);

  return tonicAmount
    .mul(exchangeRate)
    .div(BigNumber.from(10).pow(X_TONIC_TO_TONIC_EXCHANGE_RATE_DECIMALS));
}

export function getSwapInPriceImpact(
  inputAmount: BigNumber,
  outputAmount: BigNumber,
  inputAsset: TectonicAsset,
  outputAsset: TectonicAsset,
  inputTokenUsdPrices: string,
  outputTokenUsdPrices: string,
  slippageTolerance: number,
  scaled = 6
) {
  const decimalsBN = BigNumber.from(10).pow(scaled);

  const priceImpact = getNormalizedUsdPrice(
    inputAsset,
    BigNumber.from(inputTokenUsdPrices)
  )
    .mul(inputAmount)
    .div(
      getNormalizedUsdPrice(outputAsset, BigNumber.from(outputTokenUsdPrices))
        .mul(outputAmount)
        .div(decimalsBN)
    );

  return Number(utils.formatEther(priceImpact)) - 1 - slippageTolerance;
}

export function getSwapOutPriceImpact(
  inputAmount: BigNumber,
  outputAmount: BigNumber,
  inputAsset: TectonicAsset,
  outputAsset: TectonicAsset,
  inputTokenUsdPrices: string,
  outputTokenUsdPrices: string,
  slippageTolerance: number
) {
  const outputAmountUsd = getUnderlyingUsdValue(
    outputAsset,
    outputAmount,
    BigNumber.from(outputTokenUsdPrices)
  );

  const inputAmountUsd = getUnderlyingUsdValue(
    inputAsset,
    inputAmount,
    BigNumber.from(inputTokenUsdPrices)
  );
  const priceImpact = !inputAmountUsd.isZero()
    ? Number(formatAmountUsdValue(outputAmountUsd, outputAsset)) /
      Number(formatAmountUsdValue(inputAmountUsd, inputAsset))
    : 0;

  return 1 - priceImpact - slippageTolerance;
}
