import { TectonicVaultStakeInfo } from '@cronos-labs/tectonic-sdk/dist/types';
import { isBefore } from 'date-fns';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';

import { LockingPeriod } from '@components/LockDepositModal/types';
import { secondsToPeriod } from '@components/LockDepositModal/periods';

import { VaultStats } from '../types';

export function getVaultStats(vaults: TectonicVaultStakeInfo[]): VaultStats {
  const aprMantissa = vaults.find((v) => v.pool.apy > 100_000_000) ? 1 : 10_000;

  const rawStats = vaults.reduce<{
    xTonicStaked: BigNumber;
    xTonicUnlocked: BigNumber;
    summedApr: BigNumber;
    allocTotal: Record<LockingPeriod, BigNumber>;
  }>(
    (prev, vault) => {
      const monthPeriod = secondsToPeriod[vault.pool.lockPeriod];
      if (!monthPeriod) {
        return prev;
      }
      const isLocked = isBefore(Date.now(), vault.lockEndDate);
      prev.xTonicStaked = prev.xTonicStaked.add(vault.amount);
      prev.allocTotal[monthPeriod] = prev.allocTotal[monthPeriod].add(
        vault.amount
      );
      if (!isLocked) {
        prev.xTonicUnlocked = prev.xTonicUnlocked.add(vault.amount);
      }
      const weightedApr = vault.amount.mul(
        Math.round((aprMantissa * vault.pool.apy) / 100)
      );
      prev.summedApr = prev.summedApr.add(weightedApr);
      return prev;
    },
    {
      xTonicStaked: BigNumber.from(0),
      xTonicUnlocked: BigNumber.from(0),
      summedApr: BigNumber.from(0),
      allocTotal: {
        '30s': BigNumber.from(0),
        '60s': BigNumber.from(0),
        '120s': BigNumber.from(0),
        '240s': BigNumber.from(0),
        '1month': BigNumber.from(0),
        '6months': BigNumber.from(0),
        '12months': BigNumber.from(0),
        '24months': BigNumber.from(0),
        '48months': BigNumber.from(0),
      },
    }
  );
  const allocations = Object.entries(rawStats.allocTotal).reduce<
    Record<LockingPeriod, number>
  >(
    (prev, [_period, amount]) => {
      const period = _period as LockingPeriod;
      prev[period] = rawStats.xTonicStaked.gt(0)
        ? parseFloat(
            formatUnits(amount.mul(100_000).div(rawStats.xTonicStaked), 5)
          )
        : 0;
      return prev;
    },
    {
      '30s': 0,
      '60s': 0,
      '120s': 0,
      '240s': 0,
      '1month': 0,
      '6months': 0,
      '12months': 0,
      '24months': 0,
      '48months': 0,
    }
  );
  return {
    allocations,
    totalXTonicStaked: rawStats.xTonicStaked,
    totalXTonicUnlocked: rawStats.xTonicUnlocked,
    avgApr: rawStats.xTonicStaked.gt(0)
      ? rawStats.summedApr.div(rawStats.xTonicStaked).toNumber() / aprMantissa
      : 0,
  };
}
