import React, { Fragment } from 'react';
import Bugsnag, { Event } from '@bugsnag/js';
import {
  TectonicSDKError,
  TectonicSDKErrorType,
} from '@cronos-labs/tectonic-sdk/dist/error';
import { serializeError } from 'eth-rpc-errors';
import { isValidCode } from 'eth-rpc-errors/dist/utils';
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
} from '@bugsnag/plugin-react';

const getIsBugsnagEnabled = () => {
  return (
    typeof window !== 'undefined' &&
    process.env.NEXT_PUBLIC_RELEASE_STAGE !== 'local' &&
    process.env.NODE_ENV !== 'test' &&
    !!process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
  );
};

type GeneralErrorMap = Record<string, true>;

const SkipGeneralErrorMap: GeneralErrorMap = {
  // https://app.bugsnag.com/foris-limited/tectonic-app/errors/61b9de398442640007c1b51d?filters[event.since]=all
  'User close QRCode Modal': true,
  // https://app.bugsnag.com/foris-limited/tectonic-app/errors/61b9f01d8c58370007075bfe?filters[event.since]=all
  'User closed modal': true,
  // https://app.bugsnag.com/foris-limited/tectonic-app/errors/61b9d9e9422e9600070204df?filters[event.since]=30d&filters[error.status]=open&event_id=61c473fa008a8c1e00000000
  'rejected request from DeFi Wallet': true,
  // https://app.bugsnag.com/foris-limited/tectonic-app/errors/61b9d9e9422e9600070204df?filters[event.since]=30d&filters[error.status]=open&event_id=61c4711c008a85adcd2d0000
  'User canceled': true,
  // https://app.bugsnag.com/foris-limited/tectonic-app/errors/61b9d9e9422e9600070204df?filters[event.since]=30d&filters[error.status]=open&event_id=61c4613a008a82f8d5f60000
  'User Cancelled': true,
  // https://app.bugsnag.com/foris-limited/tectonic-app/errors/61b9d9e9422e9600070204df?filters[event.since]=30d&filters[error.status]=open&event_id=61c42247008a9c486e160000
  'Oops! Something went wrong.': true,
  // https://app.bugsnag.com/foris-limited/tectonic-app/errors/61b9d9e9422e9600070204df?filters[event.since]=30d&filters[error.status]=open&event_id=61bff4df008a8df061ef0000
  'A transaction is being processed': true,
};

export function skipErrorBoundaryError(errors: Event['errors']): boolean {
  if (errors.length > 0) {
    const error = errors[0];

    if (
      error.errorMessage.indexOf('Unsupported chain id') > -1 ||
      SkipGeneralErrorMap[error.errorMessage]
    ) {
      return true;
    }
  }

  return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNoEthereumProviderError = (error: any): boolean => {
  return !!(
    error.name &&
    error?.message?.includes(
      'No Ethereum provider was found on window.ethereum.'
    )
  );
};

export const start = () => {
  let ErrorBoundary: BugsnagErrorBoundary = Fragment;

  if (getIsBugsnagEnabled()) {
    Bugsnag.start({
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '',
      appVersion: process.env.NEXT_PUBLIC_APP_VERSION || '',
      releaseStage: process.env.NEXT_PUBLIC_RELEASE_STAGE || '',
      plugins: [new BugsnagPluginReact()],
      onError: function (event) {
        // Discard noise / unhelpful errors
        if (skipErrorBoundaryError(event.errors)) {
          // https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#discarding-events
          return false;
        }

        event.addMetadata('SDK Transaction', {
          code: null,
          failureInfo: null,
          rootCause: null,
        });
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);
  }

  return ErrorBoundary;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isTectonicSdkError(error: any): boolean {
  if (
    (error.type && error.type === TectonicSDKErrorType.TectonicCoreError) ||
    error.type === TectonicSDKErrorType.TokenError ||
    error.type === TectonicSDKErrorType.other
  ) {
    return true;
  }

  return false;
}

function isValidEthereumRpcCode(code: number): boolean {
  // isValidCode does not handle 4902 for some reason
  // https://githubmemory.com/repo/MetaMask/metamask-mobile/issues/3035
  // https://app.bugsnag.com/foris-limited/tectonic-app/errors/61b9e8245a084b00072e3814?filters[event.since]=all&filters[error.status]=open&filters[search]=notify&event_id=61bac9d2008a85aedee40000
  return isValidCode(code) || code === 4902;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEthereumRpcError(error: any): boolean {
  if (error.code && error.message && isValidEthereumRpcCode(error.code)) {
    return true;
  }

  return false;
}

function notifyTectonicSdkError(
  error: TectonicSDKError,
  enabled: boolean
): void {
  console.error(error);
  if (enabled) {
    Bugsnag.notify(new Error(error.code), (event) => {
      event.addMetadata('Tectonic SDK Error', {
        code: error.code,
        failureInfo: error.failureInfo,
        rootCause: error.rootCause,
        extra: error,
      });
    });
    return;
  }

  console.error('SDK Error: ', {
    code: error.code,
    failureInfo: error.failureInfo,
    rootCause: error.rootCause,
  });
}

type EthereumRpcMessageCodeMap = Record<string, number>;

const SkipEthereumRpcErrorMap: EthereumRpcMessageCodeMap = {
  '4001/MetaMask Tx Signature: User denied transaction signature.': 4001,
  '4001/User rejected the request.': 4001,
  '4001/User rejected the transaction': 4001,
};

export function skipNotifyEthereumRpcError(
  error: ReturnType<typeof serializeError>
): boolean {
  return (
    SkipEthereumRpcErrorMap[`${error.code}/${error.message}`] !== undefined
  );
}

function notifyEthereumRpcError(
  error: ReturnType<typeof serializeError>,
  enabled: boolean
): void {
  if (enabled) {
    if (!skipNotifyEthereumRpcError(error)) {
      Bugsnag.notify(new Error(`${error.code} - ${error.message}`), (event) => {
        event.addMetadata('Ethereum RPC Error', {
          code: error.code,
          data: error.data || '',
          message: error.message,
          stack: error.stack || '',
        });
      });
    }
    return;
  }

  console.error('Ethereum RPC Error: ', error);
}

function notifyError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  enabled: boolean,
  optionalMessage?: string
): void {
  if (enabled) {
    Bugsnag.notify(error);
    return;
  }

  console.error(optionalMessage || '', error);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function notify(error: any, optionalMessage?: string): void {
  if (error) {
    console.error(error);
    const isBugsnagEnabled = getIsBugsnagEnabled();

    if (isTectonicSdkError(error)) {
      notifyTectonicSdkError(error as TectonicSDKError, isBugsnagEnabled);
      return;
    }

    if (isEthereumRpcError(error)) {
      notifyEthereumRpcError(serializeError(error), isBugsnagEnabled);
      return;
    }

    notifyError(error, isBugsnagEnabled, optionalMessage);
  }
}
