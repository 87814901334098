/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect, useMemo, useRef } from 'react';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';
import { BigNumber } from 'ethers';
import { TectonicSDK } from '@cronos-labs/tectonic-sdk';

import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { getNormalizedUsdPrice, getUnderlyingUsdValue } from '@lib/math';
import { useSupportedAssets } from '@providers/SupportedAssetsProvider';
import { useAssetTokenUsdPrices } from '@hooks/useAssetTokenUsdPrice';
import { PoolType } from '@config/base';

interface UseStatsResult {
  loading: boolean;
  totalBorrowed: BigNumber | null;
  totalSupplied: BigNumber | null;
}

function useStats(): UseStatsResult {
  const sdkMain = useTectonicSdk('MAIN');
  const sdkDefi = useTectonicSdk('DEFI');
  const sdkVeno = useTectonicSdk('VENO');
  const statsLoading = useRef(false);
  const [totalBorrowedMap, setTotalBorrowed] = useState<
    Record<PoolType, BigNumber | null>
  >({
    MAIN: null,
    VENO: null,
    DEFI: null,
  });
  const [totalSuppliedMap, setTotalSupplied] = useState<
    Record<PoolType, BigNumber | null>
  >({
    MAIN: null,
    VENO: null,
    DEFI: null,
  });
  const supportedAssetsMain = useSupportedAssets('MAIN').list;
  const supportedAssetsVeno = useSupportedAssets('VENO').list;
  const supportedAssetsDefi = useSupportedAssets('DEFI').list;

  const usdPriceResultsVeno = useAssetTokenUsdPrices(
    'VENO',
    supportedAssetsVeno.map((asset) => asset.tTokenAddress)
  );

  const usdPriceResultsDefi = useAssetTokenUsdPrices(
    'DEFI',
    supportedAssetsDefi.map((asset) => asset.tTokenAddress)
  );

  const usdPriceResultsMain = useAssetTokenUsdPrices(
    'MAIN',
    supportedAssetsMain.map((asset) => asset.tTokenAddress)
  );

  const usdPricesLoaded = useMemo(
    () =>
      usdPriceResultsVeno.filter((r) => !!r.data).length ===
        usdPriceResultsVeno.length &&
      usdPriceResultsMain.filter((r) => !!r.data).length ===
        usdPriceResultsMain.length &&
      usdPriceResultsDefi.filter((r) => !!r.data).length ===
        usdPriceResultsDefi.length,
    [usdPriceResultsMain, usdPriceResultsVeno, usdPriceResultsDefi]
  );

  useEffect(() => {
    async function getTotalBorrowed(
      assets: TectonicAsset[],
      usdPrices: BigNumber[],
      sdk: TectonicSDK,
      poolType: PoolType
    ): Promise<void> {
      const borrowedAmounts: BigNumber[] = await Promise.all(
        assets.map(({ tTokenAddress }) =>
          sdk!.Borrow.totalBorrowedAmountInMarket(tTokenAddress)
        )
      );
      const sumTotalBorrowed: BigNumber = assets.reduce(
        (acc: BigNumber, asset: TectonicAsset, index: number) => {
          const assetUsdPrice = usdPrices[index];
          const marketBorrowedAmount = borrowedAmounts[index];
          const marketBorrowedUsdValue = getNormalizedUsdPrice(
            asset,
            getUnderlyingUsdValue(asset, marketBorrowedAmount, assetUsdPrice)
          );

          return acc.add(marketBorrowedUsdValue);
        },
        BigNumber.from(0)
      );

      setTotalBorrowed((tb) => ({ ...tb, [poolType]: sumTotalBorrowed }));
    }

    async function getTotalSupplied(
      assets: TectonicAsset[],
      usdPrices: BigNumber[],
      sdk: TectonicSDK,
      poolType: PoolType
    ): Promise<void> {
      const suppliedAmounts: BigNumber[] = await Promise.all(
        assets.map(({ tTokenAddress }) =>
          sdk!.Supply.totalSuppliedUnderlyingAmountInMarket(tTokenAddress)
        )
      );
      const sumTotalSupplied: BigNumber = assets.reduce(
        (acc: BigNumber, asset: TectonicAsset, index: number) => {
          const assetUsdPrice = usdPrices[index];
          const marketSuppliedAmount = suppliedAmounts[index];
          const marketSuppliedUsdValue = getNormalizedUsdPrice(
            asset,
            getUnderlyingUsdValue(asset, marketSuppliedAmount, assetUsdPrice)
          );

          return acc.add(marketSuppliedUsdValue);
        },
        BigNumber.from(0)
      );
      setTotalSupplied((ts) => ({ ...ts, [poolType]: sumTotalSupplied }));
    }

    async function getStats(): Promise<void> {
      const usdPricesMain = usdPriceResultsMain.map(
        (res) => res.data
      ) as BigNumber[];
      getTotalBorrowed(supportedAssetsMain, usdPricesMain, sdkMain, 'MAIN');
      getTotalSupplied(supportedAssetsMain, usdPricesMain, sdkMain, 'MAIN');
      const usdPricesVeno = usdPriceResultsVeno.map(
        (res) => res.data
      ) as BigNumber[];
      getTotalBorrowed(supportedAssetsVeno, usdPricesVeno, sdkVeno, 'VENO');
      getTotalSupplied(supportedAssetsVeno, usdPricesVeno, sdkVeno, 'VENO');

      const usdPricesDefi = usdPriceResultsDefi.map(
        (res) => res.data
      ) as BigNumber[];
      getTotalBorrowed(supportedAssetsDefi, usdPricesDefi, sdkDefi, 'DEFI');
      getTotalSupplied(supportedAssetsDefi, usdPricesDefi, sdkDefi, 'DEFI');
    }

    if (sdkMain && sdkVeno && usdPricesLoaded && !statsLoading.current) {
      statsLoading.current = true;
      getStats();
    }
  }, [
    sdkMain,
    sdkVeno,
    supportedAssetsMain,
    usdPricesLoaded,
    supportedAssetsVeno,
    usdPriceResultsVeno,
    usdPriceResultsMain,
    usdPriceResultsDefi,
    supportedAssetsDefi,
    sdkDefi,
  ]);

  const totalBorrowed = useMemo(
    () =>
      totalBorrowedMap['MAIN'] &&
      totalBorrowedMap['VENO'] &&
      totalBorrowedMap['DEFI']
        ? (totalBorrowedMap['MAIN'] as BigNumber)
            .add(totalBorrowedMap['VENO'] as BigNumber)
            .add(totalBorrowedMap['DEFI'] as BigNumber)
        : null,
    [totalBorrowedMap]
  );
  const totalSupplied = useMemo(
    () =>
      totalSuppliedMap['MAIN'] &&
      totalSuppliedMap['VENO'] &&
      totalSuppliedMap['DEFI']
        ? (totalSuppliedMap['MAIN'] as BigNumber)
            .add(totalSuppliedMap['VENO'] as BigNumber)
            .add(totalSuppliedMap['DEFI'] as BigNumber)
        : null,
    [totalSuppliedMap]
  );
  return {
    loading: totalSupplied === null || totalBorrowed === null,
    totalBorrowed,
    totalSupplied,
  };
}

export default useStats;
