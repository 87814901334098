import { TectonicAsset, TectonicSDK } from '@cronos-labs/tectonic-sdk';
import { BoostType } from '@cronos-labs/tectonic-sdk/dist/types';
import { BigNumber } from 'ethers';
import axios from 'axios';
import get from 'lodash/get';

import { getApy } from '@lib/utils';
import { LockingPeriodDetails } from '@components/LockDepositModal/types';
import { DisplayValuesProps } from '@components/AnalyticsPageView/TonicAnalytics/TonicAnalyticsActivity/types';
import { poolToPeriod } from '@components/LockDepositModal/periods';
import { QueryKey } from '@config/queryKey';
import { EnsProviderContextValue } from '@providers/EnsProvider';
import { PoolType } from '@config/base';
import { NFT_BOOSTING_NFT_ADDRESS } from '@config/base';
import { TokenCurrency } from '@hooks/useVVSSwapTrade';
import { ChainId } from '@config/baseNetworkConfig';

import {
  AccountResponse,
  ACCOUNT_QUERY,
  MarketInfoResponse,
  ReleaseEventsResponse,
  RELEASE_EVENTS_QUERY,
  StakeEventsResponse,
  STAKE_EVENTS_QUERY,
  XtonicStatsResponse,
  XTONIC_STATS_QUERY,
  XtonicAprsResponse,
  TransactionEventsResponse,
  TRANSACTION_EVENTS_QUERY,
  TRANSACTION_EVENTS_QUERY_NO_TYPE,
  TRANSACTION_EVENT_COUNT_QUERY,
  TransactionEventCountResponse,
  TonicAssistInfosResponse,
  TONIC_ASSIST_INFOS_QUERY,
  FinancialsAddressAmountsResult,
  FINANCIALS_ADDRESS_AMOUNTS_QUERY,
  FinancialStatementsPerDayResult,
  FINANCIAL_STATEMENTS_PER_DAY_QUERY,
  NEWEST_FINANCIAL_STATEMENT_QUERY,
  NewestFinancialStatementResult,
  FinancialsDataResponse,
  FINANCIALS_DATA_QUERY,
  NUM_FINANCIAL_STATEMENT_QUERY,
  NumFinancialStatementsResult,
  FINANCIALS_SUPPLYING_DATA_QUERY,
  FINANCIALS_BORROWING_DATA_QUERY,
  FinancialsSupplyingDataResponse,
  FinancialsBorrowingDataResponse,
  XTONIC_ONE_MONTH_APRS_QUERY,
  XTONIC_SIX_MONTHS_APRS_QUERY,
  StakeNFTInfosDataResponse,
  STAKE_NFT_INFORMATION_QUERY,
  TONIC_CIRCULATING_SUPPLY_QUERY,
  TonicCirculatingSupplyResult,
  RevenuePaidToStakerResult,
  REVENUE_PAID_STAKER_QUERY,
  BadDebtResponse,
} from './types';
import { reduceToMap } from './util';
import {
  tonicAnalyticsGraphQLClient,
  xtonicAnalyticsGraphQLClient,
  tonicSupplyGraphQLClient,
  stakeNFTInfosGraphQLClient,
  mainGraphQLClient,
  commonAnalyticsGraphQLClient,
  tonicVenoAnalyticsGraphQLClient,
  tonicDefiAnalyticsGraphQLClient,
} from './clients';

const getTectonicVaultPoolsQuery = (sdk: TectonicSDK) => ({
  queryKey: QueryKey.TECTONIC_VAULT_POOLS,
  queryFn: async () => {
    if (!sdk) {
      throw Error('Executing query without sdk');
    }
    return (await sdk.Vault.getPools())
      .map((p) => ({ ...p, apy: p.apy / 100 }))
      .map(poolToPeriod)
      .filter((p?: LockingPeriodDetails) => !!p) as LockingPeriodDetails[];
  },
});

const getTectonicVaultDepositsQuery = (sdk: TectonicSDK, account: string) => ({
  queryKey: QueryKey.TECTONIC_VAULT_DEPOSITS,
  queryFn: async () => {
    if (!sdk || !account) {
      throw Error('Executing query without sdk');
    }
    // the typing in the sdk is currently wrong, this is supposed to be fixed with the next version
    //eslint-disable-next-line
    //@ts-ignore
    return sdk.Vault.getUserStakes(account);
  },
});

const getVaultPendingTonicQuery = (
  sdk: TectonicSDK,
  currentAccount: string
) => ({
  queryKey: [QueryKey.TECTONIC_VAULT_PENDING_TONIC, currentAccount],
  queryFn: () => {
    if (!sdk || !currentAccount) {
      throw Error('Executing query without sdk or address');
    }
    return sdk.Vault.getPendingTonic(currentAccount);
  },
});

const API_PATH = {
  DEFI: '/info/defi-pool/market-history/symbol/',
  MAIN: '/info/market-history/symbol/',
  VENO: '/info/lcro-pool/market-history/symbol/', // FIXME: add this to the API
};
const getMarketsHistoryQuery = (symbol: string, poolType: PoolType) => ({
  queryKey: [QueryKey.API_MARKET_HISTORY, symbol, poolType],
  queryFn: async () => {
    const apyResponse = (
      await axios.get<MarketInfoResponse[]>(
        process.env.NEXT_PUBLIC_API_URL + API_PATH[poolType] + symbol
      )
    ).data;
    return apyResponse.map((info) => ({
      ...info,
      borrowApyPercent: getApy(info.borrowRate * 2.5) * 100,
      supplyApyPercent: getApy(info.supplyRate * 2.5) * 100,
      timestamp: new Date(info.timestamp),
    }));
  },
});

const getBadDebtQuery = () => ({
  queryKey: [QueryKey.RISK_DAO_BAD_DEBT],
  queryFn: async () => {
    const badDebtResponse = (
      await axios.get<BadDebtResponse>(
        `${process.env.NEXT_PUBLIC_RISK_DAO}/CRO_tectonic.json`
      )
    ).data;

    return badDebtResponse;
  },
});

const getGraphAccountQuery = (walletAddress: Null<string>) => ({
  queryKey: QueryKey.GRAPH_ACCOUNT,
  queryFn: async () => {
    const result = await mainGraphQLClient.request<AccountResponse>(
      ACCOUNT_QUERY,
      {
        address: walletAddress?.toLowerCase(),
      }
    );
    return result.account;
  },
});

const getStakingExchangeRateQuery = (sdk: TectonicSDK) => ({
  queryKey: QueryKey.TECTONIC_STAKING_EXCHANGE_RATE,
  queryFn: () => {
    return sdk?.Staking.getExchangeRate() ?? BigNumber.from(0);
  },
});

const getGraphStakeEventsQuery = (walletAddress: Null<string>) => ({
  queryKey: QueryKey.GRAPH_STAKE_EVENTS,
  queryFn: async () => {
    const result = await mainGraphQLClient.request<StakeEventsResponse>(
      STAKE_EVENTS_QUERY,
      {
        address: walletAddress?.toLowerCase(),
      }
    );
    return result.stakeEvents;
  },
});

const getGraphReleaseEventsQuery = (walletAddress: Null<string>) => ({
  queryKey: QueryKey.GRAPH_RELEASE_EVENTS,
  queryFn: async () => {
    const result = await mainGraphQLClient.request<ReleaseEventsResponse>(
      RELEASE_EVENTS_QUERY,
      {
        address: walletAddress?.toLowerCase(),
      }
    );
    return result.releaseEvents;
  },
});

const xtonicStatsQuery = {
  queryKey: QueryKey.GRAPH_XTONIC_STATS,
  queryFn: async () => {
    const result =
      await xtonicAnalyticsGraphQLClient.request<XtonicStatsResponse>(
        XTONIC_STATS_QUERY
      );
    return result.xtonicStats;
  },
};

export type XtonicAprRangeType = 'ONE_MONTH' | 'SIX_MONTHS';
const getXtonicAprsQuery = (type: XtonicAprRangeType, first: number) => ({
  queryKey: [QueryKey.GRAPH_XTONIC_APRS, type, first],
  queryFn: async () => {
    if (type === 'ONE_MONTH') {
      const result =
        await xtonicAnalyticsGraphQLClient.request<XtonicAprsResponse>(
          XTONIC_ONE_MONTH_APRS_QUERY,
          { first }
        );

      return result.xtonicAprsPerDays;
    } else {
      const latestResult =
        await xtonicAnalyticsGraphQLClient.request<XtonicAprsResponse>(
          XTONIC_ONE_MONTH_APRS_QUERY,
          { first: 1 }
        );
      const { id } = latestResult.xtonicAprsPerDays[0];

      const result =
        await xtonicAnalyticsGraphQLClient.request<XtonicAprsResponse>(
          XTONIC_SIX_MONTHS_APRS_QUERY,
          {
            ids: new Array(first)
              .fill(id)
              .map((latestId, i) => (Number(latestId) - 7 * i).toString()),
          }
        );

      return result.xtonicAprsPerDays;
    }
  },
});

const getFinancialStatementsPerDaysQuery = (
  first: Null<number>,
  skip: number,
  orderBy: string,
  orderDirection: 'desc' | 'asc'
) => ({
  queryKey: [
    QueryKey.GRAPH_FINANCIAL_STATEMENTS_PER_DAY,
    first,
    skip,
    orderBy,
    orderDirection,
  ],
  queryFn: async () => {
    const result =
      await tonicAnalyticsGraphQLClient.request<FinancialStatementsPerDayResult>(
        FINANCIAL_STATEMENTS_PER_DAY_QUERY,
        {
          first,
          skip,
          orderBy,
          orderDirection,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const getVenoFinancialStatementsPerDaysQuery = (
  first: Null<number>,
  skip: number,
  orderBy: string,
  orderDirection: 'desc' | 'asc'
) => ({
  queryKey: [
    QueryKey.GRAPH_VENO_FINANCIAL_STATEMENTS_PER_DAY,
    first,
    skip,
    orderBy,
    orderDirection,
  ],
  queryFn: async () => {
    const result =
      await tonicVenoAnalyticsGraphQLClient.request<FinancialStatementsPerDayResult>(
        FINANCIAL_STATEMENTS_PER_DAY_QUERY,
        {
          first,
          skip,
          orderBy,
          orderDirection,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const getDefiFinancialStatementsPerDaysQuery = (
  first: Null<number>,
  skip: number,
  orderBy: string,
  orderDirection: 'desc' | 'asc'
) => ({
  queryKey: [
    QueryKey.GRAPH_DEFI_FINANCIAL_STATEMENTS_PER_DAY,
    first,
    skip,
    orderBy,
    orderDirection,
  ],
  queryFn: async () => {
    const result =
      await tonicDefiAnalyticsGraphQLClient.request<FinancialStatementsPerDayResult>(
        FINANCIAL_STATEMENTS_PER_DAY_QUERY,
        {
          first,
          skip,
          orderBy,
          orderDirection,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const newestFinancialStatementQuery = {
  queryKey: [QueryKey.GRAPH_NEWEST_FINANCIAL_STATEMENT],
  queryFn: async () => {
    const result =
      await tonicAnalyticsGraphQLClient.request<NewestFinancialStatementResult>(
        NEWEST_FINANCIAL_STATEMENT_QUERY
      );
    return result.financialStatementsPerDays.length
      ? result.financialStatementsPerDays[0]
      : undefined;
  },
};

const getTransactionEventsQuery = (
  skip: number | null,
  type: DisplayValuesProps
) => ({
  queryKey: [QueryKey.GRAPH_TRANSACTION_EVENTS, skip, type],
  queryFn: async () => {
    const result = await Promise.all([
      tonicAnalyticsGraphQLClient.request<TransactionEventsResponse>(
        type === 'all'
          ? TRANSACTION_EVENTS_QUERY_NO_TYPE
          : TRANSACTION_EVENTS_QUERY,
        {
          skip,
          type,
        }
      ),
      tonicAnalyticsGraphQLClient.request<TransactionEventCountResponse>(
        TRANSACTION_EVENT_COUNT_QUERY
      ),
    ]);
    const count = result[1].transactionEventCount?.[`${type}Count`];
    return { transactionEvents: result[0].transactionEvents, count };
  },
});

const getVenoTransactionEventsQuery = (
  skip: number | null,
  type: DisplayValuesProps
) => ({
  queryKey: [QueryKey.GRAPH_VENO_TRANSACTION_EVENTS, skip, type],
  queryFn: async () => {
    const result = await Promise.all([
      tonicVenoAnalyticsGraphQLClient.request<TransactionEventsResponse>(
        type === 'all'
          ? TRANSACTION_EVENTS_QUERY_NO_TYPE
          : TRANSACTION_EVENTS_QUERY,
        {
          skip,
          type,
        }
      ),
      tonicVenoAnalyticsGraphQLClient.request<TransactionEventCountResponse>(
        TRANSACTION_EVENT_COUNT_QUERY
      ),
    ]);
    const count = result[1].transactionEventCount?.[`${type}Count`];
    return { transactionEvents: result[0].transactionEvents, count };
  },
});

const getDefiTransactionEventsQuery = (
  skip: number | null,
  type: DisplayValuesProps
) => ({
  queryKey: [QueryKey.GRAPH_DEFI_TRANSACTION_EVENTS, skip, type],
  queryFn: async () => {
    const result = await Promise.all([
      tonicDefiAnalyticsGraphQLClient.request<TransactionEventsResponse>(
        type === 'all'
          ? TRANSACTION_EVENTS_QUERY_NO_TYPE
          : TRANSACTION_EVENTS_QUERY,
        {
          skip,
          type,
        }
      ),
      tonicDefiAnalyticsGraphQLClient.request<TransactionEventCountResponse>(
        TRANSACTION_EVENT_COUNT_QUERY
      ),
    ]);
    const count = result[1].transactionEventCount?.[`${type}Count`];
    return { transactionEvents: result[0].transactionEvents, count };
  },
});

const numFinancialStatementsQuery = {
  queryKey: QueryKey.GRAPH_NUM_FINANCIAL_STATEMENTS_PER_DAY,
  queryFn: async () => {
    const [startResult, endResult] = await Promise.all([
      tonicAnalyticsGraphQLClient.request<NumFinancialStatementsResult>(
        NUM_FINANCIAL_STATEMENT_QUERY,
        {
          orderDirection: 'asc',
        }
      ),
      tonicAnalyticsGraphQLClient.request<NumFinancialStatementsResult>(
        NUM_FINANCIAL_STATEMENT_QUERY,
        {
          orderDirection: 'desc',
        }
      ),
    ]);

    if (
      !endResult.financialStatementsPerDays.length ||
      !startResult.financialStatementsPerDays.length
    ) {
      return 0;
    }

    return (
      parseInt(endResult.financialStatementsPerDays[0].id) -
      parseInt(startResult.financialStatementsPerDays[0].id) +
      1
    );
  },
};

const numVenoFinancialStatementsQuery = {
  queryKey: QueryKey.GRAPH_VENO_NUM_FINANCIAL_STATEMENTS_PER_DAY,
  queryFn: async () => {
    const [startResult, endResult] = await Promise.all([
      tonicVenoAnalyticsGraphQLClient.request<NumFinancialStatementsResult>(
        NUM_FINANCIAL_STATEMENT_QUERY,
        {
          orderDirection: 'asc',
        }
      ),
      tonicVenoAnalyticsGraphQLClient.request<NumFinancialStatementsResult>(
        NUM_FINANCIAL_STATEMENT_QUERY,
        {
          orderDirection: 'desc',
        }
      ),
    ]);

    if (
      !endResult.financialStatementsPerDays.length ||
      !startResult.financialStatementsPerDays.length
    ) {
      return 0;
    }

    return (
      parseInt(endResult.financialStatementsPerDays[0].id) -
      parseInt(startResult.financialStatementsPerDays[0].id) +
      1
    );
  },
};

const numDefiFinancialStatementsQuery = {
  queryKey: QueryKey.GRAPH_DEFI_NUM_FINANCIAL_STATEMENTS_PER_DAY,
  queryFn: async () => {
    const [startResult, endResult] = await Promise.all([
      tonicDefiAnalyticsGraphQLClient.request<NumFinancialStatementsResult>(
        NUM_FINANCIAL_STATEMENT_QUERY,
        {
          orderDirection: 'asc',
        }
      ),
      tonicDefiAnalyticsGraphQLClient.request<NumFinancialStatementsResult>(
        NUM_FINANCIAL_STATEMENT_QUERY,
        {
          orderDirection: 'desc',
        }
      ),
    ]);

    if (
      !endResult.financialStatementsPerDays.length ||
      !startResult.financialStatementsPerDays.length
    ) {
      return 0;
    }

    return (
      parseInt(endResult.financialStatementsPerDays[0].id) -
      parseInt(startResult.financialStatementsPerDays[0].id) +
      1
    );
  },
};

const assistInfosQuery = {
  queryKey: QueryKey.GRAPH_ASSIST_INFOS,
  queryFn: async () => {
    const result =
      await tonicAnalyticsGraphQLClient.request<TonicAssistInfosResponse>(
        TONIC_ASSIST_INFOS_QUERY
      );
    return result.tonicAssistInfos;
  },
};

const assistInfosVenoQuery = {
  queryKey: QueryKey.GRAPH_VENO_ASSIST_INFOS,
  queryFn: async () => {
    const result =
      await tonicVenoAnalyticsGraphQLClient.request<TonicAssistInfosResponse>(
        TONIC_ASSIST_INFOS_QUERY
      );
    return result.tonicAssistInfos;
  },
};

const assistInfosDefiQuery = {
  queryKey: QueryKey.GRAPH_DEFI_ASSIST_INFOS,
  queryFn: async () => {
    const result =
      await tonicDefiAnalyticsGraphQLClient.request<TonicAssistInfosResponse>(
        TONIC_ASSIST_INFOS_QUERY
      );
    return result.tonicAssistInfos;
  },
};

const getFinancialsAddressAmountsQuery = (
  market: string,
  orderDirection?: 'desc' | 'asc',
  first?: number
) => ({
  queryKey: [
    QueryKey.GRAPH_FINANCIALS_ADDRESS_AMOUNTS,
    market,
    orderDirection,
    first,
  ],
  queryFn: async () => {
    const result =
      await tonicAnalyticsGraphQLClient.request<FinancialsAddressAmountsResult>(
        FINANCIALS_ADDRESS_AMOUNTS_QUERY,
        {
          market,
          orderDirection: orderDirection || 'desc',
          first: first ?? 365,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const getVenoFinancialsAddressAmountsQuery = (
  market: string,
  orderDirection?: 'desc' | 'asc',
  first?: number
) => ({
  queryKey: [
    QueryKey.GRAPH_VENO_FINANCIALS_ADDRESS_AMOUNTS,
    market,
    orderDirection,
    first,
  ],
  queryFn: async () => {
    const result =
      await tonicVenoAnalyticsGraphQLClient.request<FinancialsAddressAmountsResult>(
        FINANCIALS_ADDRESS_AMOUNTS_QUERY,
        {
          market,
          orderDirection: orderDirection || 'desc',
          first: first ?? 365,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const getDefiFinancialsAddressAmountsQuery = (
  market: string,
  orderDirection?: 'desc' | 'asc',
  first?: number
) => ({
  queryKey: [
    QueryKey.GRAPH_DEFI_FINANCIALS_ADDRESS_AMOUNTS,
    market,
    orderDirection,
    first,
  ],
  queryFn: async () => {
    const result =
      await tonicDefiAnalyticsGraphQLClient.request<FinancialsAddressAmountsResult>(
        FINANCIALS_ADDRESS_AMOUNTS_QUERY,
        {
          market,
          orderDirection: orderDirection || 'desc',
          first: first ?? 365,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const financialsDataQuery = {
  queryKey: QueryKey.GRAPH_FINANCIALS_DATA,
  queryFn: async () => {
    const result =
      await tonicAnalyticsGraphQLClient.request<FinancialsDataResponse>(
        FINANCIALS_DATA_QUERY
      );
    return result.financialStatementsPerDays;
  },
};

const financialsVenoDataQuery = {
  queryKey: QueryKey.GRAPH_VENO_FINANCIALS_DATA,
  queryFn: async () => {
    const result =
      await tonicVenoAnalyticsGraphQLClient.request<FinancialsDataResponse>(
        FINANCIALS_DATA_QUERY
      );
    return result.financialStatementsPerDays;
  },
};

const financialsDefiDataQuery = {
  queryKey: QueryKey.GRAPH_DEFI_FINANCIALS_DATA,
  queryFn: async () => {
    const result =
      await tonicDefiAnalyticsGraphQLClient.request<FinancialsDataResponse>(
        FINANCIALS_DATA_QUERY
      );
    return result.financialStatementsPerDays;
  },
};

const getFinancialsSupplyingDataQuery = (market: string) => ({
  queryKey: [QueryKey.GRAPH_FINANCIALS_SUPPLYING_DATA, market],
  queryFn: async () => {
    const result =
      await tonicAnalyticsGraphQLClient.request<FinancialsSupplyingDataResponse>(
        FINANCIALS_SUPPLYING_DATA_QUERY,
        {
          market,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const getVenoFinancialsSupplyingDataQuery = (market: string) => ({
  queryKey: [QueryKey.GRAPH_VENO_FINANCIALS_SUPPLYING_DATA, market],
  queryFn: async () => {
    const result =
      await tonicVenoAnalyticsGraphQLClient.request<FinancialsSupplyingDataResponse>(
        FINANCIALS_SUPPLYING_DATA_QUERY,
        {
          market,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const getFinancialsBorrowingDataQuery = (market: string) => ({
  queryKey: [QueryKey.GRAPH_FINANCIALS_BORROWING_DATA, market],
  queryFn: async () => {
    const result =
      await tonicAnalyticsGraphQLClient.request<FinancialsBorrowingDataResponse>(
        FINANCIALS_BORROWING_DATA_QUERY,
        {
          market,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const getDefiFinancialsSupplyingDataQuery = (market: string) => ({
  queryKey: [QueryKey.GRAPH_DEFI_FINANCIALS_SUPPLYING_DATA, market],
  queryFn: async () => {
    const result =
      await tonicDefiAnalyticsGraphQLClient.request<FinancialsSupplyingDataResponse>(
        FINANCIALS_SUPPLYING_DATA_QUERY,
        {
          market,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const getVenoFinancialsBorrowingDataQuery = (market: string) => ({
  queryKey: [QueryKey.GRAPH_VENO_FINANCIALS_BORROWING_DATA, market],
  queryFn: async () => {
    const result =
      await tonicVenoAnalyticsGraphQLClient.request<FinancialsBorrowingDataResponse>(
        FINANCIALS_BORROWING_DATA_QUERY,
        {
          market,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const getDefiFinancialsBorrowingDataQuery = (market: string) => ({
  queryKey: [QueryKey.GRAPH_DEFI_FINANCIALS_BORROWING_DATA, market],
  queryFn: async () => {
    const result =
      await tonicDefiAnalyticsGraphQLClient.request<FinancialsBorrowingDataResponse>(
        FINANCIALS_BORROWING_DATA_QUERY,
        {
          market,
        }
      );
    return result.financialStatementsPerDays;
  },
});

const tonicCirculatingSupplyQuery = {
  queryKey: QueryKey.GRAPH_TONIC_CIRCULATING_SUPPLY,
  queryFn: async () => {
    const result =
      await tonicSupplyGraphQLClient.request<TonicCirculatingSupplyResult>(
        TONIC_CIRCULATING_SUPPLY_QUERY
      );
    return result.tonicSupply;
  },
};

const revenuePaidStakerQuery = {
  queryKey: QueryKey.GRAPH_REVENUE_PAID_STAKER,
  queryFn: async () => {
    const result =
      await commonAnalyticsGraphQLClient.request<RevenuePaidToStakerResult>(
        REVENUE_PAID_STAKER_QUERY
      );
    return result.markets;
  },
};

const getCollateralFactorsQuery = (
  tectonicSdk: TectonicSDK,
  assets: TectonicAsset[]
) => ({
  queryFn: async () => {
    const result = await Promise.all(
      assets.map((asset) =>
        tectonicSdk.Borrow.collateralFactor(asset.tTokenAddress)
      )
    );
    return reduceToMap(result, assets);
  },
  queryKey: QueryKey.TECTONIC_COLLATERAL_FACTORS,
});

const getIsCollateralQuery = (
  tectonicSdk: TectonicSDK,
  assets: TectonicAsset[],
  address: string
) => ({
  queryFn: async () => {
    const result = await Promise.all(
      assets.map((asset) =>
        tectonicSdk.Supply.checkCollateralEnabled(asset.tTokenAddress, address)
      )
    );
    return reduceToMap(result, assets);
  },
  queryKey: [QueryKey.TECTONIC_IS_COLLATERAL, address, ...assets], // NOTE: because the main and defi pool have different assets
});

const getSupplyTTokenAmountsQuery = (
  tectonicSdk: TectonicSDK,
  assets: TectonicAsset[],
  address: string
) => ({
  queryFn: async () => {
    const result = await Promise.all(
      assets.map((asset) =>
        tectonicSdk.Supply.suppliedAmountOfUser(asset.tTokenAddress, address)
      )
    );
    return reduceToMap(result, assets);
  },
  queryKey: [QueryKey.TECTONIC_TTOKEN_AMOUNTS, address, ...assets],
});

const getTonicDailyDistributionRateForSupplyQuery = (
  tectonicSdk: TectonicSDK,
  tTokenAddress: string
) => ({
  queryFn: () =>
    tectonicSdk.Tonic.tonicDailyDistributeRateForSupply(tTokenAddress),
  queryKey: [
    QueryKey.TECTONIC_TONIC_DAILY_DISTRIBUTION_RATE_FOR_SUPPLY,
    tTokenAddress,
  ],
});

const getTonicDailyDistributionRateForBorrowQuery = (
  tectonicSdk: TectonicSDK,
  tTokenAddress: string
) => ({
  queryFn: () =>
    tectonicSdk.Tonic.tonicDailyDistributeRateForBorrow(tTokenAddress),
  queryKey: [
    QueryKey.TECTONIC_TONIC_DAILY_DISTRIBUTION_RATE_FOR_BORROW,
    tTokenAddress,
  ],
});

const getNonTonicDailyDistributionRatesForSupplyQuery = (
  tectonicSdk: TectonicSDK,
  tTokenAddress: string
) => ({
  queryFn: () =>
    tectonicSdk.Tonic.nonTonicDailyDistributeRateForSupply(tTokenAddress),
  queryKey: [
    QueryKey.TECTONIC_NON_TONIC_DAILY_DISTRIBUTION_RATES_FOR_SUPPLY,
    tTokenAddress,
  ],
});

const getNonTonicDailyDistributionRatesForBorrowQuery = (
  tectonicSdk: TectonicSDK,
  tTokenAddress: string
) => ({
  queryFn: () =>
    tectonicSdk.Tonic.nonTonicDailyDistributeRateForBorrow(tTokenAddress),
  queryKey: [
    QueryKey.TECTONIC_NON_TONIC_DAILY_DISTRIBUTION_RATES_FOR_BORROW,
    tTokenAddress,
  ],
});

const getSupplyApyQuery = (
  tectonicSdk: TectonicSDK,
  tTokenAddress: string
) => ({
  queryFn: () => tectonicSdk.Supply.supplyApy(tTokenAddress),
  queryKey: [QueryKey.TECTONIC_SUPPLY_APY, tTokenAddress],
});

const getBorrowApyQuery = (
  tectonicSdk: TectonicSDK,
  tTokenAddress: string
) => ({
  queryFn: () => tectonicSdk.Borrow.borrowApy(tTokenAddress),
  queryKey: [QueryKey.TECTONIC_BORROW_APY, tTokenAddress],
});

const getSupplyAmountQuery = (
  tectonicSdk: TectonicSDK,
  tTokenAddress: string
) => ({
  queryFn: () =>
    tectonicSdk.Supply.totalSuppliedUnderlyingAmountInMarket(tTokenAddress),
  queryKey: [QueryKey.TECTONIC_SUPPLY_AMOUNT, tTokenAddress],
});

const getBorrowAmountQuery = (
  tectonicSdk: TectonicSDK,
  tTokenAddress: string
) => ({
  queryFn: () => tectonicSdk.Borrow.totalBorrowedAmountInMarket(tTokenAddress),
  queryKey: [QueryKey.TECTONIC_BORROW_AMOUNT, tTokenAddress],
});

const getUserSupplyAmountsQuery = (
  tectonicSdk: TectonicSDK,
  assets: TectonicAsset[],
  address: string
) => ({
  queryFn: async () => {
    const result = await Promise.all(
      assets.map((asset) =>
        tectonicSdk.Supply.suppliedUnderlyingAmountOfUser(
          asset.tTokenAddress,
          address
        )
      )
    );
    return reduceToMap(result, assets);
  },
  queryKey: [QueryKey.TECTONIC_USER_SUPPLY_AMOUNTS, address, ...assets],
});

const getUserBorrowAmountsQuery = (
  tectonicSdk: TectonicSDK,
  assets: TectonicAsset[],
  address: string
) => ({
  queryFn: async () => {
    const result = await Promise.all(
      assets.map((asset) =>
        tectonicSdk.Borrow.borrowedAmountOfUser(asset.tTokenAddress, address)
      )
    );
    return reduceToMap(result, assets);
  },
  queryKey: [QueryKey.TECTONIC_USER_BORROW_AMOUNTS, address, ...assets],
});

const getEnsAddressQuery = (
  ensProvider: EnsProviderContextValue,
  address: string
) => ({
  queryFn: async () => {
    return await ensProvider.lookupAddress(address);
  },
  queryKey: [QueryKey.ENS_PROVIDER_ADDRESS, address],
});

const getConversionBountyData = (
  tectonicSdk: TectonicSDK,
  address: string
) => ({
  queryFn: async () => {
    const [bountyYield, bountyReward] =
      await tectonicSdk.Staking.estimatePullAndConvertYieldingAndReward();
    return {
      bountyYield,
      bountyReward,
    };
  },
  queryKey: [QueryKey.TECTONIC_CONVERSION_BOUNTY_DATA, address],
});

const getPartnerTokensQuery = (tectonicSdk: TectonicSDK, address: string) => ({
  queryFn: async () => {
    const tokenRewarderAddress = tectonicSdk.getTokenRewarder().address;
    return await tectonicSdk.Tonic.getNonTonicTokenListAndUnclaimedAmount(
      address,
      [tokenRewarderAddress]
    );
  },
  queryKey: [QueryKey.TECTONIC_PARTNER_TOKENS, address],
});

const getEstimateTonicInBatch = (
  tectonicSdk: TectonicSDK,
  tectonicCores: string[],
  claimantAddress: string
) => ({
  queryFn: async () =>
    await tectonicSdk.Tonic.estimateTonicInBatch(
      tectonicCores,
      claimantAddress
    ),
  queryKey: [QueryKey.TECTONIC_PARTNER_TOKENS, tectonicCores, claimantAddress],
});

const getPartnerTokenListQuery = (tectonicSdk: TectonicSDK) => ({
  queryFn: () => tectonicSdk.Tonic.getPartnerTokenList(),
  queryKey: [QueryKey.TECTONIC_PARTNER_TOKEN_LIST],
});

const getERC721BalanceOf = (
  tectonicSdk: TectonicSDK,
  tokenContractAddress: string,
  ownerAddress: string
) => ({
  queryFn: async () =>
    await tectonicSdk.ERC721.balanceOf(tokenContractAddress, ownerAddress),
  queryKey: [
    QueryKey.TECTONIC_ERC721_BALANCE_OF,
    tokenContractAddress,
    ownerAddress,
  ],
});

const getERC721TokensOfOwnerByIndices = (
  tectonicSdk: TectonicSDK,
  tokenContractAddress: string,
  ownerAddress: string,
  indices: BigNumber[]
) => ({
  queryFn: async () =>
    await Promise.all(
      indices.map((index) =>
        tectonicSdk.ERC721.tokenOfOwnerByIndex(
          tokenContractAddress,
          ownerAddress,
          index
        )
      )
    ),
  queryKey: [
    QueryKey.TECTONIC_ERC721_TOKENS_OF_OWNER_BY_INDICES,
    tokenContractAddress,
    ownerAddress,
    indices,
  ],
});

const getERC721TokenURI = (
  tectonicSdk: TectonicSDK,
  tokenContractAddress: string,
  tokenIds: BigNumber[]
) => ({
  queryFn: async () =>
    await Promise.all(
      tokenIds.map((tokenId) =>
        tectonicSdk.ERC721.tokenURI(tokenContractAddress, tokenId)
      )
    ),
  queryKey: [
    QueryKey.TECTONIC_ERC721_TOKEN_URI,
    tokenContractAddress,
    tokenIds,
  ],
});

const stakeNFTs = (
  tectonicSdk: TectonicSDK,
  nftContract: string,
  tokenIds: BigNumber[],
  poolId: number
) => ({
  queryFn: async () =>
    await tectonicSdk.Vault.stakeNFTs(nftContract, tokenIds, poolId),
  queryKey: [QueryKey.TECTONIC_VAULT_STAKE_NFTS, nftContract, tokenIds, poolId],
});

const unStakeNFTs = (
  tectonicSdk: TectonicSDK,
  nftContract: string,
  tokenIds: BigNumber[],
  poolId: number
) => ({
  queryFn: async () =>
    await tectonicSdk.Vault.unstakeNFTs(nftContract, tokenIds, poolId),
  queryKey: [
    QueryKey.TECTONIC_VAULT_UNSTAKE_NFTS,
    nftContract,
    tokenIds,
    poolId,
  ],
});

const replaceNFTs = (
  tectonicSdk: TectonicSDK,
  nftContract: string,
  unstakeTokenIds: BigNumber[],
  stakeTokenIds: BigNumber[],
  poolId: number
) => ({
  queryFn: async () =>
    await tectonicSdk.Vault.replaceNFTs(
      nftContract,
      unstakeTokenIds,
      stakeTokenIds,
      poolId
    ),
  queryKey: [
    QueryKey.TECTONIC_VAULT_REPLACE_NFTS,
    nftContract,
    unstakeTokenIds,
    stakeTokenIds,
    poolId,
  ],
});

const getUserStakedCollections = (
  tectonicSdk: TectonicSDK,
  ownerAddress: string
) => ({
  queryFn: async () =>
    await tectonicSdk.Vault.getUserStakedCollections(ownerAddress),
  queryKey: [QueryKey.TECTONIC_VAULT_GET_USER_STAKED_COLLECTIONS, ownerAddress],
});

const getEnabledNFTCollections = (tectonicSdk: TectonicSDK) => ({
  queryFn: async () => await tectonicSdk.Vault.getEnabledNFTCollections(),
  queryKey: [QueryKey.TECTONIC_VAULT_GET_ENABLED_NFT_COLLECTIONS],
});

const getUserStakedNFTsByCollection = (
  tectonicSdk: TectonicSDK,
  ownerAddress: string,
  nftContract: string
) => ({
  queryFn: async () =>
    await tectonicSdk.Vault.getUserStakedNFTsByCollection(
      ownerAddress,
      nftContract
    ),
  queryKey: [
    QueryKey.TECTONIC_VAULT_GET_USER_STAKED_NFTS_BY_COLLECTION,
    ownerAddress,
    nftContract,
  ],
});

const getStakedNFTTokenIds = (
  tectonicSdk: TectonicSDK,
  ownerAddress: string,
  poolId: number,
  nftContract: string
) => ({
  queryFn: async () =>
    await tectonicSdk.Vault.getTokenIds(ownerAddress, poolId, nftContract),

  queryKey: [
    QueryKey.TECTONIC_VAULT_GET_STAKED_NFT_TOKEN_IDS,
    ownerAddress,
    poolId,
    nftContract,
  ],
});

const getUserNftBoostMultiplier = (
  tectonicSdk: TectonicSDK,
  ownerAddress: string,
  poolId: number
) => ({
  queryFn: async () =>
    await tectonicSdk.Vault.getUserNftBoostMultiplier(ownerAddress, poolId),

  queryKey: [
    QueryKey.TECTONIC_VAULT_GET_USER_NFT_BOOST_MULTIPLIER,
    ownerAddress,
    poolId,
  ],
});

const getEstimatedUserNftBoostMultiplier = (
  tectonicSdk: TectonicSDK,
  ownerAddress: string,
  poolId: number,
  stakeNftIds: BigNumber[],
  unstakeNftIds: BigNumber[]
) => ({
  queryFn: async () =>
    await tectonicSdk.Vault.getEstimatedUserNftBoostMultiplier(
      NFT_BOOSTING_NFT_ADDRESS[0],
      ownerAddress,
      stakeNftIds,
      unstakeNftIds,
      poolId
    ),

  queryKey: [
    QueryKey.TECTONIC_VAULT_GET_ESTIMATED_USER_NFT_BOOST_MULTIPLIER,
    poolId,
    stakeNftIds,
    unstakeNftIds,
  ],
});

const getMaxEstimatedUserNftBoostMultiplier = (
  tectonicSdk: TectonicSDK,
  tokenIds: BigNumber[]
) => ({
  queryFn: async () =>
    await tectonicSdk.Vault.getMaxEstimatedNftBoostMultiplier(
      NFT_BOOSTING_NFT_ADDRESS[0],
      tokenIds
    ),

  queryKey: [
    QueryKey.TECTONIC_VAULT_GET_MAX_ESTIMATED_USER_NFT_BOOST_MULTIPLIER,
    tokenIds,
  ],
});

const getNFTMultiplier = (tectonicSdk: TectonicSDK, tokenId: BigNumber) => ({
  queryFn: async () =>
    await tectonicSdk.Vault.getNftMultiplier(
      NFT_BOOSTING_NFT_ADDRESS[0],
      tokenId
    ),

  queryKey: [QueryKey.TECTONIC_VAULT_GET_NFT_MULTIPLIER, tokenId],
});

const getStakeNFTInfosQuery = (tokenId: Undefined<string>) => ({
  queryFn: async () => {
    const result =
      await stakeNFTInfosGraphQLClient.request<StakeNFTInfosDataResponse>(
        STAKE_NFT_INFORMATION_QUERY,
        {
          nftContract: NFT_BOOSTING_NFT_ADDRESS[0],
          tokenId,
        }
      );
    return result.stakeNftInfos;
  },
  queryKey: [QueryKey.GRAPH_STAKE_NFT_INFOS, tokenId],
});

const getAssetUsdPriceQuery = (
  tectonicSdk: TectonicSDK,
  tokenAddress: string
) => ({
  queryFn: async () => await tectonicSdk.Oracle.tokenPriceInUsd(tokenAddress),
  queryKey: [QueryKey.TECTONIC_ASSET_PRICE_IN_USD, tokenAddress],
});

const getPartnerTokenUsdPriceQuery = (
  tectonicSdk: TectonicSDK,
  tokenAddress: string
) => ({
  queryFn: async () =>
    await tectonicSdk.Oracle.tokenPriceInUsdFromCronosOracleRegistry(
      tokenAddress
    ),
  queryKey: [QueryKey.TECTONIC_PARTNER_TOKEN_PRICE_IN_USD, tokenAddress],
});

const getVvsSwapTradeData = (
  inputValue: string,
  inputCurrency: Null<TokenCurrency>,
  slippageAmount: number,
  outputCurrency?: Null<TokenCurrency>
) => ({
  queryKey: [QueryKey.VVS_SWAP_TRADE_DATA],
  queryFn: async () => {
    const result = await axios.get(
      process.env.NEXT_PUBLIC_API_URL + '/vvs/swaping/',
      {
        params: {
          inputValue,
          inputCurrency,
          outputCurrency,
          slippageAmount,
        },
      }
    );
    return result?.data;
  },
});

const getWowmaxSwapTradeData = (
  inputValue: string,
  fromCurrency: string,
  slippageAmount: string,
  chainId: ChainId,
  toCurrency?: string
) => ({
  queryKey: [QueryKey.WOWMAX_SWAP_TRADE_DATA],
  queryFn: async () => {
    const result = (
      await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}/wowmax/swap/${inputValue}/${fromCurrency}/${toCurrency}/${slippageAmount}/${chainId}`
      )
    ).data;
    return result;
  },
});

const getTTokenSupplyAmountOfUser = (
  tectonicSdk: TectonicSDK,
  walletAddress: string,
  tToken: string
) => ({
  queryKey: [QueryKey.GET_TTOKEN_SUPPLY_AMOUNT_USER, walletAddress, tToken],
  queryFn: async () =>
    await tectonicSdk.Supply.tTokenSupplyAmountOfUser(walletAddress, tToken),
});

const getTTokenBorrowAmountOfUser = (
  tectonicSdk: TectonicSDK,
  walletAddress: string,
  tToken: string
) => ({
  queryKey: [QueryKey.GET_TTOKEN_BORROW_AMOUNT_USER, walletAddress, tToken],
  queryFn: async () =>
    await tectonicSdk.Borrow.tTokenBorrowAmountOfUser(walletAddress, tToken),
});

const boostMarketsQuery = (
  tectonicSdk: TectonicSDK,
  tTokens: string[],
  boostTypes: BoostType[]
) => ({
  queryKey: [QueryKey.BOOST_MARKET, tTokens, boostTypes],
  queryFn: async () =>
    await tectonicSdk.Boost.boostMarkets(tTokens, boostTypes),
});

const removeBoostMarkets = (
  tectonicSdk: TectonicSDK,
  tTokens: string[],
  boostTypes: BoostType[]
) => ({
  queryKey: [QueryKey.REMOVE_BOOST_MARKET, tTokens, boostTypes],
  queryFn: async () =>
    await tectonicSdk.Boost.removeBoostMarkets(tTokens, boostTypes),
});

const replaceBoostMarkets = (
  tectonicSdk: TectonicSDK,
  oldTTokens: string[],
  oldBoostTypes: BoostType[],
  newTTokens: string[],
  newBoostTypes: BoostType[]
) => ({
  queryKey: [
    QueryKey.REPLACE_BOOST_MARKET,
    oldTTokens,
    oldBoostTypes,
    newTTokens,
    newBoostTypes,
  ],
  queryFn: async () =>
    await tectonicSdk.Boost.replaceBoostMarkets(
      oldTTokens,
      oldBoostTypes,
      newTTokens,
      newBoostTypes
    ),
});

// NOTE: when the user has no boost, so we need to check if the user has boost
const calculateBoostMultiplier = (
  tectonicSDK: TectonicSDK,
  walletAddress: string
) => ({
  queryKey: [QueryKey.CALCULATE_BOOST_MULTIPLIER, walletAddress],
  queryFn: async () =>
    await tectonicSDK.Boost.calculateBoostMultiplier(walletAddress),
});

/**
 *
 * @param walletAddress user wallet address
 * @param tToken
 * @param boostType
 * @returns Promise<[count, multiplier, amount, weightedAmount]> user's boost info
 */
const getUserInfo = (
  tectonicSdk: TectonicSDK,
  walletAddress: string,
  tToken: string,
  boostType: BoostType
) => ({
  queryKey: [QueryKey.GET_USER_BOOST_INFO, walletAddress, tToken, boostType],
  queryFn: async () =>
    await tectonicSdk.Boost.getUserInfo(walletAddress, tToken, boostType),
});

const getUserBoostCollections = (
  tectonicSdk: TectonicSDK,
  walletAddress: string,
  boostType: BoostType
) => ({
  queryKey: [QueryKey.GET_USER_BOOST_COLLECTIONS, walletAddress, boostType],
  queryFn: async () =>
    await tectonicSdk.Boost.getUserBoostCollections(walletAddress, boostType),
});

const getUnClaimTonic = (tectonicSdk: TectonicSDK, walletAddress: string) => ({
  queryKey: [QueryKey.GET_UNCLAIM_TONIC_REWARDS, walletAddress],
  queryFn: async () => await tectonicSdk.Boost.getUnClaimTonic(walletAddress),
});

const getTonicMarketStates = (
  tectonicSdk: TectonicSDK,
  tToken: string,
  boostType: BoostType
) => ({
  queryKey: [QueryKey.GET_TONIC_MARKETSTATES, tToken, boostType],
  queryFn: async () => await tectonicSdk.Boost.marketStates(tToken, boostType),
});

const getTtokenExchangeRateQuery = (
  sdk: TectonicSDK,
  tTokenAddress: string
) => ({
  queryKey: [QueryKey.TECTONIC_SUPPLY_EXCHANGE_RATE, tTokenAddress],
  queryFn: async () => await sdk?.Supply.exchangeRate(tTokenAddress),
});

const getXtonicBoostDailyDistributeRate = (
  tectonicSdk: TectonicSDK,
  tToken: string,
  boostType: BoostType
) => ({
  queryKey: [
    QueryKey.GET_XTONIC_BOOST_DAILY_DISTRIBUTE_RATE,
    tToken,
    boostType,
  ],

  queryFn: async () => {
    const result = await tectonicSdk.Boost.xTonicBoostDailyDistributeRate(
      tToken,
      boostType
    );
    return result;
  },
});

const queries = {
  [QueryKey.API_MARKET_HISTORY]: getMarketsHistoryQuery,
  [QueryKey.ENS_PROVIDER_ADDRESS]: getEnsAddressQuery,
  [QueryKey.GRAPH_ACCOUNT]: getGraphAccountQuery,
  [QueryKey.GRAPH_ASSIST_INFOS]: assistInfosQuery,
  [QueryKey.GRAPH_VENO_ASSIST_INFOS]: assistInfosVenoQuery,
  [QueryKey.GRAPH_DEFI_ASSIST_INFOS]: assistInfosDefiQuery,
  [QueryKey.GRAPH_FINANCIAL_STATEMENTS_PER_DAY]:
    getFinancialStatementsPerDaysQuery,
  [QueryKey.GRAPH_VENO_FINANCIAL_STATEMENTS_PER_DAY]:
    getVenoFinancialStatementsPerDaysQuery,
  [QueryKey.GRAPH_DEFI_FINANCIAL_STATEMENTS_PER_DAY]:
    getDefiFinancialStatementsPerDaysQuery,
  [QueryKey.GRAPH_FINANCIALS_ADDRESS_AMOUNTS]: getFinancialsAddressAmountsQuery,
  [QueryKey.GRAPH_VENO_FINANCIALS_ADDRESS_AMOUNTS]:
    getVenoFinancialsAddressAmountsQuery,
  [QueryKey.GRAPH_DEFI_FINANCIALS_ADDRESS_AMOUNTS]:
    getDefiFinancialsAddressAmountsQuery,
  [QueryKey.GRAPH_NEWEST_FINANCIAL_STATEMENT]: newestFinancialStatementQuery,
  [QueryKey.GRAPH_NUM_FINANCIAL_STATEMENTS_PER_DAY]:
    numFinancialStatementsQuery,
  [QueryKey.GRAPH_VENO_NUM_FINANCIAL_STATEMENTS_PER_DAY]:
    numVenoFinancialStatementsQuery,
  [QueryKey.GRAPH_DEFI_NUM_FINANCIAL_STATEMENTS_PER_DAY]:
    numDefiFinancialStatementsQuery,
  [QueryKey.GRAPH_RELEASE_EVENTS]: getGraphReleaseEventsQuery,
  [QueryKey.GRAPH_STAKE_EVENTS]: getGraphStakeEventsQuery,
  [QueryKey.GRAPH_TRANSACTION_EVENTS]: getTransactionEventsQuery,
  [QueryKey.GRAPH_VENO_TRANSACTION_EVENTS]: getVenoTransactionEventsQuery,
  [QueryKey.GRAPH_DEFI_TRANSACTION_EVENTS]: getDefiTransactionEventsQuery,
  [QueryKey.GRAPH_XTONIC_STATS]: xtonicStatsQuery,
  [QueryKey.GRAPH_XTONIC_APRS]: getXtonicAprsQuery,
  [QueryKey.GRAPH_FINANCIALS_DATA]: financialsDataQuery,
  [QueryKey.GRAPH_VENO_FINANCIALS_DATA]: financialsVenoDataQuery,
  [QueryKey.GRAPH_DEFI_FINANCIALS_DATA]: financialsDefiDataQuery,
  [QueryKey.GRAPH_FINANCIALS_SUPPLYING_DATA]: getFinancialsSupplyingDataQuery,
  [QueryKey.GRAPH_FINANCIALS_BORROWING_DATA]: getFinancialsBorrowingDataQuery,
  [QueryKey.GRAPH_VENO_FINANCIALS_SUPPLYING_DATA]:
    getVenoFinancialsSupplyingDataQuery,
  [QueryKey.GRAPH_VENO_FINANCIALS_BORROWING_DATA]:
    getVenoFinancialsBorrowingDataQuery,
  [QueryKey.GRAPH_DEFI_FINANCIALS_SUPPLYING_DATA]:
    getDefiFinancialsSupplyingDataQuery,
  [QueryKey.GRAPH_DEFI_FINANCIALS_BORROWING_DATA]:
    getDefiFinancialsBorrowingDataQuery,
  [QueryKey.GRAPH_TONIC_CIRCULATING_SUPPLY]: tonicCirculatingSupplyQuery,
  [QueryKey.GRAPH_REVENUE_PAID_STAKER]: revenuePaidStakerQuery,
  [QueryKey.GRAPH_STAKE_NFT_INFOS]: getStakeNFTInfosQuery,
  [QueryKey.TECTONIC_BORROW_AMOUNT]: getBorrowAmountQuery,
  [QueryKey.TECTONIC_BORROW_APY]: getBorrowApyQuery,
  [QueryKey.TECTONIC_TONIC_DAILY_DISTRIBUTION_RATE_FOR_BORROW]:
    getTonicDailyDistributionRateForBorrowQuery,
  [QueryKey.TECTONIC_NON_TONIC_DAILY_DISTRIBUTION_RATES_FOR_BORROW]:
    getNonTonicDailyDistributionRatesForBorrowQuery,
  [QueryKey.TECTONIC_USER_BORROW_AMOUNTS]: getUserBorrowAmountsQuery,
  [QueryKey.TECTONIC_COLLATERAL_FACTORS]: getCollateralFactorsQuery,
  [QueryKey.TECTONIC_CONVERSION_BOUNTY_DATA]: getConversionBountyData,
  [QueryKey.TECTONIC_IS_COLLATERAL]: getIsCollateralQuery,
  [QueryKey.TECTONIC_STAKING_EXCHANGE_RATE]: getStakingExchangeRateQuery,
  [QueryKey.TECTONIC_TONIC_DAILY_DISTRIBUTION_RATE_FOR_SUPPLY]:
    getTonicDailyDistributionRateForSupplyQuery,
  [QueryKey.TECTONIC_NON_TONIC_DAILY_DISTRIBUTION_RATES_FOR_SUPPLY]:
    getNonTonicDailyDistributionRatesForSupplyQuery,
  [QueryKey.TECTONIC_SUPPLY_AMOUNT]: getSupplyAmountQuery,
  [QueryKey.TECTONIC_SUPPLY_APY]: getSupplyApyQuery,
  [QueryKey.TECTONIC_USER_SUPPLY_AMOUNTS]: getUserSupplyAmountsQuery,
  [QueryKey.TECTONIC_SUPPLY_EXCHANGE_RATE]: getTtokenExchangeRateQuery,
  [QueryKey.TECTONIC_TTOKEN_AMOUNTS]: getSupplyTTokenAmountsQuery,
  [QueryKey.TECTONIC_VAULT_PENDING_TONIC]: getVaultPendingTonicQuery,
  [QueryKey.TECTONIC_VAULT_POOLS]: getTectonicVaultPoolsQuery,
  [QueryKey.TECTONIC_VAULT_DEPOSITS]: getTectonicVaultDepositsQuery,
  [QueryKey.TECTONIC_PARTNER_TOKENS]: getPartnerTokensQuery,
  [QueryKey.TECTONIC_PARTNER_TOKEN_LIST]: getPartnerTokenListQuery,
  [QueryKey.TECTONIC_ESTIMATE_TONIC_IN_BATCH]: getEstimateTonicInBatch,
  [QueryKey.TECTONIC_ERC721_BALANCE_OF]: getERC721BalanceOf,
  [QueryKey.TECTONIC_ERC721_TOKENS_OF_OWNER_BY_INDICES]:
    getERC721TokensOfOwnerByIndices,
  [QueryKey.TECTONIC_ERC721_TOKEN_URI]: getERC721TokenURI,
  [QueryKey.TECTONIC_VAULT_STAKE_NFTS]: stakeNFTs,
  [QueryKey.TECTONIC_VAULT_UNSTAKE_NFTS]: unStakeNFTs,
  [QueryKey.TECTONIC_VAULT_REPLACE_NFTS]: replaceNFTs,
  [QueryKey.TECTONIC_VAULT_GET_USER_STAKED_COLLECTIONS]:
    getUserStakedCollections,
  [QueryKey.TECTONIC_VAULT_GET_ENABLED_NFT_COLLECTIONS]:
    getEnabledNFTCollections,
  [QueryKey.TECTONIC_VAULT_GET_USER_STAKED_NFTS_BY_COLLECTION]:
    getUserStakedNFTsByCollection,
  [QueryKey.TECTONIC_VAULT_GET_STAKED_NFT_TOKEN_IDS]: getStakedNFTTokenIds,
  [QueryKey.TECTONIC_VAULT_GET_USER_NFT_BOOST_MULTIPLIER]:
    getUserNftBoostMultiplier,
  [QueryKey.TECTONIC_VAULT_GET_ESTIMATED_USER_NFT_BOOST_MULTIPLIER]:
    getEstimatedUserNftBoostMultiplier,
  [QueryKey.TECTONIC_VAULT_GET_MAX_ESTIMATED_USER_NFT_BOOST_MULTIPLIER]:
    getMaxEstimatedUserNftBoostMultiplier,
  [QueryKey.TECTONIC_VAULT_GET_NFT_MULTIPLIER]: getNFTMultiplier,
  [QueryKey.TECTONIC_ASSET_PRICE_IN_USD]: getAssetUsdPriceQuery,
  [QueryKey.TECTONIC_PARTNER_TOKEN_PRICE_IN_USD]: getPartnerTokenUsdPriceQuery,
  [QueryKey.RISK_DAO_BAD_DEBT]: getBadDebtQuery,
  [QueryKey.VVS_SWAP_TRADE_DATA]: getVvsSwapTradeData,
  [QueryKey.WOWMAX_SWAP_TRADE_DATA]: getWowmaxSwapTradeData,
  [QueryKey.BOOST_MARKET]: boostMarketsQuery,
  [QueryKey.REMOVE_BOOST_MARKET]: removeBoostMarkets,
  [QueryKey.REPLACE_BOOST_MARKET]: replaceBoostMarkets,
  [QueryKey.CALCULATE_BOOST_MULTIPLIER]: calculateBoostMultiplier,
  [QueryKey.GET_USER_BOOST_INFO]: getUserInfo,
  [QueryKey.GET_USER_BOOST_COLLECTIONS]: getUserBoostCollections,
  [QueryKey.GET_UNCLAIM_TONIC_REWARDS]: getUnClaimTonic,
  [QueryKey.GET_TONIC_MARKETSTATES]: getTonicMarketStates,
  [QueryKey.GET_XTONIC_BOOST_DAILY_DISTRIBUTE_RATE]:
    getXtonicBoostDailyDistributeRate,
  [QueryKey.GET_TTOKEN_SUPPLY_AMOUNT_USER]: getTTokenSupplyAmountOfUser,
  [QueryKey.GET_TTOKEN_BORROW_AMOUNT_USER]: getTTokenBorrowAmountOfUser,
};

export const getQuery = <T extends QueryKey>(key: T) => get(queries, key);
