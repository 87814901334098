import { TectonicSDK } from '@cronos-labs/tectonic-sdk';
import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import { addSeconds } from 'date-fns';
import { BigNumber } from 'ethers';
import { useEffect, useState, useMemo } from 'react';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import Spinner from '@components/Spinner';
import { X_TONIC_DECIMALS } from '@config/constants';
import { withHook } from '@hooks/withHook';
import { formatLockEndDate } from '@lib/dates';
import { parseInputAmountToBN } from '@lib/units';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { useWallet } from '@providers/WalletProvider';
import { TxStatus } from '@types';
import useIsMobile from '@hooks/useIsMobile';

import { getLockPeriodName } from './LockingPeriodCard';
import { periodInSeconds, poolToPeriod } from './periods';
import PickAmountScreen from './PickAmountScreen';
import PickLockingPeriodScreen from './PickLockingPeriodScreen';
import { LockingPeriod, LockingPeriodDetails } from './types';

type LockScreen =
  | 'loading'
  | 'enable'
  | 'pickPeriod'
  | 'pickAmount'
  | 'confirm';
export interface LockDepositModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  screen: LockScreen;
  lockingPeriod?: LockingPeriod;
  /** APR 1 = 100% */
  apr?: number;
  multiplier?: string;
  lockEndDate?: string;
  amount: string;
  amountValid: boolean;
  isOpen: boolean;
  xTonicBalance: BigNumber;
  lockingPeriods: LockingPeriodDetails[];
  poolId?: number;

  onLockingPeriodChange: (period: LockingPeriod) => void;
  onClose: () => void;
  onLockingPeriodSelect: () => void;
  onAmountChange: (inputAmount: string) => void;
  onAmountPick: () => void;
  onEnable: () => void;
  onLock: (poolId: number, amount: string) => void;
}
function getTitle(
  screen: LockScreen,
  hasXTonic: boolean,
  period?: LockingPeriod
) {
  if (screen === 'confirm') {
    return 'Confirm Deposit';
  }
  if (screen === 'pickAmount' && period && hasXTonic) {
    return `${getLockPeriodName(period)} Lock Vault`;
  }
  if (screen !== 'loading' && !hasXTonic) {
    return 'No xTONIC found';
  }
  if (screen === 'pickPeriod') {
    return 'Select Locking Period';
  }
  return 'Create New Vault Loading...';
}

export function LockDepositModalUi({
  lockingPeriod,
  xTonicBalance,
  screen,
  apr,
  multiplier,
  amount,
  amountValid,
  lockEndDate,
  isOpen,
  lockingPeriods,
  transactionErrorMessage,
  transactionExplorerHref,
  poolId,
  onClose,
  onLockingPeriodChange,
  onLockingPeriodSelect,
  onAmountChange,
  onAmountPick,
  onEnable,
  onLock,
  ...props
}: LockDepositModalProps) {
  const isMobile = useIsMobile();
  const hasXTonic = xTonicBalance.gt(0);
  return (
    <BaseTransactionModal
      isOpen={isOpen}
      onClose={onClose}
      renderTitle={(status) => {
        if (screen === 'enable') {
          return 'Enable Vaults';
        }
        if (!status) {
          return getTitle(screen, hasXTonic, lockingPeriod);
        }
        switch (status) {
          case TxStatus.pending:
          case TxStatus.awaiting_confirmation:
            return 'Lock xTONIC';
          case TxStatus.confirmed:
            return 'xTONIC Deposit Completed';
          case TxStatus.failed:
            return 'Failed';
        }
      }}
      isDrawer
      isHideCloseIcon={isMobile}
      isShowMobileSliderClose={isMobile}
      onBack={
        isMobile && screen === 'confirm' ? onLockingPeriodSelect : undefined
      }
      transactionExplorerHref={transactionExplorerHref}
      renderTransactionStatus={
        screen === 'enable'
          ? undefined
          : (txStatus) => {
              const isTransactionAwaitingConfirmation =
                txStatus === TxStatus.awaiting_confirmation;
              const isTransactionPending = txStatus === TxStatus.pending;
              const isTransactionConfirmed = txStatus === TxStatus.confirmed;
              const showSpinner =
                isTransactionAwaitingConfirmation || isTransactionPending;
              const showViewInExplorerButton = Boolean(
                (isTransactionPending || isTransactionConfirmed) &&
                  transactionExplorerHref
              );

              return (
                <div className="text-center">
                  {showSpinner && (
                    <Icon.Spinner className="inline-block h-8 w-8 animate-spin" />
                  )}
                  {isTransactionAwaitingConfirmation && (
                    <Text className="mt-4">
                      Confirm the transaction in your wallet
                    </Text>
                  )}
                  {isTransactionPending && (
                    <Text className="mt-4">
                      Confirming your deposit, please wait...
                    </Text>
                  )}
                  {isTransactionConfirmed && (
                    <>
                      <Icon.Check className="inline-block h-8 w-8" />
                      <Text className="mt-4">
                        {amount} xTONIC has been locked.
                      </Text>
                    </>
                  )}
                  {txStatus === 'failed' && (
                    <>
                      <Icon.Info className="inline-block h-8 w-8 text-rainbowRed" />
                      <Text className="mt-4">
                        {transactionErrorMessage || 'Unknown error'}
                      </Text>
                    </>
                  )}
                  {showViewInExplorerButton && (
                    <div className="pt-1">
                      <a
                        className="text-yellowPrimary"
                        href={transactionExplorerHref as string}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        View on explorer
                      </a>
                      <Button className="mt-3 w-full" onClick={onClose}>
                        Done
                      </Button>
                    </div>
                  )}
                </div>
              );
            }
      }
      variant={isMobile ? 'wallet' : 'vault'}
      {...props}
    >
      {screen === 'loading' && (
        <div className="flex h-[300px] items-center justify-center">
          <Spinner />
        </div>
      )}
      {screen === 'enable' && (
        <div>
          <Text>
            To stake your xTONIC in Vaults, you need to enable it first.
          </Text>
          <Button className="mt-2 w-full" onClick={onEnable}>
            Enable Vaults
          </Button>
        </div>
      )}
      {screen === 'pickPeriod' && (
        <PickLockingPeriodScreen
          hasXTonic={hasXTonic}
          selectedPeriod={lockingPeriod}
          lockingPeriods={lockingPeriods}
          onPeriodChange={onLockingPeriodChange}
          onPeriodSelect={onLockingPeriodSelect}
        />
      )}
      {(screen === 'pickAmount' || screen === 'confirm') && (
        <PickAmountScreen
          isConfirm={screen === 'confirm'}
          xTonicBalance={xTonicBalance}
          apr={apr || 0}
          multiplier={multiplier || '-'}
          lockEndDate={lockEndDate || '-'}
          amount={amount}
          amountValid={amountValid}
          onAmountChange={onAmountChange}
          onAmountPick={onAmountPick}
          onConfirm={() => poolId !== undefined && onLock(poolId, amount)}
          onClose={onClose}
        />
      )}
    </BaseTransactionModal>
  );
}
const getData = async (sdk: TectonicSDK, account: string) => {
  const xTonicAddress = sdk.Staking.getXTonic().address;
  const [xTonicBalance, poolsFromSdk, approved] = await Promise.all([
    // TODO load more needed data from sdk once available
    sdk.ERC20.balanceOf(xTonicAddress, account),
    sdk.Vault.getPools(),
    sdk.Vault.isApproved(account),
  ]);
  return {
    xTonicBalance,
    pools: poolsFromSdk.map((p) => ({ ...p, apy: p.apy / 100 })),
    approved,
  };
};
const useLockDepositModalData = () => {
  const sdk = useTectonicSdk();
  const { currentAccount } = useWallet();
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<Awaited<ReturnType<typeof getData>> | null>(
    null
  );

  useEffect(() => {
    if (!sdk || !currentAccount) {
      return;
    }
    getData(sdk, currentAccount).then((d) => {
      setLoaded(true);
      setData(d);
    });
  }, [sdk, currentAccount]);
  return { data, loaded };
};
const noPeriods: LockingPeriodDetails[] = [];
const getScreen = (
  _screen: LockScreen,
  loaded: boolean,
  approved: boolean
): LockScreen => {
  if (!loaded) {
    return 'loading';
  }
  if (!approved) {
    return 'enable';
  }
  return _screen;
};
const LockDepositModal = withHook(
  ({
    amount,
    preselectedPeriod,
  }: {
    amount: string;
    preselectedPeriod?: LockingPeriod;
  }) => {
    const { loaded, data } = useLockDepositModalData();
    const poolPeriods = data?.pools
      .map(poolToPeriod)
      .filter((p?: LockingPeriodDetails) => !!p) as
      | LockingPeriodDetails[]
      | undefined;
    const periods = poolPeriods || noPeriods;
    const [_screen, setScreen] = useState<LockScreen>(
      preselectedPeriod ? 'pickAmount' : 'pickPeriod'
    );
    const screen = getScreen(_screen, loaded, !!data?.approved);
    const [lockingPeriod, setLockingPeriod] = useState<
      LockingPeriod | undefined
    >(preselectedPeriod);

    const periodDetails = useMemo(
      () =>
        lockingPeriod !== undefined
          ? periods?.find((p) => p.period === lockingPeriod)
          : undefined,
      [lockingPeriod, periods]
    );
    const apr = periodDetails?.apr || 0;
    const multiplier = periodDetails?.multiplier || '-';
    const poolId = periodDetails?.poolId;
    const lockEndDate = useMemo(() => {
      if (!lockingPeriod) {
        return;
      }
      const seconds = periodInSeconds[lockingPeriod];
      const date = addSeconds(new Date(), seconds);
      return formatLockEndDate(date);
    }, [lockingPeriod]);
    const onLockingPeriodSelect = () => {
      if (!lockingPeriod) {
        return;
      }
      setScreen('pickAmount');
    };
    const onAmountPick = () => {
      if (parseInputAmountToBN(amount, X_TONIC_DECIMALS).isZero()) {
        return;
      }
      setScreen('confirm');
    };
    return {
      screen,
      lockingPeriod,
      apr,
      multiplier,
      lockingPeriods: periods,
      onLockingPeriodChange: setLockingPeriod,
      onLockingPeriodSelect,
      onAmountPick,
      xTonicBalance: data?.xTonicBalance || BigNumber.from(0),
      lockEndDate,
      poolId,
      amountValid: !parseInputAmountToBN(
        amount || '0',
        X_TONIC_DECIMALS
      ).isZero(),
    };
  }
)(LockDepositModalUi);

export default LockDepositModal;
