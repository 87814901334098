function getAssetLogoUri(assetSymbol: string): string | null {
  switch (assetSymbol.toUpperCase()) {
    case 'CRO':
      return '/images/cro-white.svg';
    case 'DAI':
      return '/images/DAI.svg';
    case 'WETH':
    case 'ETH':
      return '/images/eth.svg';
    case 'SHIB':
      return '/images/shib.svg';
    case 'TONIC':
      return '/images/tonic.svg';
    case 'TUSD':
      return '/images/TUSD.svg';
    case 'USDC':
      return '/images/USDC.svg';
    case 'USDT':
      return '/images/USDT.svg';
    case 'VVS':
      return '/images/vvs.svg';
    case 'BTC':
    case 'WBTC':
      return '/images/WBTC.svg';
    case 'FER':
      return '/images/FER.svg';
    case 'LCRO':
      return '/images/lcro.svg';
    case 'ATOM':
      return '/images/ATOM.svg';
    case 'LATOM':
      return '/images/LATOM.svg';
    case 'XRP':
      return '/images/XRP.svg';
    case 'ADA':
      return '/images/ADA.svg';
    case 'VNO':
      return '/images/VNO.svg';
    case 'LTC':
      return '/images/LTC.svg';
    case 'FUL':
      return '/images/FUL.svg';
    case 'BCRO':
      return '/images/bCRO.svg';
    case 'CDCETH':
      return '/images/CDCETH.svg';
    case 'USC':
      return '/images/USC.svg';
    case 'CDCBTC':
      return '/images/cdcBTC.svg';
    default:
      return null;
  }
}

export default getAssetLogoUri;
