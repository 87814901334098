import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk';

import { AppThunk } from '@types';
import { TectonicAssetPricer } from '@hooks/useTectonicAssetPricer';
import { notify } from '@lib/bugsnag';

export interface UsdPricesSliceState {
  bySymbol: { [key: string]: string | undefined };
  isRefreshingAll: boolean;
  isRefreshingBySymbol: { [key: string]: boolean | undefined };
}

export const initialState: UsdPricesSliceState = {
  bySymbol: {},
  isRefreshingAll: false,
  isRefreshingBySymbol: {},
};

const usdPricesSlice = createSlice({
  initialState,
  name: 'usdPrices',
  reducers: {
    refreshAllUsdPricesPending(state): void {
      state.isRefreshingAll = true;
    },
    refreshAllUsdPricesFulfilled(
      state,
      action: PayloadAction<{ assets: TectonicAsset[]; usdPrices: string[] }>
    ): void {
      state.isRefreshingAll = false;
      state.bySymbol = action.payload.assets.reduce(
        (previous, current, index) => {
          const assetUsdPrice = action.payload.usdPrices[index];

          if (!assetUsdPrice) {
            return previous;
          }

          return { ...previous, [current.symbol]: assetUsdPrice };
        },
        state.bySymbol
      );
    },
    refreshAllUsdPricesRejected(state): void {
      state.isRefreshingAll = false;
    },
    refreshUsdPricePending(
      state,
      action: PayloadAction<{ asset: TectonicAsset }>
    ): void {
      state.isRefreshingBySymbol[action.payload.asset.symbol] = true;
    },
    refreshUsdPriceFulfilled(
      state,
      action: PayloadAction<{ asset: TectonicAsset; usdPrice: string }>
    ): void {
      state.isRefreshingBySymbol[action.payload.asset.symbol] = false;
      state.bySymbol[action.payload.asset.symbol] = action.payload.usdPrice;
    },
    refreshUsdPriceRejected(
      state,
      action: PayloadAction<{ asset: TectonicAsset }>
    ): void {
      state.isRefreshingBySymbol[action.payload.asset.symbol] = false;
    },
  },
});

const {
  refreshAllUsdPricesFulfilled,
  refreshAllUsdPricesPending,
  refreshAllUsdPricesRejected,
  refreshUsdPriceFulfilled,
  refreshUsdPricePending,
  refreshUsdPriceRejected,
} = usdPricesSlice.actions;

// Deliberately swallowing errors here, so the setInterval function does not need to deal with it
export function refreshAllAssetsUsdPrice(
  assetPricer: TectonicAssetPricer,
  assets: TectonicAsset[]
): AppThunk<Promise<void>> {
  return async function refreshAssetsUsdPricesThunk(
    dispatch,
    getState
  ): Promise<void> {
    const state = getState();

    if (!state.usdPrices.isRefreshingAll) {
      try {
        dispatch(refreshAllUsdPricesPending());

        const usdPrices = await Promise.all(
          assets.map((asset) => assetPricer.getUsdPrice(asset))
        );

        dispatch(
          refreshAllUsdPricesFulfilled({
            assets,
            usdPrices: usdPrices.map((usdPrice): string => {
              if (usdPrice) {
                return usdPrice.toString();
              }

              return '';
            }),
          })
        );
      } catch (error) {
        dispatch(refreshAllUsdPricesRejected());
        notify(error);
      }
    }
  };
}

export function refreshAssetUsdPrice(
  assetPricer: TectonicAssetPricer,
  asset: TectonicAsset
): AppThunk<Promise<void>> {
  return async function refreshAssetUsdPriceThunk(
    dispatch,
    getState
  ): Promise<void> {
    const state = getState();

    if (
      !state.usdPrices.isRefreshingBySymbol[asset.symbol] &&
      !state.usdPrices.isRefreshingAll
    ) {
      try {
        dispatch(refreshUsdPricePending({ asset }));

        const assetUsdPrice = await assetPricer.getUsdPrice(asset);

        if (assetUsdPrice) {
          dispatch(
            refreshUsdPriceFulfilled({
              asset,
              usdPrice: assetUsdPrice.toString(),
            })
          );
        } else {
          dispatch(refreshUsdPriceRejected({ asset }));
        }
      } catch (error) {
        dispatch(refreshUsdPriceRejected({ asset }));
        notify(error);
      }
    }
  };
}

export function getAssetUsdPrice(
  assetPricer: TectonicAssetPricer,
  asset: TectonicAsset
): AppThunk<Promise<void>> {
  return async function getAssetUsdPriceThunk(
    dispatch,
    getState
  ): Promise<void> {
    const state = getState();
    const cachedAssetUsdPrice = state.usdPrices.bySymbol[asset.symbol];

    if (!cachedAssetUsdPrice) {
      dispatch(refreshAssetUsdPrice(assetPricer, asset));
    }
  };
}

export default usdPricesSlice.reducer;
