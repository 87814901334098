import { useScreen, Icon, Text, Button } from '@tectonicfi/tectonic-ui-kit';

import { WHITEPAPER_URL } from '@config/base';
import { PATHNAME } from '@components/AppLayout';

function Hero(): JSX.Element {
  const screen = useScreen();
  return (
    <div
      style={{ maxWidth: '583px' }}
      className="flex flex-col items-center justify-center"
    >
      <Text
        as="h1"
        variant={screen === 'mobile' ? 'heading2' : 'heading1'}
        className="mb-3 inline-block text-center"
      >
        <span className="block">A tectonic shift in</span>
        <span className="block">lending & borrowing</span>
      </Text>
      <Text className="mb-3 text-center">
        Tectonic is a cross-chain money market for earning passive yield and
        accessing instant backed loans
      </Text>
      <div className="mb-3 flex w-full items-center justify-center mobile:flex-col">
        <Button
          as="a"
          className="desktop:mr-2 tablet:mr-2 mobile:mb-2"
          href={PATHNAME.markets}
        >
          Enter App
        </Button>
        <Button
          as="a"
          variant="outlined"
          href={WHITEPAPER_URL}
          rel="noopener noreferrer"
          target="_blank"
        >
          Whitepaper
        </Button>
      </div>
      <div className="flex flex-1 flex-col justify-start">
        <Icon.PoweredByCronos className="h-4 w-[114px]" />
      </div>
    </div>
  );
}

export default Hero;
