import { StackView, Text } from '@tectonicfi/tectonic-ui-kit';

interface Props {
  className?: string;
}

function FrequentlyAskedQuestions({ className = 'max-w-xl' }: Props) {
  return (
    <StackView direction="vertical" spacing={2} className={className}>
      <Text as="h2" variant="heading2">
        Frequently asked questions
      </Text>
      <Text as="h3" variant="semiBold">
        What is Tectonic?
      </Text>
      <Text>
        Tectonic is a decentralized non-custodial algorithmic money market
        protocol. Users can deposit assets to earn passive income or borrow
        funds to unlock liquidity in their assets.
      </Text>
      <Text as="h3" variant="semiBold">
        How does Tectonic work?
      </Text>
      <Text>
        {
          "Funds deposited by users are provided as liquidity to borrowers, who may borrow at variable interest rates. Tectonic's smart contracts adjust these rates based on each market's utilization rates."
        }
      </Text>
      <Text as="h3" variant="semiBold">
        What is TONIC?
      </Text>
      <Text>
        {
          "TONIC is Tectonic's protocol token with two key use cases: governance and staking into the Community Insurance Pool to secure the protocol and earn more rewards."
        }
      </Text>
    </StackView>
  );
}

export default FrequentlyAskedQuestions;
