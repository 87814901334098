import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import SupportedAssetIcon from '@components/SupportedAssetIcon';
import { TxStatus } from '@types';

export interface ClaimBountyModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  asset: TectonicAsset | null;
  formattedAmount: string;
  onClaimBounty(): void;
}

function ClaimBountyModal({
  asset,
  onClaimBounty,
  onClose,
  transactionErrorMessage,
  transactionExplorerHref,
  formattedAmount,
  ...props
}: ClaimBountyModalProps): JSX.Element {
  return (
    <BaseTransactionModal
      onClose={onClose}
      renderTitle={(txStatus): string => {
        if (!txStatus || txStatus === TxStatus.pending) {
          return 'Claim Conversion Bounty';
        }

        if (txStatus === TxStatus.awaiting_confirmation) {
          return 'Confirm transaction';
        }

        if (txStatus === TxStatus.confirmed) {
          return 'Success';
        }

        return 'Failed';
      }}
      renderTransactionStatus={(txStatus) => {
        const isTransactionAwaitingConfirmation =
          txStatus === TxStatus.awaiting_confirmation;
        const isTransactionPending = txStatus === TxStatus.pending;
        const isTransactionConfirmed = txStatus === TxStatus.confirmed;
        const showSpinner =
          isTransactionAwaitingConfirmation || isTransactionPending;
        const showViewInExplorerButton = Boolean(
          (isTransactionPending || isTransactionConfirmed) &&
            transactionExplorerHref
        );

        return (
          <div className="text-center">
            {showSpinner && (
              <Icon.Spinner className="inline-block h-8 w-8 animate-spin" />
            )}
            {isTransactionAwaitingConfirmation && (
              <Text className="mt-4">
                Confirm the transaction in your wallet
              </Text>
            )}
            {isTransactionConfirmed && (
              <>
                <Icon.Check className="inline-block h-8 w-8" />
                <Text className="mt-4">
                  The bounty has been deposited to your wallet.
                </Text>
                <Button className="mt-3 w-full" onClick={onClose}>
                  Done
                </Button>
              </>
            )}
            {txStatus === 'failed' && (
              <Text>{transactionErrorMessage || 'Unknown error'}</Text>
            )}
            {showViewInExplorerButton && (
              <Button
                as="a"
                className="mt-3 w-full"
                href={transactionExplorerHref as string}
                rel="noopener noreferrer"
                target="_blank"
              >
                View on explorer
              </Button>
            )}
          </div>
        );
      }}
      transactionErrorMessage={transactionErrorMessage}
      transactionExplorerHref={transactionExplorerHref}
      {...props}
    >
      <div className="my-3 flex flex-col items-center">
        {asset && <SupportedAssetIcon asset={asset} className="mb-3 h-8 w-8" />}
        <Text>
          You are about to claim the conversion bounty of {formattedAmount}{' '}
          TONIC.
        </Text>
      </div>
      <Button className="w-full" onClick={onClaimBounty}>
        Confirm
      </Button>
    </BaseTransactionModal>
  );
}

export default ClaimBountyModal;
