import { ReactNode, createContext, memo, Context, useContext } from 'react';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';

import { PoolType } from '@config/base';

import {
  contextValue,
  contextDeFiPoolValue,
  contextVenoPoolValue,
} from './utils';

interface SupportedAssetsContextValue {
  list: TectonicAsset[];
  map: Record<string, TectonicAsset>;
  loaded: boolean;
}

interface SupportedAssetsProviderProps {
  children: ReactNode;
}

export const SupportedAssetsContext =
  createContext<SupportedAssetsContextValue>(contextValue);

export const SupportedAssetsDeFiPoolContext =
  createContext<SupportedAssetsContextValue>(contextDeFiPoolValue);

export const SupportedAssetsVenoPoolContext =
  createContext<SupportedAssetsContextValue>(contextVenoPoolValue);

function SupportedAssetsProvider({
  children,
}: SupportedAssetsProviderProps): JSX.Element {
  return (
    <SupportedAssetsContext.Provider value={contextValue}>
      <SupportedAssetsDeFiPoolContext.Provider value={contextDeFiPoolValue}>
        <SupportedAssetsVenoPoolContext.Provider value={contextVenoPoolValue}>
          {children}
        </SupportedAssetsVenoPoolContext.Provider>
      </SupportedAssetsDeFiPoolContext.Provider>
    </SupportedAssetsContext.Provider>
  );
}

export function getSupportedAssetsContext(
  poolType: Undefined<PoolType>
): Context<SupportedAssetsContextValue> {
  switch (poolType) {
    case 'DEFI':
      return SupportedAssetsDeFiPoolContext;
    case 'VENO':
      return SupportedAssetsVenoPoolContext;
    case 'MAIN':
    default:
      return SupportedAssetsContext;
  }
}

export const useSupportedAssets = (
  poolType?: PoolType
): SupportedAssetsContextValue => {
  const context = getSupportedAssetsContext(poolType);
  return useContext(context);
};

export default memo(SupportedAssetsProvider);
