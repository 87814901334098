import { useEffect } from 'react';
import {
  BackButton,
  Button,
  Dropdown,
  Icon,
  Text,
  Tooltip,
} from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';
import { TectonicVaultStakeInfo } from '@cronos-labs/tectonic-sdk/dist/types';
import { useCallback, useMemo, useState } from 'react';
import partial from 'lodash/partial';

import { TxState } from 'hooks/useGetTxStatus';
import { TxStatus } from '@types';
import { useSingeNFTMultiplier } from '@hooks/useStakeNFT';
import useNFT from '@components/VaultsPageView/StakingNFT/useNFT';
import {
  useGetUserNftBoostMultiplier,
  useStakedNFTTokenIds,
  useStakeNFTInfos,
} from '@hooks/useStakeNFT';
import {
  formatPercent,
  formatRateToPercent,
  formatXTonic,
  formatMantissa,
} from '@lib/units';
import { secondsToPeriod } from '@components/LockDepositModal/periods';
import { getLockPeriodName } from '@components/LockDepositModal/LockingPeriodCard';
import { LockingPeriod } from '@components/LockDepositModal/types';
import YourDepositsModal from '@components/YourDepositsModal/YourDepositsModal';
import {
  StakingNFTCard,
  StakedImage,
  NFTDetailModal,
} from '@components/VaultsPageView/StakingNFT';
import { unlockDateToString } from '@lib/dates';

import { PoolOfUser } from '../VaultsBlock/types';
import {
  DisplayValue,
  SortOptions,
  SortValue,
  sortDeposits,
} from '../DepositDropdown';
import BoostedValue from '../StakingNFT/BoostedValue';
import { NFT } from '../StakingNFT/useNFTMeta';

export interface VaultInfoProps {
  pool: PoolOfUser;
  deposits: TectonicVaultStakeInfo[];
  txState: Null<TxState>;
  onBackToOverview: () => void;
  onDepositMoreClick: (period: LockingPeriod) => void;
  onUpgradeLockingPeriodClick: (
    poolId: number,
    depositsForPeriod: TectonicVaultStakeInfo[]
  ) => void;
  onWithdrawClick: (
    poolId: number,
    unlockedDeposits: TectonicVaultStakeInfo[]
  ) => void;
  onOpenNFTStakingModal: (poolId: number, hasBoostNFTs: boolean) => void;
}

function VaultInfo({
  pool,
  deposits,
  onBackToOverview,
  onUpgradeLockingPeriodClick,
  onDepositMoreClick,
  onWithdrawClick,
  onOpenNFTStakingModal,
  txState,
}: VaultInfoProps): JSX.Element {
  const [sortBy, setSortBy] = useState<SortValue>('recently-locked');
  const [seeAllModalOpen, setSeeAllModalOpen] = useState(false);
  const [isOpenNFTDetailModal, setOpenNFTDetailModal] =
    useState<boolean>(false);
  const [openedNFTInfo, setOpenedNFTInfo] = useState<Null<NFT>>(null);

  const now = new Date().getTime();
  const { visibleDepositsOfPool, lockEndTimes, allDepositsOfPool } =
    useMemo(() => {
      let vaultPools = deposits.filter(
        (deposit) => deposit.pool.lockPeriod === pool.lockPeriod
      );
      const allDeposits = [...vaultPools];
      vaultPools = sortDeposits(vaultPools, sortBy);
      vaultPools.splice(4);

      const endDates = vaultPools.map((v) => v.lockEndDate);
      return {
        visibleDepositsOfPool: vaultPools,
        lockEndTimes: endDates,
        allDepositsOfPool: allDeposits,
      };
    }, [pool?.lockPeriod, deposits, sortBy]);
  const remainingTimeStrings = useMemo(() => {
    return lockEndTimes.map(unlockDateToString);
  }, [lockEndTimes]);
  const onSeeAllClick = () => setSeeAllModalOpen(true);

  const { data } = useStakedNFTTokenIds(pool?.poolId);
  const { data: multiplierData = 1 } = useGetUserNftBoostMultiplier(
    pool.poolId
  );

  const {
    loading: nftLoading,
    stakedNfts,
    userNfts,
    refetchNfts,
  } = useNFT(pool.poolId);
  const hasBoostNFTs = userNfts.length > 0 || stakedNfts.length > 0;

  const hasStakedNFT = multiplierData > 1;

  useEffect(() => {
    if (txState?.status === TxStatus.confirmed) {
      refetchNfts();
    }
  }, [txState, refetchNfts]);

  const { data: nftMultiplierData } = useSingeNFTMultiplier(openedNFTInfo?.id);
  const { data: stakeNFTinfosData } = useStakeNFTInfos(openedNFTInfo?.id);

  const handleUpgradeLockingPeriodClick = () => {
    onUpgradeLockingPeriodClick(pool.poolId, allDepositsOfPool);
  };

  const handleWithdrawClick = useCallback(() => {
    onWithdrawClick(
      pool.poolId,
      allDepositsOfPool.filter((d) => d.lockEndDate.getTime() < now)
    );
  }, [onWithdrawClick, pool, allDepositsOfPool, now]);

  return (
    <>
      <div
        className={clsx(
          'flex min-h-[589px] flex-col rounded-md border-1 border-yellowBorder bg-[right_24px_top_24px] bg-no-repeat p-3 text-white mix-blend-lighten mobile:border-0 mobile:bg-transparent mobile:p-0',
          {
            'bg-vault-card-1-month bg-[length:26%]': pool.poolId === 0,
            'bg-vault-card-6-month bg-[length:28%]': pool.poolId === 1,
            'bg-vault-card-12-month bg-[length:15%]': pool.poolId === 2,
            'bg-vault-card-48-month bg-[length:22%]': pool.poolId === 4,
          }
        )}
      >
        <BackButton onClick={onBackToOverview}>Back to vaults</BackButton>
        <div className="mt-3 flex w-full flex-col justify-between gap-[4px] leading-5">
          <div>
            <Text variant="heading3">
              {getLockPeriodName(secondsToPeriod[pool.lockPeriod])}
            </Text>
          </div>
          <div>
            <span className="font-semibold">
              {formatXTonic(pool.totalStaked)}
            </span>
            <span> xTONIC</span>
          </div>
        </div>
        <div
          className={clsx(
            'mt-[6px] w-fit rounded border-1 border-green px-2 py-[2px] text-small text-green mobile:hidden',
            {
              hidden: !data?.length,
            }
          )}
        >
          Boosted
        </div>
        <div className="mt-2 flex w-full flex-row justify-between text-mediumSmall leading-5">
          <div>APR</div>
          <BoostedValue
            value={formatPercent(formatRateToPercent(pool.apy))}
            boostedValue={
              hasStakedNFT
                ? formatPercent(formatRateToPercent(pool.apy * multiplierData))
                : undefined
            }
          />
        </div>
        <div className="mt-1 flex w-full flex-row justify-between text-mediumSmall leading-5">
          <div>Multiplier</div>
          <BoostedValue
            value={`${formatMantissa(pool.multiplier)}x`}
            boostedValue={
              hasStakedNFT
                ? `${formatMantissa(pool.multiplier * multiplierData)}x`
                : undefined
            }
          />
        </div>
        <div className="mt-1 flex w-full flex-row justify-between text-mediumSmall leading-5">
          <div>Number of Deposits</div>
          <div>
            <span className="font-semibold">{pool.numberOfStakes}</span>
          </div>
        </div>
        <div
          className={clsx(
            'mt-1 flex w-full flex-row items-end justify-center text-mediumSmall leading-5  mobile:hidden',
            !allDepositsOfPool.length && 'hidden'
          )}
        >
          <div className="text-[16px] mobile:text-[14px]">Sort by:</div>
          <Dropdown
            onChange={(value) => setSortBy(value)}
            value={sortBy}
            className="ml-1"
            displayValue={<DisplayValue />}
          >
            <SortOptions />
          </Dropdown>
        </div>
        {visibleDepositsOfPool.map((deposit, i) => {
          const isUnlocked = deposit.lockEndDate.getTime() < now;
          return (
            <div
              className={clsx(
                isUnlocked && 'text-green',
                'mt-1 flex w-full flex-row justify-between text-mediumSmall leading-5 mobile:mt-2'
              )}
              key={deposit.stakeId}
            >
              <div className="flex flex-row items-center">
                {isUnlocked ? (
                  <Icon.Check
                    className="mr-0.5 inline-block"
                    height={23}
                    width={23}
                  />
                ) : (
                  <Icon.Lock
                    className="mr-0.5 inline-block"
                    height={16}
                    width={16}
                  />
                )}
                <div
                  className={clsx(
                    'pl-1 font-normal',
                    isUnlocked && 'text-green'
                  )}
                >
                  {remainingTimeStrings[i]}
                </div>
              </div>
              <div
                className={clsx('font-semibold', isUnlocked && 'text-green')}
              >
                {formatXTonic(deposit.amount)} xTONIC
              </div>
            </div>
          );
        })}
        <div
          className={clsx(
            'mt-1 flex w-full flex-row mobile:mt-3',
            !allDepositsOfPool.length && 'hidden'
          )}
        >
          {allDepositsOfPool.length >= 5 && (
            <div
              className="cursor-pointer text-[14px] font-semibold text-rainbowYellow"
              onClick={() => allDepositsOfPool.length && onSeeAllClick()}
            >
              See all
            </div>
          )}
        </div>
        <span className="mt-3 mb-4 flex flex-col gap-y-1">
          <div className="mt-1 flex w-full flex-row justify-between text-mediumSmall leading-5">
            <div>NFT staked</div>
            <div>
              <span className="font-semibold">{data?.length ?? 0}</span>
            </div>
          </div>

          <Text className="text-mediumSmall" variant="semiTransparent">
            Boost {getLockPeriodName(secondsToPeriod[pool.lockPeriod])} Vault
            APR and multiplier by staking your Cronos Cruisers
          </Text>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://tectonic.gitbook.io/docs/guides/using-maturity-lock-vaults/boosting-vault-rewards-with-nfts"
          >
            <div className="mb-1 w-fit cursor-pointer text-mediumSmall font-semibold text-yellow">
              Learn more
            </div>
          </a>
          <div className="w-full overflow-y-hidden p-0.5">
            <span className="flex w-fit gap-x-2 pb-1">
              <StakingNFTCard
                hasStakedNFT={hasStakedNFT}
                size="lg"
                disabled={pool.numberOfStakes === 0 || nftLoading}
                onClick={() => onOpenNFTStakingModal(pool.poolId, hasBoostNFTs)}
              />

              {pool.numberOfStakes > 0 &&
                !nftLoading &&
                stakedNfts.map((nft) => (
                  <StakedImage
                    key={nft.id}
                    nftRarity={nft.rarity}
                    src={nft.image}
                    onClick={() => {
                      setOpenNFTDetailModal(true);
                      setOpenedNFTInfo(nft);
                    }}
                  />
                ))}
            </span>
          </div>
        </span>
        <div className="mt-auto mobile:mt-5">
          <div className="flex w-full flex-row items-center justify-between text-mediumSmall">
            <div>
              <div className="flex flex-col gap-1">
                <div className="flex flex-row">
                  <Text>Total xTONIC unlocked</Text>
                  <Tooltip
                    className="inline mobile:hidden"
                    message="This is the amount of xTONIC that is already unlocked and can be withdrawn."
                  >
                    <Icon.Question className="ml-0.5 inline h-3 w-3 align-top" />
                  </Tooltip>
                </div>
                <div className="font-semibold">
                  {pool.unlockedStaked.gt(0)
                    ? formatXTonic(pool.unlockedStaked)
                    : '-'}
                </div>
              </div>
            </div>
            <div>
              <Button
                onClick={handleWithdrawClick}
                className="bg-transparent"
                theme="pink"
                size="small"
                disabled={pool.unlockedStaked.lte(0)}
                variant="outlined"
              >
                Withdraw
              </Button>
            </div>
          </div>
          <div className="mt-2 flex w-full flex-row items-center justify-between gap-2 mobile:mt-3 mobile:flex-col">
            <Button
              onClick={() =>
                onDepositMoreClick(secondsToPeriod[pool.lockPeriod])
              }
              className="w-1/2 bg-transparent mobile:w-full"
              theme="yellow"
              size="small"
              variant="outlined"
            >
              Deposit More
            </Button>
            <Button
              onClick={handleUpgradeLockingPeriodClick}
              className="w-1/2 border-2 border-yellowPrimary disabled:border-0 mobile:w-full"
              theme="yellow"
              size="small"
              variant="contained"
              disabled={pool.hasLongestPeriod || pool.numberOfStakes === 0}
            >
              Upgrade Locking Period
            </Button>
          </div>
        </div>
      </div>
      {seeAllModalOpen && (
        <YourDepositsModal
          deposits={allDepositsOfPool.map((deposit) => ({
            id: deposit.stakeId,
            unlockDate: deposit.lockEndDate,
            xTonicAmount: deposit.amount,
          }))}
          onClose={partial(setSeeAllModalOpen, false)}
        />
      )}
      <NFTDetailModal
        data={openedNFTInfo}
        isOpen={isOpenNFTDetailModal}
        nftMultiplierData={nftMultiplierData}
        stakedSince={stakeNFTinfosData?.[0]?.stakedSince}
        onClose={partial(setOpenNFTDetailModal, false)}
      />
    </>
  );
}

export default VaultInfo;
