import { useMemo, useState, useCallback, useEffect } from 'react';
import { BigNumber, utils } from 'ethers';
import clsx from 'clsx';
import { AssetIcon, Button, Text, Icon } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk';

import { Mode } from '@components/MarketsPageView/types';
import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal/BaseTransactionModal';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import Spinner from '@components/Spinner/Spinner';
import EnableErc20Content from '@components/EnableErc20Content/EnableErc20Content';
import useUserMetrics from '@hooks/useUserMetrics';
import useWalletBalance from '@hooks/useWalletBalance';
import useIsMobile from '@hooks/useIsMobile';
import { isAmountLessThanAllowance } from '@lib/utils';
import EnhancedAmountInputSymbol from '@components/EnhancedAmountInputSymbol/EnhancedAmountInputSymbol';
import SupportedAssetIcon from '@components/SupportedAssetIcon/SupportedAssetIcon';
import {
  EnhancedAmountSelect,
  EnhancedSlippageToleranceSelect,
} from '@components/EnhancedAmountSelect';
import getAssetLogoUri from '@components/SupportedAssetIcon/getAssetLogoUri';
import BorrowLimitLavaBar from '@components/BorrowLimitLavaBar/BorrowLimitLavaBar';
import {
  BLACKLIST_COLLATERAL_SWAP_TOKENS,
  SLIPPAGE_TOLERANCE,
  SWAP_FEE,
} from '@config/constants';
import TransactionModalDataRow from '@components/TransactionModalDataRow/TransactionModalDataRow';
import StartEndText from '@components/StartEndText/StartEndText';
import {
  formatPercent,
  formatTotalUnderlyingAmount,
  parseInputAmountToBN,
  formatUserTotalUsdValue,
  formatRateToPercent,
  formatUserUnderlyingAmountWithDust,
} from '@lib/units';
import { checkPriceImpact, getSwapPath } from '@components/RepayModal/utils';
import useSupplyApy from '@hooks/useSupplyApy';
import useUsdPrices from '@hooks/useUsdPrices';
import useVvsSwapTrade from '@hooks/useVVSSwapTrade';
import {
  getTotalBorrowLimit,
  getUserBorrowBalance,
  getAvailableBorrow,
  getBorrowBalanceRate,
} from '@lib/math';
import useWallets from '@hooks/useWallets';
import { TransactionModalsContext } from '@providers/TransactionModalsProvider';
import TransactionModalsProvider from '@providers/TransactionModalsProvider';
import useSocketAddress from '@hooks/useSocketAddress';

import VVSSVG from '../../public/images/vvs.svg';
import SWAPSVG from '../../public/assets/images/swap.svg';

const LIMIT_BALANCE_RATE = 0.9;

export interface SupplyModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  mode: Mode;
  allowance: BigNumber;
  amount: string;
  asset: Null<TectonicAsset>;
  isAllowanceLoaded: boolean;
  isAllowanceLoading: boolean;
  onAmountChange(value: string): void;
  onEnable: VoidFunction;
  onGetTTokenAmountOut: (
    tectonicSocketAddress: string,
    fromAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[]
  ) => Promise<[BigNumber, BigNumber]>;
  onCollateralSwap: (
    tectonicSocketAddress: string,
    account: string,
    swapAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[],
    swapTTokenAddress: string
  ) => void;
}

const SwapModal = ({
  mode,
  allowance,
  amount,
  asset,
  isAllowanceLoaded,
  isAllowanceLoading,
  onAmountChange,
  onEnable,
  onGetTTokenAmountOut,
  transactionStatus,
  onCollateralSwap,
  ...props
}: SupplyModalProps) => {
  const [swapToAmount, setSwapToAmount] = useState<Null<BigNumber>>(null);
  const [isCollapse, setCollapse] = useState<boolean>(true);
  const [isCollapseTolerance, setCollapseTolerance] = useState<boolean>(true);
  const [collateralTokenSymbol, setCollateralData] = useState<string>();
  const [slippageTolerance, setSlippageTolerance] = useState<number>(0.005);

  const isMobile = useIsMobile();

  const { list: assets } = useSdkAndSupportedAssets(mode);

  const symbol = asset?.symbol || '';
  const options = useMemo(() => ({ skip: !props.isOpen }), [props.isOpen]);

  const erc20IsEnabled = isAmountLessThanAllowance(allowance, amount, asset);

  const tectonicSocketAddress = useSocketAddress(mode);

  const { data: userMetricsData, loaded: loadedUserMetrics } = useUserMetrics(
    mode,
    options
  );

  const { data: walletData } = useWalletBalance(mode, asset, options);

  const { loaded: loadedUsdPrices, usdPrices } = useUsdPrices(mode, options);

  const { currentAccount } = useWallets();

  const borrowLimit =
    loadedUserMetrics && loadedUsdPrices
      ? getTotalBorrowLimit(
          assets.map((a) => {
            const collateralFactor =
              userMetricsData.collateralFactors[a.symbol] ?? 0;
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol] ?? false;
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);

            return {
              asset: a,
              collateralFactor,
              isEnabledAsCollateral,
              supplyAmount,
              usdPrice,
            };
          })
        )
      : null;

  const calSupplyAmount = useCallback(
    (s: string) => {
      const supplyAmount =
        userMetricsData.supplyAmounts[s] ?? BigNumber.from(0);

      const amountBN =
        amount && asset
          ? parseInputAmountToBN(amount, asset.decimals)
          : BigNumber.from(0);

      switch (s) {
        case symbol:
          return supplyAmount.sub(amountBN);
        case collateralTokenSymbol:
          return swapToAmount ? supplyAmount.add(swapToAmount) : supplyAmount;
        default:
          return supplyAmount;
      }
    },
    [
      amount,
      asset,
      swapToAmount,
      symbol,
      collateralTokenSymbol,
      userMetricsData,
    ]
  );

  const newBorrowLimit = useMemo(() => {
    if (loadedUserMetrics && loadedUsdPrices) {
      const assetParams = assets.map((a) => {
        const collateralFactor =
          userMetricsData.collateralFactors[a.symbol] ?? 0;
        const isEnabledAsCollateral =
          userMetricsData.isCollateral[a.symbol] ?? false;
        const supplyAmount = calSupplyAmount(a.symbol);
        const usdPrice = BigNumber.from(usdPrices[a.symbol]);

        return {
          asset: a,
          collateralFactor,
          isEnabledAsCollateral,
          supplyAmount,
          usdPrice,
        };
      });

      return getTotalBorrowLimit(assetParams);
    }

    return null;
  }, [
    assets,
    userMetricsData,
    usdPrices,
    loadedUsdPrices,
    loadedUserMetrics,
    calSupplyAmount,
  ]);

  const borrowBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserBorrowBalance(
          assets.map((a) => {
            const borrowAmount = userMetricsData.borrowAmounts[a.symbol];
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            return { asset: a, borrowAmount, usdPrice };
          })
        )
      : null;

  const availableBorrowUsd =
    borrowLimit && borrowBalance
      ? getAvailableBorrow(borrowLimit, borrowBalance)
      : null;

  const newAvailableBorrowUsd = useMemo(() => {
    if (newBorrowLimit && borrowBalance) {
      return getAvailableBorrow(newBorrowLimit, borrowBalance);
    }

    return null;
  }, [newBorrowLimit, borrowBalance]);

  const swapToAsset = useMemo(() => {
    if (collateralTokenSymbol) {
      return assets.find((a) => a.symbol === collateralTokenSymbol) ?? null;
    }

    return null;
  }, [collateralTokenSymbol, assets]);

  const vvsData = useVvsSwapTrade(
    amount,
    asset,
    slippageTolerance,
    swapToAsset
  );

  const { netSupplyApy, isLoading: isNetSupplyLoading } = useSupplyApy(
    mode,
    asset
  );
  const {
    netSupplyApy: targetAssetNetApy,
    isLoading: isTargetAssetNetApyLoading,
  } = useSupplyApy(mode, swapToAsset);

  const supplyAmount = useMemo(
    () => userMetricsData.supplyAmounts[symbol],
    [userMetricsData, symbol]
  );

  const getCollateralSwapParams = useCallback(() => {
    if (asset && swapToAsset && slippageTolerance && amount) {
      const collateralSwapAmount = parseInputAmountToBN(amount, asset.decimals);

      const slip = utils.parseUnits(slippageTolerance.toString(), 18);

      const path = getSwapPath(swapToAsset, asset);

      return {
        collateralSwapAmount,
        slip,
        path,
        swapTTokenAddress: asset.tTokenAddress,
      };
    }

    return {};
  }, [asset, swapToAsset, amount, slippageTolerance]);

  const getTokenAmountOut = useCallback(async () => {
    const collateralSwapParams = getCollateralSwapParams();

    if (collateralSwapParams.path) {
      const { collateralSwapAmount, slip, path } = collateralSwapParams;

      const tTokenAmountOut = await onGetTTokenAmountOut(
        tectonicSocketAddress,
        collateralSwapAmount,
        slip,
        path
      );

      return {
        tTokenAmount: tTokenAmountOut[0],
        amountOutMax: tTokenAmountOut[1],
      };
    }

    return {
      tTokenAmount: BigNumber.from(0),
      amountOutMax: BigNumber.from(0),
    };
  }, [onGetTTokenAmountOut, tectonicSocketAddress, getCollateralSwapParams]);

  useEffect(() => {
    if (asset && swapToAsset && slippageTolerance) {
      getTokenAmountOut().then((tokenAmountOutMax) => {
        if (tokenAmountOutMax) {
          setSwapToAmount(tokenAmountOutMax.amountOutMax);
        }
      });
    }
  }, [asset, swapToAsset, slippageTolerance, getTokenAmountOut]);

  const onMaxClick = () => {
    if (asset && supplyAmount) {
      onAmountChange(formatUserUnderlyingAmountWithDust(asset, supplyAmount));
    } else {
      onAmountChange('0');
    }
  };

  const selectOptionsOfSwapAssets = useMemo(
    () =>
      Object.keys(userMetricsData.supplyAmounts).filter(function (key) {
        return (
          key !== symbol &&
          key !== 'TUSD' &&
          !BLACKLIST_COLLATERAL_SWAP_TOKENS.includes(key) &&
          userMetricsData.borrowAmounts[key]?.eq(0)
        );
      }),
    [userMetricsData, symbol]
  );

  const borrowBalanceRate = useMemo(() => {
    if (borrowBalance && newBorrowLimit && collateralTokenSymbol) {
      return getBorrowBalanceRate(borrowBalance, newBorrowLimit);
    }

    if (borrowBalance && borrowLimit) {
      return getBorrowBalanceRate(borrowBalance, borrowLimit);
    }

    return 0;
  }, [borrowBalance, borrowLimit, newBorrowLimit, collateralTokenSymbol]);

  const isExceededBorrowBalanceRate = useMemo(
    () => borrowBalanceRate >= LIMIT_BALANCE_RATE,
    [borrowBalanceRate]
  );

  const isExceededSupplyAmount = useMemo(() => {
    if (amount && supplyAmount && asset) {
      return utils.parseUnits(amount, asset.decimals).gt(supplyAmount);
    }

    return false;
  }, [amount, supplyAmount, asset]);

  const getWarningText = useCallback(() => {
    switch (true) {
      case isExceededSupplyAmount:
        return 'Try increasing slippage tolerance or reducing input amount';
      default:
        return '';
    }
  }, [isExceededSupplyAmount]);

  const huddleSwap = async () => {
    const collateralSwapParams = getCollateralSwapParams();

    if (collateralSwapParams.path && currentAccount) {
      const { slip, path, swapTTokenAddress, collateralSwapAmount } =
        collateralSwapParams;

      onCollateralSwap(
        tectonicSocketAddress,
        currentAccount,
        collateralSwapAmount,
        slip,
        path,
        swapTTokenAddress
      );
    }
  };

  function renderCtaButton() {
    if (!erc20IsEnabled) {
      return (
        <Button className="w-full" onClick={onEnable}>
          Enable
        </Button>
      );
    }

    if (
      collateralTokenSymbol &&
      swapToAsset &&
      !userMetricsData.isCollateral[collateralTokenSymbol]
    ) {
      return (
        <TransactionModalsProvider>
          <TransactionModalsContext.Consumer>
            {({ onOpenModal }) => (
              <Button
                className="w-full"
                onClick={() => {
                  onOpenModal(swapToAsset, 'collateral');
                }}
              >
                Enable {collateralTokenSymbol} Market
              </Button>
            )}
          </TransactionModalsContext.Consumer>
        </TransactionModalsProvider>
      );
    }

    return (
      <span className="flex flex-col gap-y-1.5">
        <div
          className={clsx('flex gap-x-1', {
            hidden: !getWarningText(),
          })}
        >
          <Icon.Warn width={18} height={18} />
          <div className="text-mediumSmall font-normal text-rainbowRed">
            {getWarningText()}
          </div>
        </div>
        <Button
          className="w-full"
          disabled={
            isExceededBorrowBalanceRate ||
            isExceededSupplyAmount ||
            checkPriceImpact(Number(vvsData?.data?.priceImpact))
          }
          onClick={huddleSwap}
        >
          {checkPriceImpact(Number(vvsData?.data?.priceImpact))
            ? 'Price Impact Too High'
            : 'Swap'}
        </Button>
      </span>
    );
  }

  return (
    <BaseTransactionModal
      className={clsx('max-h-[calc(100%-1rem)] desktop:!w-[510px]', {
        'fixed bottom-0 right-0 top-0 left-0 !h-[100vh] !max-h-screen !w-[100vw]':
          isMobile,
        // 'h-[666px]': !isResetHight,
      })}
      title={
        isAllowanceLoaded && !allowance.isZero()
          ? `Swap ${symbol}`
          : `Enable ${symbol} Market`
      }
      {...props}
      isShowMobileSliderClose={isMobile}
      transactionStatus={transactionStatus}
    >
      {(!isAllowanceLoaded || isAllowanceLoading) && (
        <div className="flex flex-col items-center justify-center">
          <Spinner />
          <Text className="mt-4">Loading data, please wait...</Text>
        </div>
      )}

      {isAllowanceLoaded && allowance.isZero() && (
        <EnableErc20Content
          onEnable={onEnable}
          symbol={symbol}
          walletData={walletData}
        />
      )}

      {isAllowanceLoaded && !allowance.isZero() && (
        <>
          <div className="mb-2">
            {erc20IsEnabled ? (
              <EnhancedAmountInputSymbol
                label="Supplied asset amount"
                displayBalance={
                  <Text className="font-light">
                    Supply balance{' '}
                    {asset &&
                      formatUserUnderlyingAmountWithDust(asset, supplyAmount)}
                  </Text>
                }
                rightLabel={
                  asset && (
                    <>
                      <SupportedAssetIcon className="h-2" asset={asset} />
                      <Text className="mx-1">{symbol}</Text>
                    </>
                  )
                }
                onBlur={(): void => {
                  if (amount === '') {
                    onAmountChange('0');
                  }
                }}
                onButtonClick={onMaxClick}
                onChange={(e): void => onAmountChange(e.target.value)}
                value={amount}
              />
            ) : (
              <Text>
                {`To supply ${symbol} to the Tectonic Protocol, you need to enable it first.`}
              </Text>
            )}
          </div>

          <div className="flex justify-center">
            <SWAPSVG width="25" height="25" />
          </div>

          <div className="my-2">
            <EnhancedAmountSelect
              label="Swap to"
              rightLabel={
                collateralTokenSymbol ? (
                  <>
                    {getAssetLogoUri(collateralTokenSymbol) && (
                      <AssetIcon
                        name={collateralTokenSymbol}
                        src={getAssetLogoUri(collateralTokenSymbol) ?? ''}
                      />
                    )}
                    <Text className="mx-1">{collateralTokenSymbol}</Text>
                  </>
                ) : (
                  <Text className="mr-1">Select</Text>
                )
              }
              onButtonClick={(tokenName) => setCollateralData(tokenName)}
              selectOptions={selectOptionsOfSwapAssets}
              leftLabel={
                swapToAsset && swapToAmount
                  ? formatTotalUnderlyingAmount(swapToAsset, swapToAmount)
                  : '0'
              }
              leftLabelClass={'text-white/50'}
              isCollapse={isCollapse}
              setCollapse={setCollapse}
              collateralTokenSymbol={collateralTokenSymbol}
            />
          </div>

          <TransactionModalDataRow
            label={<Text variant="default">Supply APY</Text>}
            loading={isNetSupplyLoading || isTargetAssetNetApyLoading}
            value={
              <StartEndText
                startValue={netSupplyApy ? formatPercent(netSupplyApy) : ''}
                endValue={
                  targetAssetNetApy && collateralTokenSymbol
                    ? formatPercent(targetAssetNetApy)
                    : ''
                }
              />
            }
          />

          <TransactionModalDataRow
            label={<Text variant="default">Available borrow</Text>}
            loading={false}
            value={
              availableBorrowUsd && (
                <StartEndText
                  startValue={formatUserTotalUsdValue(availableBorrowUsd)}
                  endValue={
                    newAvailableBorrowUsd &&
                    !newAvailableBorrowUsd.eq(availableBorrowUsd) &&
                    collateralTokenSymbol
                      ? formatUserTotalUsdValue(newAvailableBorrowUsd)
                      : null
                  }
                />
              )
            }
          />
          <Text className="text-center">Position at risk</Text>
          <div className="mb-2">
            <BorrowLimitLavaBar
              value={formatRateToPercent(borrowBalanceRate)}
            />
          </div>
          <div className="my-2 border border-white border-opacity-30 bg-darkerBlue p-1">
            <div className="mt-2 flex">
              <Text className="mr-1 text-white/60">Powered by</Text>
              <VVSSVG width="25" height="25" />
              <Text className="ml-1 font-bold">VVS Finance</Text>
            </div>

            <div className="my-1">
              <EnhancedSlippageToleranceSelect
                label=""
                onButtonClick={(st: number) => setSlippageTolerance(st)}
                selectOptions={SLIPPAGE_TOLERANCE}
                leftLabel="Slippage tolerance"
                isCollapse={isCollapseTolerance}
                setCollapse={setCollapseTolerance}
                slippageTolerance={slippageTolerance}
              />
            </div>

            <TransactionModalDataRow
              label={<Text variant="default">Minimum Received</Text>}
              loading={vvsData?.isLoading ?? false}
              value={
                <StartEndText
                  startValue={vvsData?.data?.minReceived}
                  endValue={null}
                />
              }
              tooltip="Amount received after accounting for slippage and fees. The transaction may revert if there is a large, unfavorable price movement before it is confirmed."
            />

            <TransactionModalDataRow
              label={<Text variant="default">Price</Text>}
              loading={vvsData?.isLoading ?? false}
              value={
                <StartEndText
                  startValue={
                    vvsData?.data?.price
                      ? `${vvsData?.data?.price} ${asset?.symbol} per ${swapToAsset?.symbol}`
                      : ''
                  }
                  endValue={null}
                />
              }
            />

            <TransactionModalDataRow
              label={<Text variant="default">Price impact</Text>}
              loading={vvsData?.isLoading ?? false}
              value={
                <StartEndText
                  startValue={
                    vvsData?.data?.priceImpact
                      ? `${vvsData?.data?.priceImpact} %`
                      : ''
                  }
                  endValue={null}
                />
              }
              tooltip="Difference between the market price and estimated price due to discrepancies between the trade size relative to liquidity available."
            />

            <TransactionModalDataRow
              label={<Text variant="default">Swap fee</Text>}
              loading={false}
              value={
                <StartEndText
                  startValue={formatPercent(SWAP_FEE * 100)}
                  endValue={null}
                />
              }
              tooltip="If using an aggregator, this amount represents commission fees paid to use their services, but does not account for swap fees incurred from routing liquidity to various trading venues"
            />
          </div>
          {renderCtaButton()}
        </>
      )}
    </BaseTransactionModal>
  );
};

export default SwapModal;
