import HomePageView from '@components/HomePageView';
import Seo from '@components/Seo';

function HomePage(): JSX.Element {
  return (
    <>
      <Seo
        title="Crypto Loans - Earn, Lend and Borrow Crypto on Cronos | Tectonic"
        description="Tectonic is the first lending and borrowing platform of the Cronos ecosystem, powered by the TONIC governance token. Tectonic is your gateway to earn passive yield and accessing instant backed loans."
      />
      <HomePageView />
    </>
  );
}

export default HomePage;
