import { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { BigNumber } from 'ethers';
import { AssetIcon, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk';

import CollapsibleShortSteps from '@components/CollapsibleShortSteps/CollapsibleShortSteps';
import { WalletBalanceState } from '@hooks/useWalletBalance';
import { formatUnits, formatUserUnderlyingAmount } from '@lib/units';
import getAssetLogoUri from '@components/SupportedAssetIcon/getAssetLogoUri';

interface selectLongAssetProps {
  selectStep: {
    selectedLongAsset: boolean;
    selectedBorrowLevel: boolean;
    selectedDexes: boolean;
  };
  setSelectStep: Dispatch<
    SetStateAction<{
      selectedLongAsset: boolean;
      selectedBorrowLevel: boolean;
      selectedDexes: boolean;
    }>
  >;
  walletData: WalletBalanceState;
  amount: string;
  onAmountChange(value: string): void;
  longTokenSymbol?: string;
  selectOptionsOfAssets: string[];
  onMaxClick: VoidFunction;
  setLongTokenData: Dispatch<SetStateAction<string>>;
  asset: Null<TectonicAsset>;
  borrowLimit: Null<BigNumber>;
}
const SelectLongAsset = ({
  selectStep,
  setSelectStep,
  walletData,
  amount,
  onAmountChange,
  longTokenSymbol,
  selectOptionsOfAssets,
  onMaxClick,
  setLongTokenData,
  asset,
  borrowLimit,
}: selectLongAssetProps) => {
  return (
    <CollapsibleShortSteps
      step={'Step 1'}
      isSelected={selectStep.selectedLongAsset}
      leftLabel={'Choose the asset and amount to go long on'}
      onStepSelected={() =>
        setSelectStep({
          selectedLongAsset: !selectStep.selectedLongAsset,
          selectedBorrowLevel: false,
          selectedDexes: false,
        })
      }
      chooseValue={
        <div className="mt-2">
          <Text>
            Wallet balance{' '}
            {!!(walletData.asset && walletData.balance) &&
              formatUserUnderlyingAmount(walletData.asset, walletData.balance)}
          </Text>
          <div className="flex">
            <input
              className="webkit-none box-border h-full w-full bg-transparent py-1.5 font-medium text-white outline-none"
              onBlur={(): void => {
                if (amount === '') {
                  onAmountChange('0');
                }
              }}
              onChange={(e): void => onAmountChange(e.target.value)}
              placeholder="0"
              type="text"
              value={amount}
              disabled={walletData.balance?.isZero()}
            />
            {longTokenSymbol && (
              <>
                {getAssetLogoUri(longTokenSymbol) && (
                  <AssetIcon
                    name={longTokenSymbol}
                    src={getAssetLogoUri(longTokenSymbol) ?? ''}
                  />
                )}
                <Text className="mx-1">{longTokenSymbol}</Text>
              </>
            )}
          </div>

          {walletData.balance?.isZero() ? (
            <>
              <span className="text-[11px]">
                You currently do not hold any {longTokenSymbol} in your wallet.
                Your existing{' '}
                {borrowLimit
                  ? Number(formatUnits(borrowLimit, 18)).toFixed(4)
                  : 0}{' '}
                collateral will be used to borrow {asset?.symbol} and then
                swapped for {longTokenSymbol}.
              </span>
              <a
                href={'https://tectonic.gitbook.io/docs/guides/shorting-assets'}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-0.5 text-[11px] text-yellowPrimary underline"
              >
                Learn more.
              </a>
            </>
          ) : (
            <>
              <span className="text-[11px]">
                You can long this token using your wallet balance and your
                existing collateral on Tectonic.
              </span>
              <a
                href={'https://tectonic.gitbook.io/docs/guides/shorting-assets'}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-0.5 text-[11px] text-yellowPrimary underline"
              >
                Learn more.
              </a>
            </>
          )}

          <div>
            <button className="text-yellowPrimary" onClick={onMaxClick}>
              Max
            </button>
          </div>
          <div className="grid grid-cols-4 gap-1 py-1.5">
            {selectOptionsOfAssets &&
              selectOptionsOfAssets?.map((value, key) => {
                return (
                  <button
                    key={key}
                    className={clsx(
                      'flex items-center justify-center rounded-lg border px-1 text-white mobile:mr-1 mobile:w-fit mobile:text-small',
                      longTokenSymbol === value
                        ? 'bg-black border-yellow'
                        : 'border-white/[0.3] bg-white/[0.12]'
                    )}
                    onClick={() => {
                      setLongTokenData(value);
                      onAmountChange('0');
                    }}
                  >
                    {getAssetLogoUri(value) && (
                      <AssetIcon
                        name={value}
                        src={getAssetLogoUri(value) ?? ''}
                        className="mr-1 h-[12px] w-[12px]"
                      />
                    )}
                    {value}
                  </button>
                );
              })}
          </div>
        </div>
      }
      label={
        amount &&
        longTokenSymbol &&
        getAssetLogoUri(longTokenSymbol) && (
          <div className="flex py-2">
            <AssetIcon
              name={longTokenSymbol}
              src={getAssetLogoUri(longTokenSymbol) ?? ''}
            />
            <Text className="ml-1">{amount}</Text>
          </div>
        )
      }
    />
  );
};

export default SelectLongAsset;
