import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import {
  Button,
  PageSection,
  StackView,
  Text,
} from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk';

import Card from '@components/Card';
import TransactionModalsProvider, {
  TransactionModalsContext,
  TransactionModalsProviderProps,
} from '@providers/TransactionModalsProvider';
import useVvsFinance2022FebTonicAirdropData from '@hooks/useVvsFinance2022FebTonicAirdropData';
import useWalletBalance from '@hooks/useWalletBalance';
import useWallets from '@hooks/useWallets';
import Features from '@components/Features';
import useSupportedAsset from '@hooks/useSupportedAsset';
import usePoolMode from '@hooks/usePoolMode';

import TonicAirdropCard from './TonicAirdropCard';
import TonicMarketStatsCards from './TonicMarketStatsCards';
import TonicStakingCard from './TonicStakingCard';
import useTonicStakingCardData from './useTonicStakingCardData';
import ConversionBountyCard from './ConversionBountyCard';
import UnclaimedAirdropCard from './UnclaimedAirdropCard';
import useBountyConversionData from './useBountyConversionData';

function TonicPageView(): JSX.Element {
  const tonicAsset = useSupportedAsset('TONIC');
  const { currentAccount, onShowConnectWalletModal } = useWallets();
  const poolType = usePoolMode();
  const {
    data: { balance: tonicBalance },
    refetch: refetchTonicBalance,
  } = useWalletBalance(poolType, tonicAsset);
  const {
    hasClaimedAirdrop,
    hasError,
    hasNoSnapshot,
    loaded,
    loading: loadingAirdropData,
    refetch: refetchAirdropData,
    snapshot,
  } = useVvsFinance2022FebTonicAirdropData(currentAccount);
  const {
    apy,
    countdownInSeconds,
    hasError: unableToLoadingTonicStakingCardData,
    loading: loadingTonicStakingCardData,
    lockedExchangeRate,
    refetch: refetchTonicStakingCardData,
    releasableXTonicAmount,
    unstakedXTonicAmount,
    xTonicBalance,
    xTonicToTonicExchangeRate,
    stakingRewards,
    stakingRewardsFetched,
    totalXTonicLockedInVaults,
    totalXTonicLockedInVaultsFetched,
  } = useTonicStakingCardData(currentAccount);
  const claimTonicAirdropModalProps = useMemo<
    TransactionModalsProviderProps['ClaimTonicAirdropModalProps']
  >(() => {
    if (snapshot?.signature) {
      return { signature: snapshot.signature };
    }

    return {};
  }, [snapshot?.signature]);

  const {
    isLoading: bountyDataLoading,
    bountyYield,
    bountyReward,
    bountyRewardUsd,
  } = useBountyConversionData(
    tonicAsset as TectonicAsset,
    currentAccount ?? ''
  );

  return (
    <Features.ConversionBounty>
      {(bountyEnabled) => (
        <PageSection contentWrapperClassName="w-full space-y-3 pb-3 mobile:pb-2">
          <TonicMarketStatsCards />
          {currentAccount ? (
            <div className="flex flex-col desktop:flex-row desktop:space-x-3 tablet:space-y-3 mobile:space-y-3">
              <TransactionModalsProvider
                ClaimTonicAirdropModalProps={claimTonicAirdropModalProps}
                onTxSuccess={(): void => {
                  refetchAirdropData();
                  refetchTonicStakingCardData();
                  refetchTonicBalance();
                }}
              >
                <TransactionModalsContext.Consumer>
                  {({ onOpenModal }) => (
                    <>
                      {!bountyEnabled && (
                        <TonicAirdropCard
                          hasClaimedAirdrop={hasClaimedAirdrop}
                          hasError={hasError}
                          hasNoSnapshot={hasNoSnapshot}
                          loaded={loaded}
                          loading={loadingAirdropData}
                          tonicAsset={tonicAsset}
                          onClaimTonic={(amount: string): void => {
                            if (tonicAsset) {
                              onOpenModal(tonicAsset, 'claimTonicAirdrop', {
                                amount,
                              });
                            }
                          }}
                          snapshot={snapshot}
                        />
                      )}
                      <TonicStakingCard
                        apy={apy}
                        countdownInSeconds={countdownInSeconds}
                        hasError={unableToLoadingTonicStakingCardData}
                        loading={loadingTonicStakingCardData}
                        lockedXTonicToTonicExchangeRate={lockedExchangeRate}
                        onStakeTonic={(amount: string): void => {
                          if (tonicAsset) {
                            onOpenModal(tonicAsset, 'stakeTonic', { amount });
                          }
                        }}
                        onUnstakeTonic={(
                          tonicAmount: string,
                          hasCooldown: boolean,
                          exchangeRate: BigNumber
                        ): void => {
                          if (tonicAsset) {
                            onOpenModal(tonicAsset, 'unstakeTonic', {
                              amount: tonicAmount,
                              meta: {
                                type: 'unstakeTonic',
                                hasCooldown,
                                xTonicToTonicExchangeRate: exchangeRate,
                              },
                            });
                          }
                        }}
                        onWithdraw={(amount: string): void => {
                          if (tonicAsset) {
                            onOpenModal(tonicAsset, 'withdrawUnstakedTonic', {
                              amount,
                            });
                          }
                        }}
                        releasableXTonicAmount={releasableXTonicAmount}
                        tonicAsset={tonicAsset}
                        tonicBalance={tonicBalance}
                        unstakedXTonicAmount={unstakedXTonicAmount}
                        xTonicBalance={xTonicBalance}
                        xTonicToTonicExchangeRate={xTonicToTonicExchangeRate}
                        stakingRewards={stakingRewards}
                        stakingRewardsFetched={stakingRewardsFetched}
                        totalXTonicLockedInVaults={totalXTonicLockedInVaults}
                        totalXTonicLockedInVaultsFetched={
                          totalXTonicLockedInVaultsFetched
                        }
                      />
                      {bountyEnabled && (
                        <div>
                          <ConversionBountyCard
                            loading={bountyDataLoading}
                            protocolYieldInTonic={bountyYield || ''}
                            claimableBountyInTonic={bountyReward || ''}
                            claimableBountyInUsd={bountyRewardUsd || ''}
                            onClaim={() =>
                              tonicAsset &&
                              onOpenModal(tonicAsset, 'claimBounty', {
                                meta: {
                                  type: 'claimBounty',
                                  formattedAmount: bountyReward ?? '0',
                                },
                              })
                            }
                          />
                          {/* <UnclaimedAirdropCard
                            hasClaimedAirdrop={hasClaimedAirdrop}
                            hasError={hasError}
                            hasNoSnapshot={hasNoSnapshot}
                            loaded={loaded}
                            loading={loadingAirdropData}
                            tonicAsset={tonicAsset}
                            onClaimTonic={(amount: string): void => {
                              if (tonicAsset) {
                                onOpenModal(tonicAsset, 'claimTonicAirdrop', {
                                  amount,
                                });
                              }
                            }}
                            snapshot={snapshot}
                          /> */}
                        </div>
                      )}
                    </>
                  )}
                </TransactionModalsContext.Consumer>
              </TransactionModalsProvider>
            </div>
          ) : (
            <Card className="text-center" padding="large">
              <StackView spacing={2} className="place-items-center">
                <Text variant="heading3">Connect your wallet</Text>
                <Text>Connect your wallet to see your reward</Text>
                <Button onClick={onShowConnectWalletModal}>
                  Connect wallet
                </Button>
              </StackView>
            </Card>
          )}
        </PageSection>
      )}
    </Features.ConversionBounty>
  );
}

export default TonicPageView;
