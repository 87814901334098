import { PageSection } from '@tectonicfi/tectonic-ui-kit';

import { Community } from '../LandingBodyContent';

import BackgroundImageContainer from './BackgroundImageContainer';

function CommunitySection(): JSX.Element {
  return (
    <BackgroundImageContainer
      height="420px"
      src="/images/community-background.png"
    >
      <PageSection
        className="h-full"
        contentWrapperClassName="w-full flex items-center justify-start mobile:justify-center"
      >
        <Community />
      </PageSection>
    </BackgroundImageContainer>
  );
}

export default CommunitySection;
