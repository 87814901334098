import { BigNumber } from 'ethers';
import { addDays } from 'date-fns';
import { Button, Icon, Skeleton, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';
import Link from 'next/link';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import CountdownTimer from '@components/CountdownTimer';
import SupportedAssetIcon from '@components/SupportedAssetIcon';
import useUsdPrices from '@hooks/useUsdPrices';
import {
  formatUserUnderlyingAmount,
  formatUserUnderlyingUsdValue,
} from '@lib/units';
import { convertTonicToXTonic } from '@lib/math';
import { getUnderlyingUsdValue } from '@lib/math';
import { TxStatus } from '@types';
import Features from '@components/Features';

export interface TonicStakingModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  // This is TONIC Amount scaled up to 18 decimals
  amount: string;
  asset: TectonicAsset | null;
  hasCooldown: boolean;
  mode: 'stake' | 'unstake';
  onStartTransaction(amount: string): void;
  xTonicToTonicExchangeRate: BigNumber | null;
}

function TonicStakingModal({
  amount,
  asset,
  hasCooldown,
  mode,
  onClose,
  onStartTransaction,
  transactionErrorMessage,
  xTonicToTonicExchangeRate,
  ...props
}: TonicStakingModalProps): JSX.Element {
  const isStaking = mode === 'stake';
  const showResetCooldownMessage = !isStaking && hasCooldown;
  const { usdPrices } = useUsdPrices();
  const tonicUsdPrice = usdPrices['TONIC']
    ? BigNumber.from(usdPrices['TONIC'])
    : null;

  function renderSuccessMessage(): JSX.Element {
    if (isStaking) {
      return (
        <Features.Vaults>
          {(vaultEnabled) => (
            <>
              <Icon.Check className="inline-block h-8 w-8" />
              {vaultEnabled ? (
                <Text className="mt-4">
                  Your TONIC is staked! Click the button below to lock your
                  xTONIC into vaults to earn additional yield.
                </Text>
              ) : (
                <Text className="mt-4">Your TONIC is staked!</Text>
              )}
              {vaultEnabled ? (
                // eslint-disable-next-line @next/next/link-passhref
                <Link href="/vaults" passHref={false}>
                  <Button className="mt-3 w-full">Lock xTONIC</Button>
                </Link>
              ) : (
                <Button className="mt-3 w-full" onClick={onClose}>
                  Done
                </Button>
              )}
            </>
          )}
        </Features.Vaults>
      );
    }

    const countdownEndDate = addDays(new Date(), 10);

    return (
      <>
        <div className="mb-1 flex flex-row justify-center">
          <Icon.Clock className="h-10 w-10 text-yellowPrimary" />
        </div>
        <div className="mx-[20%] mb-3">
          <CountdownTimer endDateTime={countdownEndDate} />
        </div>
        <Text>
          You will be able to withdraw your TONIC after the cooldown period.
        </Text>
        <Button className="mt-3 w-full" onClick={onClose}>
          Done
        </Button>
      </>
    );
  }

  function getInstructionsText(): string {
    if (isStaking) {
      return 'You are about to stake the following amounts.';
    }

    if (!showResetCooldownMessage) {
      return 'You are about to unstake the following amounts. It will take about 10 days to complete.';
    }

    return 'You are about to unstake the following amounts. It will reinitiate the cooldown period and take about 10 days to complete.';
  }

  function getParsedAmount(): BigNumber | null {
    // amount can be a decimal for other modals
    try {
      return BigNumber.from(amount);
    } catch (error) {
      return null;
    }
  }

  function renderAmount() {
    if (asset && amount) {
      const parsedAmount = getParsedAmount();

      if (!parsedAmount) {
        return <Skeleton />;
      }

      return (
        <Text variant="large">
          {formatUserUnderlyingAmount(asset, parsedAmount)}
        </Text>
      );
    }

    return <Skeleton />;
  }

  function renderUsdValue() {
    if (asset && amount && tonicUsdPrice) {
      const parsedAmount = getParsedAmount();

      if (!parsedAmount) {
        return <Skeleton />;
      }

      return (
        <Text className="text-onSurface60" variant="default">
          {formatUserUnderlyingUsdValue(
            asset,
            getUnderlyingUsdValue(asset, parsedAmount, tonicUsdPrice)
          )}
        </Text>
      );
    }

    return <Skeleton />;
  }

  return (
    <BaseTransactionModal
      onClose={onClose}
      renderTitle={(txStatus): string => {
        if (!txStatus || txStatus === TxStatus.pending) {
          return isStaking ? 'Stake TONIC' : 'Unstake TONIC';
        }

        if (txStatus === TxStatus.awaiting_confirmation) {
          return 'Confirm transaction';
        }

        if (txStatus === TxStatus.confirmed) {
          return 'Success';
        }

        return 'Failed';
      }}
      renderTransactionStatus={(txStatus) => {
        const isTransactionAwaitingConfirmation =
          txStatus === TxStatus.awaiting_confirmation;
        const isTransactionPending = txStatus === TxStatus.pending;
        const isTransactionConfirmed = txStatus === TxStatus.confirmed;
        const showSpinner =
          isTransactionAwaitingConfirmation || isTransactionPending;

        return (
          <div className="text-center">
            {showSpinner && (
              <Icon.Spinner className="inline-block h-8 w-8 animate-spin" />
            )}
            {isTransactionAwaitingConfirmation && (
              <Text className="mt-4">
                Confirm the transaction in your wallet
              </Text>
            )}
            {isTransactionPending && (
              <Text className="mt-4">
                {isStaking
                  ? 'Staking your TONIC, please wait...'
                  : 'Unstaking your TONIC, please wait...'}
              </Text>
            )}
            {isTransactionConfirmed && renderSuccessMessage()}
            {txStatus === TxStatus.failed && (
              <Text>{transactionErrorMessage || 'Unknown error'}</Text>
            )}
          </div>
        );
      }}
      transactionErrorMessage={transactionErrorMessage}
      {...props}
    >
      <Text variant="default">{getInstructionsText()}</Text>
      <div className="my-3 flex flex-col items-center">
        {asset && <SupportedAssetIcon asset={asset} className="mb-3 h-8 w-8" />}
        {renderAmount()}
        {renderUsdValue()}
      </div>
      <Button
        className="w-full"
        onClick={(): void => {
          if (mode === 'stake') {
            onStartTransaction(amount);
          } else if (xTonicToTonicExchangeRate) {
            onStartTransaction(
              convertTonicToXTonic(
                BigNumber.from(amount),
                xTonicToTonicExchangeRate
              ).toString()
            );
          }
        }}
      >
        Confirm
      </Button>
    </BaseTransactionModal>
  );
}

export default TonicStakingModal;
