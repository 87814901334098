import { makeRelativeUrl } from '@lib/utils';

interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  children?: JSX.Element;
  height: string;
}

function BackgroundImageContainer({
  children,
  height,
  ...imageProps
}: Props): JSX.Element {
  const { src, ...rest } = imageProps;
  return (
    <div style={{ height: height }}>
      <div
        style={{ height: height }}
        className="absolute -z-1 flex w-screen items-center justify-center"
      >
        <img
          style={{ height: '100%', width: '1920px', overflowY: 'hidden' }}
          className="object-cover"
          alt="Hero"
          src={src ? makeRelativeUrl(src) : ''}
          {...rest}
        />
      </div>
      {children}
    </div>
  );
}

export default BackgroundImageContainer;
