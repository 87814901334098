import { AssetIcon, AssetIconProps } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';

import getAssetLogoUri from './getAssetLogoUri';

interface SupportedAssetIconProps extends Omit<AssetIconProps, 'name' | 'src'> {
  asset: TectonicAsset;
}

function SupportedAssetIcon({
  asset,
  ...rest
}: SupportedAssetIconProps): JSX.Element | null {
  const uri = getAssetLogoUri(asset.symbol);

  if (!uri) {
    return null;
  }

  return <AssetIcon name={asset.symbol} src={uri} {...rest} />;
}

export default SupportedAssetIcon;
