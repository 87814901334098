import { useMemo } from 'react';
import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';
import { BigNumber } from 'ethers';
import Link from 'next/link';
import clsx from 'clsx';
import get from 'lodash/get';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import BorrowLimitLavaBar from '@components/BorrowLimitLavaBar';
import StartEndText from '@components/StartEndText';
import SupportedAssetIcon from '@components/SupportedAssetIcon';
import EnhancedAmountInput from '@components/EnhancedAmountInput';
import TransactionModalDataRow from '@components/TransactionModalDataRow';
import useBorrowApy from '@hooks/useBorrowApy';
import useUsdPrices from '@hooks/useUsdPrices';
import useUserMetrics from '@hooks/useUserMetrics';
import useWalletBalance from '@hooks/useWalletBalance';
import {
  formatPercent,
  formatRateToPercent,
  formatUserTotalUsdValue,
  formatUserUnderlyingAmount,
} from '@lib/units';
import {
  getBorrowBalanceRate,
  getLiquidationLtvRate,
  getLoanToValueRate,
  getPostBorrowBorrowBalance,
  getTotalBorrowLimit,
  getUserBorrowBalance,
  getUserCollateralBalance,
  getUserMaxBorrowAmount,
} from '@lib/math';
import { determineBorrowMaxAmount } from '@lib/maxLogic';
import useGetTotalMarketAvailableAmount from '@hooks/useGetTotalMarketAvailableAmount';
import { isAmountZeroEquivalent } from '@lib/utils';
import useDisableBorrow from '@hooks/useDisableBorrow';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import { Mode } from '@components/MarketsPageView/types';
import { PARTNER_TOKEN_ICONS } from '@config/constants';

import validateBorrowAmount from './validateBorrowAmount';
import BorrowCautionMessage, { SeverityType } from './BorrowCautionMessage';

export interface BorrowModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  amount: string;
  asset: TectonicAsset | null;
  onAmountChange(value: string): void;
  onBorrow(amount: string): void;
  mode: Mode;
}

function BorrowModal({
  amount,
  asset,
  onAmountChange,
  onBorrow,
  transactionStatus = null,
  mode,
  ...props
}: BorrowModalProps): JSX.Element {
  const { list: assets } = useSdkAndSupportedAssets(mode);
  const symbol = asset?.symbol || '';
  const options = useMemo(() => ({ skip: !props.isOpen }), [props.isOpen]);

  const { loaded: loadedUsdPrices, usdPrices } = useUsdPrices(mode, options);
  // TODO - If we can't load userMetricsData, then we should not proceed
  const {
    data: userMetricsData,
    loading: loadingUserMetrics,
    loaded: loadedUserMetrics,
  } = useUserMetrics(mode, options);
  const { data: walletData } = useWalletBalance(mode, asset, options);

  const {
    netBorrowApy,
    tonicBorrowApy,
    partnerTokenBorrowApy,
    isLoading: isBorrowApyLoading,
  } = useBorrowApy(mode, asset, options);

  const {
    balance: totalMarketAvailableAmount,
    loading: loadingTotalMarketAvailableAmount,
  } = useGetTotalMarketAvailableAmount(asset, mode, options);

  const totalBorrowLimit =
    loadedUserMetrics && loadedUsdPrices
      ? getTotalBorrowLimit(
          assets.map((a) => {
            const collateralFactor =
              userMetricsData.collateralFactors[a.symbol] ?? 0;
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol] ?? false;
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);

            return {
              asset: a,
              collateralFactor,
              isEnabledAsCollateral,
              supplyAmount,
              usdPrice,
            };
          })
        )
      : null;

  const borrowBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserBorrowBalance(
          assets.map((a) => {
            const borrowAmount =
              userMetricsData.borrowAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            return { asset: a, borrowAmount, usdPrice };
          })
        )
      : null;

  const userMaxBorrowAmount =
    asset &&
    borrowBalance &&
    loadedUserMetrics &&
    totalBorrowLimit &&
    loadedUsdPrices
      ? getUserMaxBorrowAmount(
          asset,
          BigNumber.from(usdPrices[symbol]),
          borrowBalance,
          totalBorrowLimit
        )
      : null;

  const collateralBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserCollateralBalance(
          assets.map((a) => {
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol] ?? false;

            return {
              asset: a,
              collateralAmount: supplyAmount,
              isCollateral: isEnabledAsCollateral,
              usdPrice,
            };
          })
        )
      : null;

  const hasAnyCollateralEnabled =
    loadedUserMetrics &&
    Object.values(userMetricsData.isCollateral).some(
      (assetIsCollateral) => assetIsCollateral
    );

  const loadingInfo =
    isBorrowApyLoading ||
    loadingTotalMarketAvailableAmount ||
    loadingUserMetrics ||
    walletData.loading ||
    !loadedUsdPrices;

  const shouldRenderCautionMessage = useMemo(() => {
    return (
      !isAmountZeroEquivalent(amount) &&
      borrowBalance &&
      !borrowBalance.isZero()
    );
  }, [amount, borrowBalance]);

  const { valid, reason } = validateBorrowAmount({
    amount,
    asset,
    hasAnyCollateralEnabled,
    totalMarketAvailableAmount,
    userMaxBorrowAmount,
  });

  const disableBorrow = useDisableBorrow(
    mode,
    asset,
    valid ? amount : undefined
  );

  const buttonText = useMemo(() => {
    switch (reason) {
      case 'insufficientCollateral':
        return 'Insufficient Collateral';
      case 'insufficientLiquidity':
        return 'Insufficient Liquidity';
      case 'exceedsBorrowLimitAmount':
        return 'Exceeds Borrow Limit';
      default:
        return 'Borrow';
    }
  }, [reason]);

  const onMaxClick = () => {
    if (asset && userMaxBorrowAmount && hasAnyCollateralEnabled) {
      onAmountChange(
        determineBorrowMaxAmount(asset, {
          userMaxBorrowAmount,
          hasAnyCollateralEnabled,
        })
      );
    } else {
      onAmountChange('0');
    }
  };

  function getNewBorrowBalance(): BigNumber | null {
    if (asset && borrowBalance) {
      const newBorrowBalance = getPostBorrowBorrowBalance(
        asset,
        borrowBalance,
        amount,
        BigNumber.from(usdPrices[symbol])
      );

      if (!newBorrowBalance) {
        return null;
      }

      return newBorrowBalance;
    }

    return null;
  }

  const endBorrowBalance = getNewBorrowBalance();

  function getNewLtv(): string | null {
    if (asset && borrowBalance && collateralBalance) {
      const newBorrowBalance = getPostBorrowBorrowBalance(
        asset,
        borrowBalance,
        amount,
        BigNumber.from(usdPrices[symbol])
      );

      if (newBorrowBalance) {
        const newLtv = formatPercent(
          formatRateToPercent(
            getLoanToValueRate(newBorrowBalance, collateralBalance)
          )
        );

        return newLtv;
      }
    }

    return null;
  }

  function getNewLtvFloat(): number {
    const newLtv = getNewLtv();
    if (newLtv) {
      return parseFloat(newLtv);
    }
    return 0;
  }

  function getLavaBarPercent(): number {
    if (totalBorrowLimit) {
      if (endBorrowBalance) {
        return formatRateToPercent(
          getBorrowBalanceRate(endBorrowBalance, totalBorrowLimit)
        );
      }

      if (borrowBalance) {
        return formatRateToPercent(
          getBorrowBalanceRate(borrowBalance, totalBorrowLimit)
        );
      }
    }

    return 0;
  }

  const lavaBarPercent = getLavaBarPercent();
  const newLtvFloat = getNewLtvFloat();

  function determineMessageSeverity(
    lavaBar: number,
    newLtv: number
  ): SeverityType {
    if (lavaBar >= 89) return 'severe';
    if (newLtv >= 50) return 'normal';
    return null;
  }

  return (
    <BaseTransactionModal
      className="desktop:!w-[510px]"
      title={`Borrow ${symbol}`}
      {...props}
      transactionStatus={transactionStatus}
    >
      <div className="mb-4">
        <EnhancedAmountInput
          label="Borrow amount"
          onBlur={(): void => {
            if (amount === '') {
              onAmountChange('0');
            }
          }}
          onButtonClick={onMaxClick}
          onChange={(e): void => onAmountChange(e.target.value)}
          value={amount}
        />
      </div>
      <TransactionModalDataRow
        label={
          <div className="flex">
            {asset && <SupportedAssetIcon className="mr-1" asset={asset} />}
            <Text variant="default">Net Borrow APY</Text>
          </div>
        }
        loading={loadingInfo}
        value={
          netBorrowApy !== undefined && (
            <Text className={clsx({ 'text-green': netBorrowApy < 0 })}>
              {formatPercent(netBorrowApy)}
            </Text>
          )
        }
      />
      <TransactionModalDataRow
        label={
          <div className="ml-2.5 flex whitespace-nowrap">
            <Icon.Tonic className="mr-1 h-3 w-3" />
            <Text variant="default">Distribution APY</Text>
          </div>
        }
        loading={loadingInfo}
        value={<Text>{formatPercent(tonicBorrowApy * 100)}</Text>}
      />
      {Object.entries(partnerTokenBorrowApy).map(([tokenSymbol, apy]) => {
        const PartnerIcon = get(
          PARTNER_TOKEN_ICONS,
          tokenSymbol.toUpperCase(),
          null
        );

        if (!PartnerIcon || apy === 0) return null;

        return (
          <TransactionModalDataRow
            key={tokenSymbol}
            label={
              <div className="ml-2.5 flex whitespace-nowrap">
                <PartnerIcon className="mr-1 h-3 w-3" />
                <Text variant="default">Distribution APY</Text>
              </div>
            }
            loading={loadingInfo}
            value={<Text>{formatPercent(apy * 100)}</Text>}
          />
        );
      })}
      <TransactionModalDataRow
        label={<Text variant="default">Borrow balance</Text>}
        loading={loadingInfo}
        value={
          borrowBalance && (
            <StartEndText
              startValue={formatUserTotalUsdValue(borrowBalance)}
              endValue={
                endBorrowBalance
                  ? formatUserTotalUsdValue(endBorrowBalance)
                  : null
              }
            />
          )
        }
      />
      <TransactionModalDataRow
        label={<Text variant="default">Current Loan to Value (LTV)</Text>}
        loading={loadingInfo}
        value={
          !!(borrowBalance && collateralBalance) && (
            <StartEndText
              startValue={formatPercent(
                formatRateToPercent(
                  getLoanToValueRate(borrowBalance, collateralBalance)
                )
              )}
              endValue={getNewLtv()}
            />
          )
        }
      />
      <TransactionModalDataRow
        label={<Text variant="default">Liquidation Threshold</Text>}
        loading={loadingInfo}
        value={
          !!(totalBorrowLimit && collateralBalance) && (
            <Text>
              {formatPercent(
                formatRateToPercent(
                  getLiquidationLtvRate(totalBorrowLimit, collateralBalance)
                )
              )}
            </Text>
          )
        }
      />
      <div className="mb-10">
        <BorrowLimitLavaBar value={lavaBarPercent} />

        {shouldRenderCautionMessage && lavaBarPercent <= 90 ? (
          <BorrowCautionMessage
            severity={determineMessageSeverity(lavaBarPercent, newLtvFloat)}
          />
        ) : null}
      </div>
      <TransactionModalDataRow
        label={<Text>Currently borrowing</Text>}
        loading={loadingInfo}
        value={
          !!(asset && userMetricsData.borrowAmounts[symbol]) && (
            <Text>
              {`${formatUserUnderlyingAmount(
                asset,
                userMetricsData.borrowAmounts[symbol]
              )} ${symbol}`}
            </Text>
          )
        }
      />
      {hasAnyCollateralEnabled ? (
        <>
          <Button
            className="w-full"
            theme="pink"
            disabled={!valid || disableBorrow}
            onClick={() => onBorrow(amount)}
          >
            {buttonText}
          </Button>
          {disableBorrow && (
            <Text className="mt-1 text-small">
              You are unable to borrow from this market as the maximum amount
              that can be borrowed has been reached.
            </Text>
          )}
        </>
      ) : (
        <Link href="/dashboard" passHref>
          <Button as="a" className="w-full text-center" theme="pink">
            Enable Collateral to Borrow
          </Button>
        </Link>
      )}
    </BaseTransactionModal>
  );
}

export default BorrowModal;
