import { useEffect, useMemo, useState } from 'react';
import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';
import { BigNumber, utils } from 'ethers';
import clsx from 'clsx';
import get from 'lodash/get';

import { BaseTransactionModalProps } from '@components/BaseTransactionModal';
import BorrowLimitLavaBar from '@components/BorrowLimitLavaBar';
import StartEndText from '@components/StartEndText';
import SupportedAssetIcon from '@components/SupportedAssetIcon';
import EnhancedAmountInput from '@components/EnhancedAmountInput';
import TransactionModalDataRow from '@components/TransactionModalDataRow';
import useBorrowApy from '@hooks/useBorrowApy';
import useUserAssetBorrowAmount from '@hooks/useUserAssetBorrowAmount';
import useUsdPrices from '@hooks/useUsdPrices';
import useUserMetrics from '@hooks/useUserMetrics';
import useWalletBalance from '@hooks/useWalletBalance';
import {
  formatPercent,
  formatRateToPercent,
  formatUserTotalUsdValue,
  formatUserUnderlyingAmount,
} from '@lib/units';
import {
  getBorrowBalanceRate,
  getLiquidationLtvRate,
  getLoanToValueRate,
  getPostRepayBorrowBalance,
  getTotalBorrowLimit,
  getUserBorrowBalance,
  getUserCollateralBalance,
} from '@lib/math';
import { determineRepayMaxAmount } from '@lib/maxLogic';
import { CRO_GAS_BUFFER, PARTNER_TOKEN_ICONS } from '@config/constants';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import { Mode } from '@components/MarketsPageView/types';

import validateRepayAmount from './validateRepayAmount';

export interface RepayWalletProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  allowance: BigNumber;
  amount: string;
  asset: TectonicAsset | null;
  isAllowanceLoaded: boolean;
  onAmountChange(value: string): void;
  onEnable(): void;
  onRepay(): void;
  onRepaySome(amount: string): void;
  mode: Mode;
  erc20IsEnabled: boolean;
}

function RepayWithWallet({
  mode,
  asset,
  amount,
  onAmountChange,
  onEnable,
  onRepay,
  onRepaySome,
  erc20IsEnabled,
  ...props
}: RepayWalletProps): JSX.Element {
  const { list: assets } = useSdkAndSupportedAssets(mode);
  const [isMaxRepay, setIsMaxRepay] = useState(false);
  const symbol = asset?.symbol || '';
  const options = useMemo(
    () => ({ skip: !props.isOpen, mode }),
    [props.isOpen, mode]
  );
  const { loaded: loadedUsdPrices, usdPrices } = useUsdPrices(mode, options);
  // TODO - If we can't load userMetricsData, then we should not proceed
  const {
    data: userMetricsData,
    loading: loadingUserMetrics,
    loaded: loadedUserMetrics,
  } = useUserMetrics(mode, options);
  const { data: walletData } = useWalletBalance(mode, asset, options);
  const { amount: currentBorrowAmount } = useUserAssetBorrowAmount(
    asset,
    mode,
    options
  );
  const {
    netBorrowApy,
    tonicBorrowApy,
    partnerTokenBorrowApy,
    isLoading: isBorrowApyLoading,
  } = useBorrowApy(mode, asset, options);

  const borrowBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserBorrowBalance(
          assets.map((a) => {
            const borrowAmount =
              userMetricsData.borrowAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            return { asset: a, borrowAmount, usdPrice };
          })
        )
      : null;

  const borrowLimit =
    loadedUserMetrics && loadedUsdPrices
      ? getTotalBorrowLimit(
          assets.map((a) => {
            const collateralFactor =
              userMetricsData.collateralFactors[a.symbol] ?? 0;
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol] ?? false;
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);

            return {
              asset: a,
              collateralFactor,
              isEnabledAsCollateral,
              supplyAmount,
              usdPrice,
            };
          })
        )
      : null;
  const collateralBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserCollateralBalance(
          assets.map((a) => {
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol];

            return {
              asset: a,
              collateralAmount: supplyAmount,
              isCollateral: isEnabledAsCollateral,
              usdPrice,
            };
          })
        )
      : null;

  const loadingInfo =
    isBorrowApyLoading ||
    loadingUserMetrics ||
    walletData.loading ||
    !loadedUsdPrices;

  const { valid, reason } = validateRepayAmount({
    amount,
    asset,
    currentBorrowAmount,
    walletBalance: walletData.balance,
  });
  const decideRepayMethod = () => {
    if (currentBorrowAmount && asset) {
      if (isMaxRepay) {
        onRepay();
      } else {
        onRepaySome(amount);
      }
    }
    return null;
  };

  const onMaxClick = () => {
    if (asset && currentBorrowAmount && walletData.balance) {
      const walletBalanceBorrowAmountDifference =
        walletData.balance.sub(currentBorrowAmount);
      const gasBuffer =
        asset.symbol === 'CRO'
          ? utils.parseUnits(CRO_GAS_BUFFER, asset.decimals)
          : BigNumber.from(0);
      if (
        walletData.balance.gte(currentBorrowAmount) &&
        walletBalanceBorrowAmountDifference.gte(gasBuffer)
      ) {
        setIsMaxRepay(true);
      }
      onAmountChange(
        determineRepayMaxAmount(asset, {
          walletBalance: walletData.balance,
          currentBorrowAmount,
        })
      );
    } else {
      onAmountChange('0');
    }
  };

  const displayCtaButton = () => {
    if (!erc20IsEnabled) {
      return (
        <Button className="w-full" onClick={onEnable}>
          Enable
        </Button>
      );
    }
    const repayText = isMaxRepay ? 'Repay all' : 'Repay';
    return (
      <Button
        className="w-full"
        disabled={!valid || !walletData.balance || walletData.loading}
        onClick={decideRepayMethod}
        theme="pink"
      >
        {reason === 'insufficientBalance' ? 'Insufficient Balance' : repayText}
      </Button>
    );
  };

  function getNewBorrowBalance(): BigNumber | null {
    if (asset && borrowBalance) {
      const newBorrowBalance = getPostRepayBorrowBalance(
        asset,
        borrowBalance,
        amount,
        BigNumber.from(usdPrices[symbol])
      );

      if (!newBorrowBalance) {
        return null;
      }

      return newBorrowBalance;
    }

    return null;
  }

  const endBorrowBalance = getNewBorrowBalance();
  function getNewLtv(): string | null {
    if (asset && borrowBalance && collateralBalance) {
      const newBorrowBalance = getPostRepayBorrowBalance(
        asset,
        borrowBalance,
        amount,
        BigNumber.from(usdPrices[symbol])
      );

      if (newBorrowBalance) {
        const newLtv = formatPercent(
          formatRateToPercent(
            getLoanToValueRate(newBorrowBalance, collateralBalance)
          )
        );

        return newLtv;
      }
    }

    return null;
  }

  function getLavaBarPercent(): number {
    if (borrowLimit) {
      if (endBorrowBalance) {
        return formatRateToPercent(
          getBorrowBalanceRate(endBorrowBalance, borrowLimit)
        );
      }

      if (borrowBalance) {
        return formatRateToPercent(
          getBorrowBalanceRate(borrowBalance, borrowLimit)
        );
      }
    }

    return 0;
  }

  useEffect(() => {
    if (!props.isOpen) {
      setIsMaxRepay(false);
    }
  }, [props.isOpen]);
  return (
    <div>
      <div className="mb-4">
        {erc20IsEnabled && (
          <EnhancedAmountInput
            label="Repay amount"
            onBlur={(): void => {
              if (amount === '') {
                onAmountChange('0');
              }
            }}
            onButtonClick={onMaxClick}
            onChange={(e): void => {
              setIsMaxRepay(false);
              onAmountChange(e.target.value);
            }}
            value={amount}
          />
        )}
      </div>
      <TransactionModalDataRow
        label={
          <div className="flex">
            {asset && <SupportedAssetIcon className="mr-1" asset={asset} />}
            <Text variant="default">Borrow APY</Text>
          </div>
        }
        loading={loadingInfo}
        value={
          netBorrowApy !== undefined && (
            <Text className={clsx({ 'text-green': netBorrowApy < 0 })}>
              {formatPercent(netBorrowApy)}
            </Text>
          )
        }
      />
      <TransactionModalDataRow
        label={
          <div className="ml-2.5 flex whitespace-nowrap">
            <Icon.Tonic className="mr-1 h-3 w-3" />
            <Text variant="default">Distribution APY</Text>
          </div>
        }
        loading={loadingInfo}
        value={<Text>{formatPercent(tonicBorrowApy * 100)}</Text>}
      />
      {Object.entries(partnerTokenBorrowApy).map(([tokenSymbol, apy]) => {
        const PartnerIcon = get(
          PARTNER_TOKEN_ICONS,
          tokenSymbol.toUpperCase(),
          null
        );

        if (!PartnerIcon || apy === 0) return null;

        return (
          <TransactionModalDataRow
            key={tokenSymbol}
            label={
              <div className="ml-2.5 flex whitespace-nowrap">
                <PartnerIcon className="mr-1 h-3 w-3" />
                <Text variant="default">Distribution APY</Text>
              </div>
            }
            loading={loadingInfo}
            value={<Text>{formatPercent(apy * 100)}</Text>}
          />
        );
      })}
      <TransactionModalDataRow
        label={<Text variant="default">Borrow balance</Text>}
        loading={loadingInfo}
        value={
          borrowBalance && (
            <StartEndText
              startValue={formatUserTotalUsdValue(borrowBalance)}
              endValue={
                endBorrowBalance
                  ? formatUserTotalUsdValue(endBorrowBalance)
                  : null
              }
            />
          )
        }
      />
      <TransactionModalDataRow
        label={<Text variant="default">Current Loan to Value (LTV)</Text>}
        loading={loadingInfo}
        value={
          !!(borrowBalance && collateralBalance) && (
            <StartEndText
              startValue={formatPercent(
                formatRateToPercent(
                  getLoanToValueRate(borrowBalance, collateralBalance)
                )
              )}
              endValue={getNewLtv()}
            />
          )
        }
      />
      <TransactionModalDataRow
        label={<Text variant="default">Liquidation Threshold</Text>}
        loading={loadingInfo}
        value={
          !!(borrowLimit && collateralBalance) && (
            <Text>
              {formatPercent(
                formatRateToPercent(
                  getLiquidationLtvRate(borrowLimit, collateralBalance)
                )
              )}
            </Text>
          )
        }
      />
      <div className="mb-10">
        <BorrowLimitLavaBar value={getLavaBarPercent()} />
      </div>
      <TransactionModalDataRow
        label={<Text>Wallet balance</Text>}
        loading={walletData.loading}
        value={
          !!(walletData.asset && walletData.balance) && (
            <Text>
              {`${formatUserUnderlyingAmount(
                walletData.asset,
                walletData.balance
              )} ${symbol}`}
            </Text>
          )
        }
      />
      {displayCtaButton()}
    </div>
  );
}

export default RepayWithWallet;
