import { Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import numbro from 'numbro';
import { utils, BigNumber } from 'ethers';

import { NORMALIZED_USD_PRICE_DECIMALS } from '@config/constants';

import useStats from './useStats';

function Stats(): JSX.Element {
  const { loading, totalBorrowed, totalSupplied } = useStats();

  function keepWholeNumbers(value: string) {
    return value.split('.')[0];
  }

  function formatStats(value: BigNumber) {
    const convertedValue = numbro(
      utils.formatUnits(value, NORMALIZED_USD_PRICE_DECIMALS)
    ).format({ thousandSeparated: true });

    return keepWholeNumbers(convertedValue);
  }

  if (loading) {
    return (
      <div className="max-w-xl">
        <Icon.Spinner className="inline-block h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="flex max-w-xl desktop:flex-row desktop:space-x-2 tablet:flex-col tablet:space-y-2 mobile:flex-col mobile:space-y-2">
      <div>
        <Text
          as="h2"
          className="whitespace-no-wrap text-center"
          variant="heading3"
        >
          Total supplied
        </Text>
        <Text as="h2" variant="heading1" className="text-center">
          {`$${formatStats(totalSupplied as BigNumber)}`}
        </Text>
      </div>
      <div>
        <Text
          as="h2"
          className="whitespace-no-wrap text-center"
          variant="heading3"
        >
          Total borrowed
        </Text>
        <Text as="h2" variant="heading1" className="text-center">
          {`$${formatStats(totalBorrowed as BigNumber)}`}
        </Text>
      </div>
    </div>
  );
}

export default Stats;
