import { useScreen, PageSection } from '@tectonicfi/tectonic-ui-kit';

import { Features } from '../LandingBodyContent';

import BackgroundImageContainer from './BackgroundImageContainer';

function FeaturesSection(): JSX.Element {
  const screen = useScreen();
  return screen !== 'desktop' ? (
    <div>
      <PageSection
        className="h-full"
        contentWrapperClassName="w-full flex items-center justify-center"
      >
        <Features />
      </PageSection>
    </div>
  ) : (
    <BackgroundImageContainer
      height="650px"
      src="/images/features-background.png"
    >
      <PageSection
        className="h-full"
        contentWrapperClassName="w-full flex items-center justify-center"
      >
        <Features />
      </PageSection>
    </BackgroundImageContainer>
  );
}

export default FeaturesSection;
