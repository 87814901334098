import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';
import { BigNumber } from 'ethers';

import { isAmountZeroEquivalent } from '@lib/utils';
import { parseInputAmountToBN } from '@lib/units';

interface ValidateRepayAmountParams {
  amount: string;
  asset: TectonicAsset | null;
  currentBorrowAmount: BigNumber | null;
  walletBalance: BigNumber | null;
}

interface ValidateRepayAmountResult {
  reason: 'insufficientBalance' | 'invalid' | null;
  valid: boolean;
}

const invalidResult: ValidateRepayAmountResult = {
  valid: false,
  reason: 'invalid',
};

function validateRepayAmount({
  amount,
  asset,
  currentBorrowAmount,
  walletBalance,
}: ValidateRepayAmountParams): ValidateRepayAmountResult {
  if (asset && walletBalance && currentBorrowAmount) {
    try {
      const parsedAmount = parseInputAmountToBN(amount, asset.decimals);
      const invalidInput =
        isAmountZeroEquivalent(amount) ||
        parsedAmount.isZero() ||
        parsedAmount.isNegative();
      const noBorrow = currentBorrowAmount.isZero();
      const tooMuchToRepay = parsedAmount.gt(currentBorrowAmount);
      if (invalidInput || noBorrow || tooMuchToRepay) {
        return invalidResult;
      }

      if (parsedAmount.gt(walletBalance)) {
        return { valid: false, reason: 'insufficientBalance' };
      }

      return { valid: true, reason: null };
    } catch (error) {
      return invalidResult;
    }
  }
  return invalidResult;
}

export default validateRepayAmount;
