import { useContext, useEffect, useMemo, useState } from 'react';
import { TectonicSDK } from '@cronos-labs/tectonic-sdk';
import { BigNumber } from 'ethers';
import clsx from 'clsx';

import VaultsBlock from '@components/VaultsPageView/VaultsBlock';
import { Mode } from '@components/EarnPageView/types';
import TransactionModalsProvider, {
  TransactionModalsContext,
} from '@providers/TransactionModalsProvider';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { formatPercent, formatRateToPercent, formatXTonic } from '@lib/units';
import { LockingPeriod } from '@components/LockDepositModal/types';
import { useWallet } from '@providers/WalletProvider';
import useSupportedAsset from '@hooks/useSupportedAsset';
import {
  INITIAL_VAULTS_BLOCK_STATE,
  VaultsBlockStateContext,
} from '@components/EarnPageView';

import EarningsBlock from '../EarningsBlock/EarningsBlock';

import { getVaultStats } from './getVaultStats';

export interface VaultsAndEarningsBlockProps {
  mode: Mode;
}

const getData = async (sdk: TectonicSDK, address: string) => {
  const [deposits, exchangeRate, poolsFromSdk, pendingTonic] =
    await Promise.all([
      // the typing in the sdk is currently wrong, this is supposed to be fixed with the next version
      //eslint-disable-next-line
      //@ts-ignore
      sdk.Vault.getUserStakes(address),
      sdk.Staking.getExchangeRate(),
      sdk.Vault.getPools(),
      sdk.Vault.getPendingTonic(address),
    ]);
  const pools = poolsFromSdk.map((p) => ({ ...p, apy: p.apy / 100 }));
  const maxPeriodLength = Math.max(...pools.map((p) => p.lockPeriod));

  // * The `totalStaked` in `TectonicVaultPool` is overall staked amount in a pool
  const poolsOfUser = pools.map(({ totalStaked: _, ...p }) => {
    const depositsOfPool = deposits.filter((v) => v.pool.poolId === p.poolId);
    const now = new Date().getTime();
    const unlockedVault = depositsOfPool.find(
      (v) => v.lockEndDate.getTime() <= now
    );
    const totalStaked = depositsOfPool.reduce(
      (prev, pool) => pool.amount.add(prev),
      BigNumber.from(0)
    );
    const { lockedStaked, unlockedStaked } = depositsOfPool.reduce(
      (prev, pool) =>
        pool.lockEndDate.getTime() > now
          ? {
              lockedStaked: prev.lockedStaked.add(pool.amount),
              unlockedStaked: prev.unlockedStaked,
            }
          : {
              unlockedStaked: prev.unlockedStaked.add(pool.amount),
              lockedStaked: prev.lockedStaked,
            },
      { lockedStaked: BigNumber.from(0), unlockedStaked: BigNumber.from(0) }
    );
    return {
      totalStaked,
      lockedStaked,
      unlockedStaked,
      numberOfStakes: depositsOfPool.length,
      hasUnlockedVault: !!unlockedVault,
      hasLongestPeriod: p.lockPeriod === maxPeriodLength,
      initialTotalStaked: _,
      ...p,
    };
  });

  return {
    deposits,
    exchangeRate,
    pools,
    pendingTonic,
    poolsOfUser,
  };
};
export const useVaultsData = () => {
  const sdk = useTectonicSdk();
  const { currentAccount } = useWallet();
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<Awaited<ReturnType<typeof getData>> | null>(
    null
  );
  useEffect(() => {
    if (!sdk || !currentAccount) {
      return;
    }
    setLoaded(false);
    setData(null);
    getData(sdk, currentAccount).then((d) => {
      setLoaded(true);
      setData(d);
    });
  }, [sdk, currentAccount]);
  const reload = () =>
    sdk &&
    currentAccount &&
    getData(sdk, currentAccount).then((d) => {
      setData(d);
    });
  return { loaded, data, reload };
};

export default function VaultsAndEarningsBlock({
  mode,
}: VaultsAndEarningsBlockProps) {
  const tonicAsset = useSupportedAsset('TONIC');
  const { loaded, data, reload } = useVaultsData();
  const { stats, pools, vaults } = useMemo(() => {
    const _vaults = data?.deposits;
    const _pools = data?.poolsOfUser;
    if (!_vaults || !_pools) {
      return { vaults: [], pools: [] };
    }
    const _stats = getVaultStats(_vaults);
    return { vaults: _vaults, stats: _stats, pools: _pools };
  }, [data?.deposits, data?.poolsOfUser]);

  const { vaultsBlockState, setVaultsBlockState } = useContext(
    VaultsBlockStateContext
  );

  return (
    <TransactionModalsProvider
      onTxSuccess={(): void => {
        reload();
        setVaultsBlockState(INITIAL_VAULTS_BLOCK_STATE);
      }}
    >
      <TransactionModalsContext.Consumer>
        {({ onOpenModal, txState }) => (
          <div className="flex flex-1 flex-col overflow-auto">
            <div
              className={
                'flex flex-1 flex-row justify-between overflow-auto mobile:inline'
              }
            >
              <div
                className={clsx('mr-1.5 w-1/2 mobile:mr-0 mobile:w-auto', {
                  'mobile:hidden': mode !== Mode.vaults,
                })}
              >
                <VaultsBlock
                  onDepositMoreClick={(period: LockingPeriod) => {
                    if (!tonicAsset) {
                      return;
                    }
                    onOpenModal(tonicAsset, 'lockDeposit', {
                      meta: { type: 'lockDeposit', preselectedPeriod: period },
                    });
                  }}
                  pools={pools}
                  deposits={vaults}
                  loaded={loaded}
                  onOpenModal={onOpenModal}
                  vaultsBlockState={vaultsBlockState}
                  setVaultsBlockState={setVaultsBlockState}
                  txState={txState}
                />
              </div>
              <EarningsBlock
                className={clsx(
                  'ml-1.5 inline w-1/2 mobile:ml-0 mobile:w-auto',
                  {
                    'mobile:hidden': mode !== Mode.earnings,
                  }
                )}
                avgApr={formatPercent(formatRateToPercent(stats?.avgApr ?? 0))}
                xTonicStaked={
                  stats ? formatXTonic(stats?.totalXTonicStaked) : '0'
                }
                xTonicUnlocked={
                  stats ? formatXTonic(stats?.totalXTonicUnlocked) : '0'
                }
                allocations={stats?.allocations}
                hasDeposits={!!stats?.totalXTonicStaked.gt(0)}
                onOpenModal={onOpenModal}
              />
            </div>
          </div>
        )}
      </TransactionModalsContext.Consumer>
    </TransactionModalsProvider>
  );
}
